export { default as Introduction } from "./introduction/Introduction";
export { default as InstanceSelection } from "./instance-selection/InstanceSelection";
import { default as CompanySelection } from "./company-selection/CompanySelection";
import { default as MainMenu } from "./main-menu/MainMenu";
import MultiProjectSearch, { multiProjectSearchSlice } from "./multi-project-search/MultiProjectSearch";
import LinkRequirementToDeliverable, {
  LinkRequirementToDeliverableSlice,
} from "./link-requirement-to-deliverable/LinkRequirementToDeliverable";

export const slices = {
  multiProjectSearch: multiProjectSearchSlice,
  LinkRequirementToDeliverableSlice: LinkRequirementToDeliverableSlice,
};

export const views = { CompanySelection, MainMenu, MultiProjectSearch, LinkRequirementToDeliverable };
