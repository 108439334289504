import React, { useState } from "react";
import styles from "./RequirementBox.module.css";
import { CircularLoader, CustomButton, CustomIconButton, Hierarchy, icon } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import CoverageDropDown from "../coverage-drop-down/CoverageDropDown";
import { WordUtils } from "../../../../common/utils";
import { useApi } from "../../../../common/hooks";
import { ParagraphOutService } from "../../../../api";
import { useSelector } from "react-redux";
import { newLineRegex, paragraphOutIdRegex, spaceRegex } from "../../../../common/constants";
const RequirementBox = ({ row, onOpen, onUpdateReqCounter, onUpdateRequirementCoverage }) => {
  const { call: createParagraphForDeliverables } = useApi(ParagraphOutService.createParagraphForDeliverables);
  const deliverableId = useSelector(({ context }) => context.deliverable?.id);
  const projectId = useSelector(({ context }) => context.projectReqToDeliv?.id);
  const [isAdding, setIsAdding] = useState(false);
  const handleCallBack =
    ({ content, html, tag }) =>
    ({ title }) => {
      const paragraphId = parseInt(tag?.match(paragraphOutIdRegex)?.[1]) || undefined;
      createParagraphForDeliverables(
        { deliverableId, projectId, informationId: row.id },
        {
          contentHTML: html,
          content: content.replace(newLineRegex, "\n").replace(spaceRegex, " "),
          paragraphId,
          title,
          order_in_doc: 1,
          page: 1,
        }
      )
        .then((data) => {
          WordUtils.insertContentControl({ ...data, deliverableId, clientRef: row.clientRef });
          onUpdateReqCounter({ reqId: row.id, increment: true });
        })
        .catch(console.error)
        .finally(() => {
          setIsAdding(false);
        });
    };
  const handleAddParagraph = () => {
    setIsAdding(true);
    WordUtils.getInformationForAddParagraph(handleCallBack);
  };
  const handleOpen = () => {
    onOpen(row);
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.header__leftSide}>REQ_{row.id}</div>
        <div className={styles.header__rightSide}>
          {(!isAdding && (
            <CustomIconButton
              btnClassName={styles["btn--transparent"]}
              disabled={isAdding}
              icon={icon.faPlus}
              size="xs"
              tooltip={translate("common:btn.add-selection")}
              variant="outlined"
              onClick={handleAddParagraph}
            />
          )) || (
            <span className={styles.loaderContainer}>
              <CircularLoader size={16} />
            </span>
          )}
          <CoverageDropDown
            covered={!!row.covered}
            paragraphCount={row.count}
            reqId={row.id}
            onUpdateRequirementCoverage={onUpdateRequirementCoverage}
          />
          <CustomButton color="secondary" size="sm" variant="outlined" onClick={handleOpen}>
            {translate("common:btn.details")}
          </CustomButton>
        </div>
      </div>
      <div className={styles.content}>{row.content}</div>
      <div>
        <Hierarchy
          enableFirstLocationCut
          isDangerousSetInnerHTML
          className={styles.breadcrumbContainer}
          documentName={row.documentName}
          hierarchy={row.location}
        />
      </div>
    </div>
  );
};

export default RequirementBox;
