import { DialogTitle } from "@mui/material";
import React from "react";
import { IconComponent, icon, CustomIconButton } from "../..";
import styles from "./GenericDialogHeader.module.css";

export default function GenericDialogHeader({ className = "", children, onClose = null, iconName = "" }) {
  return (
    <DialogTitle className={`${styles.dialogHeader} ${className}`}>
      {iconName && <IconComponent className={styles.dialogHeader__icon} icon={iconName} />}
      <span className={styles.dialogHeader__title}>{children}</span>
      {onClose && (
        <CustomIconButton
          icon={icon.faTimes}
          iconClassName={styles["dialogHeader__closeBtn--larger"]}
          onClick={onClose}
        />
      )}
    </DialogTitle>
  );
}
