import React from "react";
import { CustomBreadcrumb } from "../";
import styles from "./Hierarchy.module.css";

export default function Hierarchy({
  breadcrumbClassName = "",
  charsKeptInEnd = 12,
  className = "",
  disabled = false,
  documentName = "",
  enableFirstLocationCut = false,
  hierarchy = "",
  isDangerousSetInnerHTML = false,
  openOnHover = true,
}) {
  const fullToc = [
    ...((documentName.length > 0 && [documentName]) || []),
    ...((hierarchy?.length > 0 && hierarchy.split(" / ")) || []),
  ].map((toc, index) => ({ id: index, label: toc }));
  return (
    <CustomBreadcrumb
      closeOnLeave
      breadcrumbClassName={`${styles.breadcrumb} ${breadcrumbClassName}`}
      charsKeptInEnd={charsKeptInEnd}
      className={className}
      disabled={disabled}
      enableFirstLocationCut={enableFirstLocationCut}
      isDangerousSetInnerHTML={isDangerousSetInnerHTML}
      locations={fullToc}
      openOnHover={openOnHover}
    />
  );
}
