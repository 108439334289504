import React from "react";
import styles from "./MenuSidePanel.module.css";
import { CustomIconButton, icon } from "../../../../../common/components";
import { menus } from "../../menus";
import MenuRow from "../menu-row/MenuRow";
import { checkValidity } from "../../../../utils/utils";
const MenuSidePanel = ({ open, onClose }) => {
  return (
    <div className={styles.menuSidePanel} data-open={open}>
      <div className={styles.menuHeader}>
        <CustomIconButton icon={icon.faXmark} size="sm" onClick={onClose} />
      </div>
      {menus.map((menu) => {
        if (!checkValidity(menu)) {
          return null;
        }
        return <MenuRow key={menu.name} menu={menu} onClose={onClose} />;
      })}
    </div>
  );
};

export default MenuSidePanel;
