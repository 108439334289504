import client from "../client/client";

class ProjectDocumentsService {
  getDirectory({ projectId, directoryId }, cancelToken) {
    return client.get(`/smartview/projects/${projectId}/document-tabs/directories/${directoryId}`, {
      config: { cancelToken },
    });
  }

  getRoot({ projectId }, { limit = 25, page = 0, excludeDeliverable = false, search = "" }, cancelToken) {
    return client.get(`/smartview/projects/${projectId}/document-tabs/directories/root`, {
      params: { limit, page, search, excludeDeliverable },
      config: { cancelToken },
    });
  }

  getAllPrecedences({ projectId, excludeDeliverable = false }, cancelToken) {
    return client.get(`/smartview/projects/${projectId}/document-tabs/me/precedence`, {
      params: { excludeDeliverable },
      config: { cancelToken },
    });
  }

  getPrecedence({ precedenceId, excludeDeliverable = false }, cancelToken) {
    return client.get(`/smartview/document-tabs/me/precedence/${precedenceId}`, {
      params: { excludeDeliverable },
      config: { cancelToken },
    });
  }

  getAllRoles({ projectId, excludeDeliverable = false }, cancelToken) {
    return client.get(`/smartview/projects/${projectId}/document-tabs/me/roles`, {
      params: { excludeDeliverable },
      config: { cancelToken },
    });
  }

  getRole({ projectId, role, excludeDeliverable = false }, cancelToken) {
    return client.get(`/smartview/projects/${projectId}/document-tabs/me/roles/${role}`, {
      params: { excludeDeliverable },
      config: { cancelToken },
    });
  }
}

export default new ProjectDocumentsService();
