import client from "../client/client";
class SmartViewService {
  getSelectedDocumentCountByRole({ projectId }, { values }, cancelToken) {
    return client.post(`/documents/projects/${projectId}/counter/role`, {
      payload: { values },
      config: { cancelToken },
    });
  }

  getSelectedDocumentCountByPrecedence({ projectId }, { values }, cancelToken) {
    return client.post(`/documents/projects/${projectId}/counter/precedence`, {
      payload: { values },
      config: { cancelToken },
    });
  }
  searchInDirectoryByDocumentName({ projectId }, { search }, cancelToken) {
    return client.get(`/smartview/projects/${projectId}/document-tabs/directories`, {
      params: { search },
      config: { cancelToken },
    });
  }

  searchInRoleByDocumentName({ projectId }, { search }, cancelToken) {
    return client.get(`/smartview/projects/${projectId}/document-tabs/roles`, {
      params: { search },
      config: { cancelToken },
    });
  }

  searchInPrecedenceByDocumentName({ projectId }, { search }, cancelToken) {
    return client.get(`/smartview/projects/${projectId}/document-tabs/precedences`, {
      params: { search },
      config: { cancelToken },
    });
  }
}
export default new SmartViewService();
