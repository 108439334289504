import { Badge } from "@mui/material";
import React from "react";
import styles from "./CustomBadge.module.css";

const CustomBadge = React.forwardRef((props, ref) => {
  const {
    anchorOrigin = { vertical: "top", horizontal: "right" },
    badgeClass = "",
    children,
    className = "",
    color = "default",
    component = "span",
    content,
    invisible = false,
    max = 99,
    onMouseLeave,
    onMouseOver,
    overlap = "rectangular",
    showZero = false,
    variant = "standard",
  } = props;
  return (
    <Badge
      ref={ref}
      anchorOrigin={anchorOrigin}
      badgeContent={content}
      classes={{
        badge: badgeClass,
      }}
      className={`${styles.badge} ${className}`}
      color={color}
      component={component}
      invisible={(!showZero && content === 0) || invisible}
      max={max}
      overlap={overlap}
      showZero={showZero}
      variant={variant}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
    >
      {children}
    </Badge>
  );
});

export default CustomBadge;
