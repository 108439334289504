import React from "react";
import styles from "./SearchHelpTooltip.module.css";
import { translate } from "../../../../common/providers";
import { I18nTranslate } from "../../../../common/components";

const SearchHelpTooltip = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{translate("multi-project-search.help-icon.tooltip.title")}</div>
      <ul className={styles.listContainer}>
        <li>
          <I18nTranslate translationKey="multi-project-search.help-icon.tooltip.element1" />
        </li>
        <li>
          <I18nTranslate translationKey="multi-project-search.help-icon.tooltip.element2" />
        </li>
        <li>
          <I18nTranslate translationKey="multi-project-search.help-icon.tooltip.element3" />
        </li>
      </ul>
    </div>
  );
};

export default SearchHelpTooltip;
