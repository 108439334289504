import React from "react";
import styles from "./SelectorList.module.css";
import { DropDown, InfiniteList } from "../..";

const SelectorList = React.forwardRef(
  (
    {
      classNameInfiniteList,
      infiniteListRequest,
      onClick,
      onClose,
      open,
      rowRenderer,
      callOnResponse,
      nullable = false,
    },
    ref
  ) => {
    const row = (loadedRow) => rowRenderer({ onClick, loadedRow });
    return (
      <DropDown ref={ref} disablePortals open={open} onClose={onClose}>
        <div
          className={styles.listContainer}
          style={{
            width: ref.current?.clientWidth || 0,
          }}
        >
          {nullable && row(null)}
          <InfiniteList
            callOnResponse={callOnResponse}
            className={classNameInfiniteList}
            request={infiniteListRequest}
            rowRenderer={row}
          />
        </div>
      </DropDown>
    );
  }
);

export default SelectorList;
