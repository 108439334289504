import React from "react";
import { CustomButtonLink } from "../../../../common/components";
import styles from "./Row.module.css";
import { translate } from "../../../../common/providers";
const Row = ({ menu }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {menu.title}
        {menu.isBeta && <span className={styles.betaText}>{translate("main-menu.row.beta")}</span>}
      </div>
      <div>{menu.subText}</div>
      <div className={styles.button_container}>
        <CustomButtonLink to={menu.path} variant="contained">
          {translate("common:btn.go")}
        </CustomButtonLink>
      </div>
    </div>
  );
};

export default Row;
