import React from "react";
import PropTypes from "prop-types";
import { IconComponent } from "../..";
import styles from "./CustomIconButton.module.css";
import { CustomTooltip, CustomBadge } from "../../";

const iconSizes = {
  xs: "2xs",
  sm: "xs",
  md: "lg",
  lg: "lg",
  xl: "xl",
};
const CustomIconButton = React.forwardRef(
  (
    {
      badgeClassName = "",
      badgeColor = "default",
      badgeContent = "",
      btnClassName = "",
      className = "",
      color = "secondary",
      disabled = false,
      disableHovering = false,
      icon,
      iconClassName = "",
      iconColor = "inherit",
      label = "",
      onClick = null,
      onMouseOver = null,
      onMouseLeave = null,
      size = "md",
      tooltip = "",
      tooltipPlacement = "bottom",
      variant = "text",
      "data-testid": dataTestId = null,
    },
    ref
  ) => {
    const handleClick = disabled ? null : onClick;
    return (
      <CustomTooltip placement={tooltipPlacement} title={tooltip}>
        <span className={`${styles.wrapper} ${className}`}>
          <button
            ref={ref}
            className={`${styles.btn} ${btnClassName}`}
            data-color={color}
            data-disabledhovering={disableHovering}
            data-size={size}
            data-testid={dataTestId}
            data-variant={variant}
            disabled={disabled}
            type="button"
            onClick={handleClick}
            onMouseEnter={onMouseOver}
            onMouseLeave={onMouseLeave}
          >
            <span className={styles.btn__label}>
              {icon
                ? (badgeContent && (
                    <CustomBadge
                      className={`${styles.btn__badge} ${badgeClassName}`}
                      color={badgeColor}
                      content={badgeContent}
                    >
                      <span>
                        <IconComponent
                          className={`${styles.btn__icon} ${iconClassName}`}
                          color={iconColor}
                          disabled={disabled}
                          icon={icon}
                          size={iconSizes[size]}
                        />
                      </span>
                    </CustomBadge>
                  )) || (
                    <IconComponent
                      className={`${styles.btn__icon} ${iconClassName}`}
                      color={iconColor}
                      disabled={disabled}
                      icon={icon}
                      size={iconSizes[size]}
                    />
                  )
                : label}
            </span>
          </button>
        </span>
      </CustomTooltip>
    );
  }
);
CustomIconButton.propTypes = {
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  color: PropTypes.oneOf(["secondary", "primary"]),
  variant: PropTypes.oneOf(["contained", "text", "outlined"]),
};
CustomIconButton.defaultProps = {
  size: "md",
  color: "secondary",
  variant: "text",
};
export default CustomIconButton;
