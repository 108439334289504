import React, { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import {
  ChapterPreview,
  CircularLoader,
  CustomButton,
  CustomIconButton,
  icon,
  SidePanel,
  SidePanelContent,
} from "../../../../common/components";
import styles from "./RequirementSidePanel.module.css";
import { translate } from "../../../../common/providers";
import ParagraphRow from "./ParagraphRow";
import { isNonEmptyArray, WordUtils } from "../../../../common/utils";
import { useApi } from "../../../../common/hooks";
import { ContentService, ParagraphOutService } from "../../../../api";
import { useSelector } from "react-redux";
import { newLineRegex, paragraphOutIdRegex, spaceRegex } from "../../../../common/constants";

const RequirementSidePanel = forwardRef(({ open, onClose, onUpdateReqCounter, req, selectedLinkId }, ref) => {
  const { call: getParagraphsForDeliverables } = useApi(ParagraphOutService.getParagraphsForDeliverables);
  const { call: createParagraphForDeliverables } = useApi(ParagraphOutService.createParagraphForDeliverables);
  const { call: deleteParagraphsLinkForDeliverables } = useApi(ParagraphOutService.deleteParagraphsLinkForDeliverables);
  const { call: getPreviewInformationChapter } = useApi(ContentService.getPreviewInformationChapter);
  const deliverableId = useSelector(({ context }) => context.deliverable?.id);
  const projectId = useSelector(({ context }) => context.projectReqToDeliv?.id);
  const [isContentOverflowing, setIsContentOverflowing] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [hide, setHide] = useState(true);
  const [paragraphs, setParagraphs] = useState([]);
  const [preview, setPreview] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const textRef = useRef(null);
  const scrollRef = useRef(null);

  const handleContentOverflow = useCallback(() => {
    setIsContentOverflowing(textRef.current?.scrollHeight > textRef.current?.offsetHeight);
  }, [textRef]);
  useEffect(() => {
    if (open) {
      setIsLoading(true);
      getParagraphsForDeliverables({ deliverableId, projectId, informationId: req.id })
        .then((data) => {
          setParagraphs(data);
        })
        .catch(console.error)
        .finally(() => setIsLoading(false));
    }
  }, [open, deliverableId, projectId, req]);
  useEffect(() => {
    if (open) {
      handleContentOverflow();
    }
  }, [open]);
  useEffect(() => {
    window.addEventListener("resize", handleContentOverflow);
    return () => {
      window.removeEventListener("resize", handleContentOverflow);
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (open && scrollRef?.current && !isLoading) {
        scrollRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 500);
  }, [open, scrollRef, isLoading]);
  useImperativeHandle(
    ref,
    () => ({
      updateParagraphTitlesUI(title, paragraphIds) {
        setParagraphs((prev) =>
          prev.map((p) => {
            if (paragraphIds.includes(p.id)) {
              return { ...p, title };
            }
            return p;
          })
        );
      },
      updateParagraphUI({ paragraphOutId, title, content, contentHTML }) {
        setParagraphs((prev) =>
          prev.map((p) => {
            if (p.id === paragraphOutId) {
              return { ...p, title, content, contentHTML };
            }
            return p;
          })
        );
      },
    }),
    [paragraphs]
  );
  const handleShowMore = () => {
    setHide((prev) => !prev);
  };
  const handleCallBack =
    ({ content, html, tag }) =>
    ({ title }) => {
      const paragraphId = parseInt(tag?.match(paragraphOutIdRegex)?.[1]) || undefined;
      createParagraphForDeliverables(
        { deliverableId, projectId, informationId: req.id },
        {
          contentHTML: html,
          content: content.replace(newLineRegex, "\n").replace(spaceRegex, " "),
          paragraphId,
          title,
          order_in_doc: 1,
          page: 1,
        }
      )
        .then((data) => {
          WordUtils.insertContentControl({ ...data, deliverableId, clientRef: req.clientRef });
          setParagraphs((prev) => [...prev, data]);
          onUpdateReqCounter({ reqId: req.id, increment: true });
        })
        .catch(console.error)
        .finally(() => {
          setIsAdding(false);
        });
    };
  const handleAddParagraph = () => {
    setIsAdding(true);
    WordUtils.getInformationForAddParagraph(handleCallBack);
  };
  const handleDeleteLink = (pargraphToDelete) => {
    deleteParagraphsLinkForDeliverables({
      projectId,
      deliverableId,
      informationId: req.id,
      paragraphOutId: pargraphToDelete.paragraphOutId,
      linkId: pargraphToDelete.id,
    })
      .then(() => {
        setParagraphs((prev) => prev.filter((p) => p.id !== pargraphToDelete.id));
        onUpdateReqCounter({ reqId: req.id, increment: false });
        WordUtils.removeContentControl({ ...pargraphToDelete, documentOutId: deliverableId });
      })
      .catch(console.error);
  };
  const handlegetContentForPreview = () => {
    getPreviewInformationChapter({ docId: req.documentId, informationId: req.id })
      .then((data) => setPreview(data))
      .catch(console.error);
  };
  const handleClosePreviewDisplay = () => {
    setPreview(null);
  };
  const sidePanelHeader = () => {
    return (
      <div className={styles.sidePanelHeaderContent}>
        <div>
          <CustomIconButton icon={icon.faChevronLeft} size="sm" onClick={onClose} />
        </div>
        <div className={styles.sidePanelHeaderContent__mainHeader}>
          <div className={styles.sidePanelHeaderContent__topLine}>
            <div className={styles.reqId}>REQ_{req.id}</div>
            <div className={styles.sidePanelHeaderContent__rightSide}>
              {isNonEmptyArray(paragraphs) && (
                <span>
                  {(paragraphs.length === 1 &&
                    translate("requirements-to-deliverables.requirement-box.paragraph", {
                      count: paragraphs.length,
                    })) ||
                    translate("requirements-to-deliverables.requirement-box.paragraphs", { count: paragraphs.length })}
                </span>
              )}
              <CustomIconButton
                icon={icon.faFileLines}
                size="xs"
                tooltip={translate("common:btn.display")}
                variant="outlined"
                onClick={handlegetContentForPreview}
              />
            </div>
          </div>
          <div>
            <div ref={textRef} className={styles.content} data-hide={hide}>
              {req.content}
            </div>
            {isContentOverflowing && (
              <CustomButton className={styles.informationContent__btn} color="primary" onClick={handleShowMore}>
                {(hide && translate("common:btn.show-more")) || translate("common:btn.show-less")}
              </CustomButton>
            )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <SidePanel open={open} onClose={onClose}>
        <SidePanelContent className={styles.sidePanelContent} customHeader={sidePanelHeader()}>
          <div className={styles.sidePanelContent__container}>
            <div className={styles.sidePanelContent__addButton}>
              <CustomButton
                disabled={isAdding}
                startIcon={icon.faPlus}
                variant="contained"
                onClick={handleAddParagraph}
              >
                {isAdding ? <CircularLoader size={16} /> : translate("common:btn.add-selection")}
              </CustomButton>
            </div>
            <div className={styles.deliverablesList}>
              {(isLoading && <CircularLoader className={styles.circularLoader} />) || (
                <>
                  {isNonEmptyArray(paragraphs) &&
                    paragraphs.map((paragraph) => (
                      <ParagraphRow
                        key={paragraph.id}
                        ref={scrollRef}
                        isSelected={paragraph.id === selectedLinkId}
                        row={paragraph}
                        onDelete={handleDeleteLink}
                      />
                    ))}
                </>
              )}
            </div>
          </div>
        </SidePanelContent>
      </SidePanel>
      <ChapterPreview
        coordinates={preview?.rectangles}
        docId={preview?.documentId}
        infoId={preview?.informationId}
        numOfPages={preview?.numberOfPages}
        open={!!preview}
        page={preview?.pageStart}
        projectId={preview?.projectId}
        recievedRectangles={preview?.contentPositionDTOs}
        onClose={handleClosePreviewDisplay}
      />
    </>
  );
});

export default RequirementSidePanel;
