import React, { useState } from "react";
import { CustomButton, CustomIconButton, icon } from "../../../common/components";
import styles from "./SecondaryMenu.module.css";
import { MenuSidePanel, SyncDialog } from "./components";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { translate } from "../../../common/providers";
import { useSelector } from "react-redux";
const SecondaryMenu = () => {
  const projectReqToDeliv = useSelector(({ context }) => context.projectReqToDeliv);
  const deliverable = useSelector(({ context }) => context.deliverable);
  const projectReqToDelivToUpdate = useSelector(({ context }) => context.projectReqToDelivToUpdate);
  const deliverableToUpdate = useSelector(({ context }) => context.deliverableToUpdate);
  const [open, setOpen] = useState(false);
  const [openSync, setOpenSync] = useState(false);
  const location = useLocation();
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };
  const handleOpenSync = () => {
    setOpenSync((prev) => !prev);
  };
  return (
    <>
      <div className={styles.secondary__container}>
        <div>
          <CustomIconButton icon={icon.faBars} size="sm" onClick={handleOpen} />
          <span className={styles.currentMenu}>
            {translate(`common:user-bar.secondary-menu.${location.pathname.substring(1)}`)}
          </span>
        </div>
        {location.pathname === "/link-requirement-to-deliverable" &&
          projectReqToDeliv?.id &&
          deliverable?.id &&
          !projectReqToDelivToUpdate &&
          !deliverableToUpdate && (
            <CustomButton color="secondary" size="sm" variant="outlined" onClick={handleOpenSync}>
              {translate("common:btn.synchronize")}
            </CustomButton>
          )}
      </div>
      <MenuSidePanel open={open} onClose={handleOpen} />
      <SyncDialog open={openSync} onClose={handleOpenSync} />
    </>
  );
};

export default SecondaryMenu;
