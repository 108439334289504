import client from "../../client/client";

class UserService {
  verifyEmail({ email }, cancelToken) {
    return client.get("/users", { params: { email }, config: { cancelToken } });
  }

  loginWithCompany({ companyId }, cancelToken, { token = "" } = {}) {
    const config = { config: { cancelToken } };
    if (token) {
      config.config.headers = { Authorization: token };
    }
    return client.post(`/users/login/${companyId}`, config);
  }
}

export default new UserService();
