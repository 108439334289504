import client from "../client/client";

class CategoryService {
  getTreeForProject({ projectId, search = "", details = true }, cancelToken) {
    return client.get(`/thematic/project/${projectId}`, {
      params: { search, details },
      config: { cancelToken },
    });
  }

  getTreeParentsForProject({ projectId, details = true }, cancelToken) {
    return client.get(`/thematic/project/${projectId}/parents`, {
      params: { details },
      config: { cancelToken },
    });
  }

  getTreeChildrenForProject({ parentId, details = true }, cancelToken) {
    return client.get(`/thematic/${parentId}/children`, {
      params: { details },
      config: { cancelToken },
    });
  }
}
export default new CategoryService();
