export const FILE_TYPES_EXTENSIONS = [
  ".pdf",
  ".docx",
  ".docm",
  "dotx",
  "dotm",
  ".xlsx",
  ".xlsm",
  ".xslx",
  ".zip",
  ".exe",
];
export const FILE_TYPES = { FILE: "FILE", FOLDER: "FOLDER" };
