import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { loadViewTranslation } from "../../../common/providers";
import { Loader } from ".";
import { KeycloakService } from "../../../sevices";

export default function NavRouteWrapper({ localesPath, children }) {
  const { value } = useSelector(({ context }) => context.language);
  const metadataLoaded = useSelector(({ context }) => [context.loadingFlags, context.loadingUser].every((e) => !e));
  const firstTimeLogin = useSelector(({ context }) => context.firstTimeLogin);
  const [trsLoaded, setTrsLoaded] = useState(false);
  const locale = KeycloakService.userLanguage();
  const language = firstTimeLogin ? locale : value;
  useEffect(() => {
    if (localesPath) {
      loadViewTranslation(language, localesPath)
        .then(() => setTrsLoaded(true))
        .catch((err) => console.error(err));
    }
    return () => setTrsLoaded(false);
  }, [value, localesPath]);
  return metadataLoaded && trsLoaded ? (
    children
  ) : (
    <div>
      <Loader />
    </div>
  );
}
