import React from "react";
import styles from "./ParagraphRow.module.css";
import { CustomButton, CustomTooltip } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import { WordUtils } from "../../../../common/utils";
import { useSelector } from "react-redux";
const ParagraphRow = React.forwardRef(({ isSelected, row, onDelete }, ref) => {
  const deliverableId = useSelector(({ context }) => context.deliverable?.id);
  const handleScrollTo = () => {
    WordUtils.scrollToContentControl({ ...row, documentOutId: deliverableId });
  };
  const handleDelete = () => {
    onDelete(row);
  };

  return (
    <div ref={isSelected ? ref : null} className={styles.container} data-is-selected={isSelected}>
      <CustomTooltip showWhenOverflow title={row.chapter}>
        <div className={styles.header}>{row.title}</div>
      </CustomTooltip>
      <div className={styles.content}>{row.content}</div>
      <div className={styles.btnContainer}>
        <CustomButton color="secondary" size="sm" variant="outlined" onClick={handleDelete}>
          {translate("requirements-to-deliverables.deliverable-box.delete-link")}
        </CustomButton>
        <CustomButton color="secondary" size="sm" variant="outlined" onClick={handleScrollTo}>
          {translate("requirements-to-deliverables.deliverable-box.highlight-paragraph")}
        </CustomButton>
      </div>
    </div>
  );
});

export default ParagraphRow;
