import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import styles from "./IconComponent.module.css";

const IconComponent = React.forwardRef(
  (
    {
      autoTranslate = "yes",
      children,
      className = "",
      color = "var(--color-blue)",
      disabled = false,
      fontSize,
      icon,
      onMouseLeave,
      onMouseOver,
      size = "sm",
    },
    ref
  ) => {
    if (!icon && !children) {
      return null;
    }
    return !children ? (
      <FontAwesomeIcon
        ref={ref}
        className={`${styles.icon} ${className}`}
        color={color}
        data-size={size}
        disabled={disabled}
        fontSize={fontSize}
        icon={icon}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
      />
    ) : (
      <span
        ref={ref}
        className={`${styles.letter} ${className}`}
        data-size={size}
        disabled={disabled}
        translate={autoTranslate}
      >
        {children}
      </span>
    );
  }
);
IconComponent.propTypes = {
  size: PropTypes.oneOf(["2xs", "xs", "sm", "md", "lg", "xl", "2xl", "3xl", "4xl", "5xl", "8xl"]),
};
IconComponent.defaultProps = {
  size: "sm",
};

export default IconComponent;
