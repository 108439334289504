import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { contextSlice } from "../common/slice";
import { slices } from "../views";

const appReducer = combineReducers({ ...slices, context: contextSlice });
const rootReducer = (state, action) => {
  // Reset Store when changing project to avoid conflicts (stale data)
  if (action.type === "context/getProject/fulfilled") {
    return appReducer({ context: state.context }, action);
  }
  // Verify payload validity, cleanup context slice or reset store given the result
  if (action.type === "context/setToken") {
    const { payload } = action;
    const isValid = typeof payload === "string" && payload.includes("Bearer");
    const initialState = isValid ? state : {};
    return appReducer(initialState, { ...action, payload: isValid ? payload : null });
  }
  return appReducer(state, action);
};
/**
 * redux-thunk and additionnal middlewares are automatically set
 */
function store() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
    devTools: process.env.NODE_ENV === "development",
  });
}

export default store();
