import React from "react";
import { Link } from "react-router-dom";
import styles from "./CustomLink.module.css";

export default function CustomLink({
  children,
  className = "",
  disabled = false,
  external = false,
  openInNewTab = false,
  to,
}) {
  if (disabled) {
    return children;
  }
  return !external ? (
    <Link
      className={`${styles.customLink} ${className}`}
      rel={(openInNewTab && "noopener noreferrer") || "next"}
      target={(openInNewTab && "_blank") || "_self"}
      to={to}
    >
      {children}
    </Link>
  ) : (
    <a
      className={`${styles.customLink} ${className}`}
      href={to}
      rel={(openInNewTab && "noopener noreferrer") || "next"}
      target={(openInNewTab && "_blank") || "_self"}
    >
      {children}
    </a>
  );
}
