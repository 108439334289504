import React from "react";
import { TextField } from "@mui/material";
import { icon, CustomIconButton } from "../../../../..";
import { translate } from "../../../../../../providers";
import styles from "./MultiInput.module.css";

const MultiInput = ({
  disableLimitation,
  error = false,
  helperText,
  index,
  input,
  isLast,
  onAddNew,
  onChangeInput,
  onRemove,
  type = "text",
}) => {
  const handleChange = (e) => {
    onChangeInput(e.target.value, index);
  };
  const handleRemove = () => {
    onRemove(index);
  };
  return (
    <div className={styles.multiInput__container}>
      <TextField
        autoFocus
        fullWidth
        className={styles.multiInput_textField}
        data-testid="multiInput.textField"
        error={error}
        helperText={
          <>
            {!disableLimitation && input.length < 2 && (
              <span>{translate("common:component.filters.keyword-filter.min-input", { min: 2 })}&nbsp;</span>
            )}
            <span>
              {isLast && (helperText || translate("common:component.filters.keyword-filter.case-sensitivity"))}
            </span>
          </>
        }
        InputLabelProps={{ classes: { outlined: styles.multiInput__inputLabel } }}
        InputProps={{ className: styles.multiInput__input }}
        type={type}
        value={input}
        onChange={handleChange}
      />
      <div className={styles.multiInput__controlContainer}>
        <CustomIconButton
          btnClassName={styles.control__btn}
          color="primary"
          disabled={index === 0}
          icon={icon.faMinusSquare}
          iconClassName={styles.control__icon}
          onClick={handleRemove}
        />
        <CustomIconButton
          btnClassName={styles.control__btn}
          color="primary"
          icon={icon.faPlusSquare}
          iconClassName={styles.control__icon}
          onClick={onAddNew}
        />
      </div>
    </div>
  );
};

export default MultiInput;
