import client from "../client/client";

class SimilarContentService {
  getMostRelevantChaptersContents({ page = 0, limit = 50 }, { filters }, cancelToken) {
    return client.post("/searchcard/projects/deliverable/search", {
      payload: filters,
      params: { page, limit },
      config: { cancelToken },
    });
  }
  getDeliverablesFilters(cancelToken) {
    return client.get("/searchcard/saved/deliverable/search/filter", {
      config: { cancelToken },
    });
  }
}
export default new SimilarContentService();
