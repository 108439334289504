export const roles = {
  VALIDATOR: { role: "val", icon: "faCheckDouble" },
  REVIEWER: { role: "req", icon: "faCheck" },
  READER: { role: "req" },
  EDITOR: { role: "redr", icon: "faPen" },
};

export function generateIcon(option, mode) {
  if (mode === "role") {
    if (option[mode]) {
      return roles[option[mode]]?.icon || "";
    }
    return "faFilePdf";
  }
  if (mode === "precedence") {
    if (!option[mode]) {
      return "faFilePdf";
    }
    return null;
  }
  return option[mode] ? "faFolder" : "faFilePdf";
}

export function generateTitle(option, mode) {
  const number = (mode === "precedence" && option[mode] && `${option.number}.`) || "";
  if (option.count > 0) {
    let title = "";
    if (number) {
      title += `${number} `;
    }
    return `${title}${option[mode] || option.name || ""} (${option.count})`;
  }
  return `${number} ${option[mode] || option.name || ""}`;
}
