import { hasProperty } from "../utils";

let flags = null;

export const FLAGS = {
  ADMIN: "ADMIN",
  ADMIN_COMPANY: "ADMIN_COMPANY",
  ANALYTICALAXE: "ANALYTICALAXE",
  BACK_OFFICE: "BACK_OFFICE",
  CONTROL: "CONTROL",
  COVERAGE: "COVERAGE",
  DOCUMENT_CENTER: "DOCUMENT_CENTER",
  NORMS: "NORMS",
  NOT_IN_PRODUCTION: "NOT_IN_PRODUCTION",
  OPERATION: "OPERATION",
  PARTNERS: "PARTNERS",
  PROJECT_CREATION_ONBOARDING: "PROJECT_CREATION_ONBOARDING",
  QA: "QA",
  REDRESSMENT: "REDRESSMENT",
  REQUIREMENT_MATRIX: "REQUIREMENT_MATRIX",
  REQ_REVIEW: "REQ_REVIEW",
  REQ_VALIDATION: "REQ_VALIDATION",
  SEARCHCARD: "SEARCHCARD",
  SEARCHCARD_ACTIONS: "SEARCHCARD_ACTIONS",
  SELECT_SYNTHESIS: "SELECT_SYNTHESIS",
  SR_COMMENTS: "SR_COMMENTS",
  SR_PANEL_POSITIONS: "SR_PANEL_POSITIONS",
  SR_QUESTIONS: "SR_QUESTIONS",
  TASK: "TASK",
  THEMATIC: "THEMATIC",
  SEARCHTEMPLATE: "SEARCHTEMPLATE",
  THEMATIC_TEMPLATE: "THEMATIC_TEMPLATE",
  WRITE_PLUGIN: "WRITE_PLUGIN",
  COVERAGE_PLUGIN: "COVERAGE_PLUGIN",
};
export const setFlags = (flgs) => {
  flags = flgs;
};
export function isFeatureEnabled(flag) {
  if (!flag || !flags) {
    return false;
  }
  if (Array.isArray(flag) && flag.length > 0) {
    return flag.every((f) => !!isFeatureEnabled(f));
  }
  return hasProperty(flags, flag) && flags[flag];
}
