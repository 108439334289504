export function removeNullFilters(filters) {
  const newFilters = {};
  Object.entries(filters).forEach(([fKey, value]) => {
    if (!fKey || value === undefined || value === null) {
      return;
    }
    newFilters[fKey] = value;
  });
  return newFilters;
}
