import {
  hasCompanyPermission,
  hasPermission,
  isFeatureEnabled,
  isSegFeatureEnabled,
  isCompanyTypeAllowed,
} from "../../common/providers";

export const getProjectIdFromURL = (url) => {
  const projectURI = "/projects/";
  const projectURIIndex = url.indexOf(projectURI);
  if (projectURIIndex === -1) {
    return "";
  }
  return url.substring(projectURIIndex + projectURI.length).split("/", 1)[0];
};
export const checkValidity = ({ companyRole, flag, role, segFlag, allowedCompanyTypes } = {}) => {
  const checks = [
    flag ? isFeatureEnabled(flag) : true,
    segFlag ? isSegFeatureEnabled(segFlag) : true,
    role ? hasPermission(role) : true,
    companyRole ? hasCompanyPermission(companyRole) : true,
    allowedCompanyTypes ? isCompanyTypeAllowed(allowedCompanyTypes) : true,
  ];
  return checks.every(Boolean);
};
