export function drawSelectionBox(boxElement, start, end) {
  const b = boxElement;
  if (end.left > start.left) {
    b.style.left = `${start.left}px`;
    b.style.width = `${end.left - start.left}px`;
  } else {
    b.style.left = `${end.left}px`;
    b.style.width = `${start.left - end.left}px`;
  }
  if (end.top > start.top) {
    b.style.top = `${start.top}px`;
    b.style.height = `${end.top - start.top}px`;
  } else {
    b.style.top = `${end.top}px`;
    b.style.height = `${start.top - end.top}px`;
  }
}
export function handleCalculateRequestCoordinates({ start, end, pageDimensions, zoom }) {
  if (!start || !end) {
    return [];
  }
  let fromTop = 0;
  let page = 0;
  let selectedArea = [];
  const adjustedLeft = start.left < end.left ? start.left : end.left;
  const adjustedRight = start.left < end.left ? end.left : start.left;
  const adjustedTop = start.top < end.top ? start.top : end.top;
  const adjustedBottom = start.top < end.top ? end.top : start.top;
  while (fromTop < adjustedBottom) {
    if (fromTop + pageDimensions?.[page].height * zoom > adjustedTop) {
      if (adjustedBottom < fromTop + pageDimensions?.[page].height * zoom) {
        if (adjustedTop > fromTop) {
          // Only page
          const x1 = adjustedLeft / pageDimensions[page].width / zoom;
          const x2 = adjustedRight / pageDimensions[page].width / zoom;
          const y1 = (adjustedTop - fromTop) / pageDimensions[page].height / zoom;
          const y2 = (adjustedBottom - fromTop) / pageDimensions[page].height / zoom;
          if (x1 < 1 && x2 > 0) {
            selectedArea = [
              ...selectedArea,
              { pageId: page + 1, x1: x1 < 0 ? 0 : x1, x2: x2 > 1 ? 1 : x2, y1: y1 < 0 ? 0 : y1, y2: y2 > 1 ? 1 : y2 },
            ];
          }
        } else {
          // Last Page
          const x1 = adjustedLeft / pageDimensions[page].width / zoom;
          const x2 = adjustedRight / pageDimensions[page].width / zoom;
          const y2 = (adjustedBottom - fromTop) / pageDimensions[page].height / zoom;
          if (x1 < 1 && x2 > 0) {
            selectedArea = [
              ...selectedArea,
              { pageId: page + 1, x1: x1 < 0 ? 0 : x1, x2: x2 > 1 ? 1 : x2, y1: 0, y2: y2 > 1 ? 1 : y2 },
            ];
          }
        }
      } else if (adjustedBottom > fromTop + pageDimensions?.[page].height * zoom && adjustedTop < fromTop) {
        // Middle page;
        const x1 = adjustedLeft / pageDimensions[page].width / zoom;
        const x2 = adjustedRight / pageDimensions[page].width / zoom;
        if (x1 < 1 && x2 > 0) {
          selectedArea = [
            ...selectedArea,
            { pageId: page + 1, x1: x1 < 0 ? 0 : x1, x2: x2 > 1 ? 1 : x2, y1: 0, y2: 1 },
          ];
        }
      } else {
        const x1 = adjustedLeft / pageDimensions[page].width / zoom;
        const x2 = adjustedRight / pageDimensions[page].width / zoom;
        const y1 = (adjustedTop - fromTop) / pageDimensions[page].height / zoom;
        if (x1 < 1 && x2 > 0) {
          selectedArea = [
            ...selectedArea,
            { pageId: page + 1, x1: x1 < 0 ? 0 : x1, x2: x2 > 1 ? 1 : x2, y1: y1 < 0 ? 0 : y1, y2: 1 },
          ];
        }
      }
    }
    fromTop += pageDimensions?.[page].height * zoom;
    page++;
  }
  return selectedArea;
}
