import React, { useEffect } from "react";
import styles from "./SelectionLayer.module.css";
import { drawSelectionBox } from "../utils/utils";

const SelectionLayer = ({ end, selectionRectangleRef, start }) => {
  useEffect(() => {
    if (start && end && selectionRectangleRef?.current) {
      drawSelectionBox(selectionRectangleRef.current, start, end);
    }
  }, [start, end]);

  return start && end && <div ref={selectionRectangleRef} className={styles.selectionRectangle} />;
};

export default SelectionLayer;
