/* global Office */
import React, { useEffect, useRef, useState } from "react";
import styles from "./IdentifiedContentBox.module.css";
import {
  CustomButton,
  CustomDialog,
  CustomIconButton,
  CustomTooltip,
  VerticalDivider,
  createNotification,
  icon,
} from "../../../../common/components";
import { translate, translateDate } from "../../../../common/providers";
import { ContentService, DocumentService } from "../../../../api";
import { WordUtils } from "../../../../common/utils";
import { useApi } from "../../../../common/hooks";
import { useSelector } from "react-redux";
import { TABS } from "../../constants/tabs";

const IdentifiedContentBox = ({
  disableRemove = false,
  identifiedContent,
  onDelete,
  onDisplay,
  onDisplaySelection,
  onSaveContent,
}) => {
  const { call: onGetChapterString } = useApi(ContentService.getChapterString);
  const { call: deleteSavedContent } = useApi(ContentService.deleteSavedContent);
  const { call: onDownloadDocument } = useApi(DocumentService.downloadDocument);
  const { call: onGetPreviewInformationChapter } = useApi(ContentService.getPreviewInformationChapter);
  const selectedTab = useSelector(({ multiProjectSearch }) => multiProjectSearch.selectedTab);
  const [isContentOverflowing, setIsContentOverflowing] = useState(false);
  const [textToInsert, setTextToInsert] = useState("");
  const [numberOfPages, setNumberOfPages] = useState("");
  const [hide, setHide] = useState(true);
  const [localIdentifiedContent, setLocalIdentifiedContent] = useState(identifiedContent);
  const textRef = useRef(null);

  const handleContentOverflow = () => {
    setIsContentOverflowing(textRef.current?.scrollHeight > textRef.current?.offsetHeight);
  };
  useEffect(() => {
    handleContentOverflow();
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleContentOverflow);
    return () => {
      window.removeEventListener("resize", handleContentOverflow);
    };
  }, []);
  const handleShowMore = () => {
    setHide((prev) => !prev);
  };

  const handleInsert = () => {
    WordUtils.insertTextToWord(textToInsert);
    setTextToInsert("");
    setNumberOfPages(0);
  };
  const handleCloseInsertDialog = () => {
    setTextToInsert("");
    setNumberOfPages(0);
  };
  const requestContentString = () => {
    if (localIdentifiedContent) {
      onGetChapterString({
        informationId: localIdentifiedContent.informationId,
        docId: localIdentifiedContent.documentId,
      })
        .then((data) => {
          if (data.numberOfPages < 3) {
            WordUtils.insertTextToWord(data.allContent.replaceAll("\\", "\n"));
          } else {
            setTextToInsert(data.allContent.replaceAll("\\", "\n"));
            setNumberOfPages(data.numberOfPages);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const handleCopy = () => {
    onGetChapterString({
      informationId: localIdentifiedContent.informationId,
      docId: localIdentifiedContent.documentId,
    })
      .then((data) => {
        const textToInsert = data.allContent.replaceAll("\\", "\n");
        navigator.clipboard
          .writeText(textToInsert)
          .then(() =>
            createNotification({
              type: "info",
              message: translate("common:notification.content-copied"),
            })
          )
          .catch((err) => {
            console.error(err);
            WordUtils.insertTextToWord(textToInsert);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleUpdateSavedId = (savedId) => {
    setLocalIdentifiedContent((prev) => ({ ...prev, savedId }));
  };
  const handleSaveContent = () => {
    const { projectId, documentId, informationId } = localIdentifiedContent;
    onGetPreviewInformationChapter({ docId: documentId, informationId })
      .then((data) => {
        onSaveContent({ projectId, documentId, informationId, previewResponse: data }, handleUpdateSavedId);
      })
      .catch((err) => console.error(err));
  };
  const handleDeleteContent = () => {
    deleteSavedContent({ savedDataId: localIdentifiedContent.savedId })
      .then(() => handleUpdateSavedId(undefined))
      .catch(console.error);
  };
  const handleClickDisplay = () => {
    const { projectId, documentId, informationId } = localIdentifiedContent;
    onGetPreviewInformationChapter({ docId: documentId, informationId })
      .then((data) =>
        onDisplay({
          projectId,
          documentId,
          informationId,
          previewResponse: data,
          identifiedContent: { ...localIdentifiedContent, onCallBack: handleUpdateSavedId },
        })
      )
      .catch((err) => console.error(err));
  };
  const handleDisplayChapter = () => {
    onDisplaySelection(localIdentifiedContent);
  };
  const handleOpenNewWord = () => {
    onDownloadDocument({ docId: localIdentifiedContent.documentId })
      .then(({ data, filename }) => WordUtils.openDocumentInNewFile({ data, filename }))
      .catch((err) => console.error(err));
  };
  return (
    <div className={styles.container}>
      <div className={styles.hoverContainer}>
        {(selectedTab === TABS.SEARCH && (
          <>
            <CustomIconButton
              color="secondary"
              icon={icon.faArrowLeft}
              size="xs"
              tooltip={translate("common:btn.insert")}
              variant="outlined"
              onClick={requestContentString}
            />
            <CustomIconButton
              color="secondary"
              icon={icon.faFileLines}
              size="xs"
              tooltip={translate("common:btn.display")}
              variant="outlined"
              onClick={handleClickDisplay}
            />
            {Office.context.platform !== Office.PlatformType.OfficeOnline && (
              <CustomIconButton
                color="secondary"
                icon={icon.faCopy}
                size="xs"
                tooltip={translate("common:btn.copy")}
                variant="outlined"
                onClick={handleCopy}
              />
            )}
            <CustomIconButton
              color="secondary"
              icon={localIdentifiedContent.savedId ? icon.faBookmark : icon.faBookmarkRegular}
              size="xs"
              tooltip={translate(localIdentifiedContent.savedId ? "common:btn.unsave" : "common:btn.save")}
              variant="outlined"
              onClick={localIdentifiedContent.savedId ? handleDeleteContent : handleSaveContent}
            />
          </>
        )) || (
          <>
            <CustomIconButton
              color="secondary"
              icon={icon.faFileLines}
              size="xs"
              tooltip={translate("common:btn.display")}
              variant="outlined"
              onClick={handleDisplayChapter}
            />
            {!disableRemove && (
              <CustomIconButton
                color="secondary"
                icon={icon.faBookmark}
                size="xs"
                tooltip={translate("common:btn.delete")}
                variant="outlined"
                onClick={() => onDelete(localIdentifiedContent.savedDataId)}
              />
            )}
          </>
        )}
      </div>

      <div>
        <div className={styles.additionalInformation__nameDate}>
          {translateDate(localIdentifiedContent.projectCreationDate)}
          <VerticalDivider inFlexContainer />
          <CustomTooltip showWhenOverflow title={localIdentifiedContent.projectName}>
            <div className={styles.projectName}>{localIdentifiedContent.projectName}</div>
          </CustomTooltip>
          <VerticalDivider inFlexContainer />
          <CustomTooltip showWhenOverflow title={localIdentifiedContent.projectType}>
            <div className={styles.projectName}>{localIdentifiedContent.projectType}</div>
          </CustomTooltip>
        </div>
        <div ref={textRef} className={styles.content} data-hide={hide}>
          {localIdentifiedContent.content}
        </div>
        {isContentOverflowing && (
          <CustomButton className={styles.informationContent__btn} color="primary" onClick={handleShowMore}>
            {(hide && translate("common:btn.show-more")) || translate("common:btn.show-less")}
          </CustomButton>
        )}
      </div>
      <div className={styles.additionalInformation}>
        <CustomTooltip showWhenOverflow title={localIdentifiedContent.documentName}>
          <div className={styles.documentName} role="presentation" onClick={handleOpenNewWord}>
            {localIdentifiedContent.documentName}
          </div>
        </CustomTooltip>
      </div>
      <CustomDialog
        iconColor="var(--color-red)"
        iconModel={icon.faExclamationTriangle}
        open={textToInsert && numberOfPages}
        submitLabel={translate("common:btn.validate")}
        subTitle={translate("multi-project-search.identified-content-box.sub-title", { numberOfPages })}
        title={translate("common:btn.insert-chapter")}
        onClose={handleCloseInsertDialog}
        onSubmit={handleInsert}
      />
    </div>
  );
};

export default IdentifiedContentBox;
