export function getColorOnCondition({ iconColor, variant, color, disabled }) {
  if (!iconColor) {
    if (variant === "contained") {
      if (color === "primary") {
        return "--color-white";
      }
      if (color === "secondary") {
        return "--color-dark-grey-2";
      }
    } else {
      if (color === "primary") {
        return "--color-blue";
      }
      if (color === "secondary") {
        return "--color-light-grey-2";
      }
    }
  }
  return disabled ? "var(--color-light-grey-2)" : iconColor;
}
