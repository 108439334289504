import React, { useEffect, useState } from "react";
import styles from "./UserPrompts.module.css";
import { isNonEmptyArray } from "../../../../../../../common/utils";
import { translate } from "../../../../../../../common/providers";

import CustomTextField from "../../../../../../../common/components/text-fields/text-field/CustomTextField";
import AdditionalInfoBox from "../additional-info-box/AdditionalnfoBox";

const UserPrompts = ({ additionalContext, onAdd, onRemove }) => {
  const [tempUserPromp, setTempUserPromp] = useState("");
  useEffect(() => {
    const handleKeyDetect = (e) => {
      if (e.repeat) {
        return;
      }
      if (e.key === "Enter") {
        onAdd(tempUserPromp);
        setTempUserPromp("");
      }
    };
    window.addEventListener("keydown", handleKeyDetect, true);
    return () => {
      window.removeEventListener("keydown", handleKeyDetect, true);
    };
  }, [tempUserPromp]);
  const handleChangeTempContext = (e) => {
    const { value } = e.target;
    setTempUserPromp(value);
  };
  return (
    <>
      <CustomTextField
        fullWidth
        className={styles.textfield}
        placeholder={translate("multi-projects-search.generate.add-item-to-prompt")}
        size="sm"
        value={tempUserPromp}
        onChange={handleChangeTempContext}
      />
      <div className={styles.additional__helper}>
        {translate("multi-projects-search.generate.btn.improve-proposal")}
      </div>
      <div className={styles.additionalContextContainer}>
        {isNonEmptyArray(additionalContext) &&
          additionalContext.map((ac) => <AdditionalInfoBox key={ac} value={ac} onRemove={onRemove} />)}
      </div>
    </>
  );
};

export default UserPrompts;
