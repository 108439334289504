export const KEYWORD_FILTER_TYPE = {
  PARAGRAPH: "PARAGRAPH",
  TOC: "TOC",
};
export const KEYWORD_FILTER_OPERATOR = {
  IS_EXACTLY: "IS_EXACTLY",
  IS_NOT_EXACTLY: "IS_NOT_EXACTLY",
  START_WITH: "START_WITH",
  START_NOT_WITH: "START_NOT_WITH",
};

export const OPERATORS = {
  AND: "AND",
  OR: "OR",
};

export const IS_SELECTOR = {
  IS: "IS",
  IS_NOT: "IS_NOT",
};

export const ANY_ALL_OPERATOR = {
  ANY: "ANY",
  ALL: "ALL",
};
