import { isFeatureEnabled, hasPermission, hasCompanyPermission, Flags } from "../../common/providers";

const WRONG_FLAG = "Wrong flag denomination";
const WRONG_ROLE = "Wrong role denomination";
const MISSING_INFO = "Missing view information";

export const checkValidity = ({ flag, role, companyRole } = {}) => {
  const checks = [
    flag ? isFeatureEnabled(flag) : true,
    role ? hasPermission(role) : true,
    companyRole ? hasCompanyPermission(companyRole) : true,
  ];
  return checks.every(Boolean);
};
function validateFlag(flag) {
  if (flag && !Array.isArray(flag) && !Object.values(Flags).includes(flag)) {
    throw Error(WRONG_FLAG);
  } else if (flag && Array.isArray(flag) && flag.length > 0) {
    flag.forEach((f) => {
      if (!Object.values(Flags).includes(f)) {
        throw Error(WRONG_FLAG);
      }
    });
  }
}
function validateRole(role) {
  if (role && !Array.isArray(role) && !Object.values(Permissions).includes(role)) {
    throw Error(WRONG_ROLE);
  } else if (role && Array.isArray(role) && role.length > 0) {
    role.forEach((r) => {
      if (!Object.values(Permissions).includes(r)) {
        throw Error(WRONG_ROLE);
      }
    });
  }
}

export const exportView = ({ path, localesPath, component, flag, role, companyRole }) => {
  if (!component || Array.isArray(component)) {
    throw Error(MISSING_INFO);
  }
  validateFlag(flag);
  validateRole(role);
  validateRole(companyRole);
  if (!path || (Array.isArray(path) && path.length === 0)) {
    throw Error(MISSING_INFO);
  }
  if (Array.isArray(path) && path.length > 0) {
    path.forEach((p) => {
      if (!p.path) {
        throw Error(MISSING_INFO);
      }
      validateFlag(p.flag);
      validateRole(p.role);
      validateRole(p.companyRole);
    });
  }
  return { path, localesPath, component, flag, role, companyRole };
};
