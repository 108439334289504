import React, { useRef, useState } from "react";
import { hasCompanyPermission, hasPermission, Permissions, translate } from "../../../../common/providers";
import { CustomButton, CustomTooltip, DropDown, HorizontalDivider, icon } from "../../../../common/components";
import styles from "./CoverageDropDown.module.css";
import { MenuItem, MenuList, Radio } from "@mui/material";
import { useSelector } from "react-redux";

const CoverageDropDown = ({ covered, onUpdateRequirementCoverage, paragraphCount, reqId }) => {
  const deliverableOwnerId = useSelector(({ context }) => context.deliverable?.ownerId);
  const userId = useSelector(({ context }) => context.user.id);
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleUpdateCoverage = (value) => {
    onUpdateRequirementCoverage({ value, reqId });
    handleClose();
  };
  return (
    <>
      {((deliverableOwnerId === userId ||
        (hasPermission([Permissions.PROJECT_LEADER]) && hasCompanyPermission(Permissions.PROJECT_LEADER))) && (
        <CustomButton
          ref={dropdownRef}
          className={styles.cover}
          color="secondary"
          data-covered={covered}
          endIcon={icon.faChevronDown}
          size="sm"
          variant="outlined"
          onClick={handleOpen}
        >
          {covered
            ? translate("requirements-to-deliverables.requirement-box.covered")
            : translate("requirements-to-deliverables.requirement-box.to-cover")}
          {(paragraphCount > 0 && ` | ${paragraphCount}`) || ""}
        </CustomButton>
      )) || (
        <CustomTooltip title={translate("requirements-to-deliverables.requirement-box.no-rights.tooltip")}>
          <span className={styles.cover} data-covered={covered}>
            {covered
              ? translate("requirements-to-deliverables.requirement-box.covered")
              : translate("requirements-to-deliverables.requirement-box.to-cover")}
            {(paragraphCount > 0 && ` | ${paragraphCount}`) || ""}
          </span>
        </CustomTooltip>
      )}
      <DropDown ref={dropdownRef} open={open} position="bottom-end" onClose={handleClose}>
        <MenuList className={styles.menuContainer}>
          <MenuItem className={styles.menuItem} data-selected={!covered} onClick={() => handleUpdateCoverage(false)}>
            <Radio checked={!covered} className={styles.radio} size="small" />
            {translate("requirements-to-deliverables.requirement-box.to-cover")}
          </MenuItem>
          <HorizontalDivider className={styles.divider} />
          <MenuItem className={styles.menuItem} data-selected={covered} onClick={() => handleUpdateCoverage(true)}>
            <Radio checked={covered} className={styles.radio} size="small" />
            {translate("requirements-to-deliverables.requirement-box.covered")}
          </MenuItem>
        </MenuList>
      </DropDown>
    </>
  );
};

export default CoverageDropDown;
