import React, { useMemo } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { CustomIconButton, IconComponent } from "../../";
import styles from "./CustomTextField.module.css";

const handleAdornment = (icon, iconPlacement, onClickIcon) => {
  if (!onClickIcon && !icon) {
    return null;
  }
  if (!onClickIcon) {
    return {
      endAdornment: (
        <InputAdornment position={iconPlacement}>
          <IconComponent color={"var(--color-light-grey-1)"} icon={icon} size="md" />
        </InputAdornment>
      ),
    };
  }
  return {
    endAdornment: (
      <InputAdornment position={iconPlacement}>
        <CustomIconButton icon={icon} onClick={onClickIcon} />
      </InputAdornment>
    ),
  };
};

const CustomTextField = ({
  className = "",
  error = false,
  fullWidth = false,
  helperText = "",
  icon = null,
  iconPlacement = "start",
  label = "",
  multiline = false,
  onChange = null,
  onClickIcon = null,
  placeholder = "",
  required = false,
  rows = 1,
  size = "md",
  type = "text",
  value = "",
  variant = "outlined",
}) => {
  const InputProps = useMemo(
    () => handleAdornment(icon, iconPlacement, onClickIcon),
    [icon, iconPlacement, onClickIcon]
  );
  return (
    <TextField
      className={`${styles.text} ${className}`}
      data-size={size}
      error={error}
      fullWidth={fullWidth}
      helperText={helperText}
      InputProps={InputProps}
      label={label}
      multiline={multiline}
      placeholder={placeholder}
      required={required}
      rows={rows}
      type={type}
      value={value}
      variant={variant}
      onChange={onChange}
    />
  );
};

export default CustomTextField;
