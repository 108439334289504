import { ButtonGroup, ClickAwayListener, Popper } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { translate } from "../../../../providers";
import styles from "./FilterWrapper.module.css";
import { IS_SELECTOR, KEYWORD_FILTER_OPERATOR, OPERATORS } from "../../../../constants";
import { FilterSearch } from "../components";
import { FilterSelector } from "../../components";
import { CustomTooltip, I18nTranslate, IconComponent, icon, CustomButton, CircularLoader } from "../../..";
import { isNonEmptyArray } from "../../../../utils";

export default function FilterWrapper({
  applyDisabled = false,
  appliedValue = null,
  children,
  customLabelApply = null,
  defaultOpened = false,
  description = null,
  dynamicLabel = "",
  disableNoResult = false,
  disablePortal = true,
  disabled,
  filterIndex,
  filterKey,
  filterPopUpPlacement = "bottom-start",
  hasAdvancedSearch = false,
  hasAndOrOperator = false,
  hasClearSelection = false,
  hasIsSeparator = false,
  hasNegative = false,
  hasSearch = false,
  hidden = false,
  iconName = null,
  isActive,
  isPreviewLoading,
  label = "",
  minSearch = 0,
  onApply,
  onChange,
  onClearSelection = null,
  onClose,
  onMount,
  onRemove,
  onSearch = null,
  previewCount,
  resizable = false,
  search,
  toApply = true,
  value = null,
  withDescription = true,
}) {
  const labelBtnRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [allowDisplay, setAllowDisplay] = useState(defaultOpened);
  const [is, setIs] = useState(IS_SELECTOR.IS);
  const [andOr, setAndOr] = useState(OPERATORS.OR);
  const isDisabledRemove = useMemo(() => filterKey === "tocFilters" || filterKey === "keywordFilters", [filterKey]);
  const isOnlyPositive = useMemo(
    () =>
      (value?.filters?.[filterIndex]?.operator === KEYWORD_FILTER_OPERATOR.IS_EXACTLY ||
        value?.filters?.[filterIndex]?.operator === KEYWORD_FILTER_OPERATOR.START_WITH) &&
      value?.filters?.filter(
        (v) => v.operator === KEYWORD_FILTER_OPERATOR.IS_EXACTLY || v.operator === KEYWORD_FILTER_OPERATOR.START_WITH
      ).length <= 1,
    [value, filterIndex]
  );
  const open = Boolean(anchorEl);
  const handleOpen = () => {
    setAnchorEl(labelBtnRef.current);
  };
  useEffect(() => {
    onMount(!toApply);
    setAllowDisplay(true);
  }, [toApply]);
  useEffect(() => {
    if (defaultOpened) {
      handleOpen();
    }
  }, [defaultOpened]);

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };
  const handleApply = () => {
    setAnchorEl(null);
    onApply();
  };
  const handleSetIsSeparator = (entry) => {
    setIs(entry);
    onChange({ ...value, is: IS_SELECTOR.IS === entry });
  };
  const handleSearch = (searchValue) => {
    if (onSearch && typeof onSearch === "function") {
      onSearch(searchValue?.length >= minSearch ? searchValue : "");
    }
  };
  const handleAndOrOperator = (entry) => {
    setAndOr(entry);
    onChange({ ...value, matchAll: OPERATORS.AND === entry });
  };
  useEffect(() => {
    if (value?.is !== undefined) {
      setIs(value.is ? IS_SELECTOR.IS : IS_SELECTOR.IS_NOT);
    }
  }, [value?.is]);
  useEffect(() => {
    if (value?.matchAll !== undefined) {
      setAndOr(value.matchAll ? OPERATORS.AND : OPERATORS.OR);
    }
  }, [value?.matchAll]);
  const handleTooltip = () => {
    if (filterKey === "tocFilters") {
      return translate(
        "common:filters.keywords.disable-negative-filters.toc.cannot-remove-last-positive-when-negatives"
      );
    }
    return translate(
      "common:filters.keywords.disable-negative-filters.paragraph.cannot-remove-last-positive-when-negatives"
    );
  };
  const handleActiveLabelTitle = () => {
    if (dynamicLabel && filterIndex >= 0 && isNonEmptyArray(value?.filters?.[filterIndex]?.keywords)) {
      const filterKeywords = value.filters[filterIndex].keywords;
      let elements = filterKeywords[0];
      if (filterKeywords.length > 1) {
        elements += ` + ${filterKeywords.length - 1}`;
      }
      return (
        <I18nTranslate
          param={{
            elements,
          }}
          translationKey={`${dynamicLabel}.${value.filters[filterIndex].operator.toLowerCase().replaceAll("_", "-")}`}
        />
      );
    }
    return <>{translate(label)}</>;
  };
  // Preventing filter wrapper bar to be scrolled with arrow keys
  const handleKeyDown = (e) => {
    if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
      e.preventDefault();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    (allowDisplay &&
      !hidden &&
      ((toApply && (
        <>
          <div>
            {!isActive && (
              <CustomButton
                ref={labelBtnRef}
                className={styles.nonActive}
                color="secondary"
                disabled={disabled}
                endIcon={icon.faChevronDown}
                size="md"
                startIcon={iconName}
                variant="outlined"
                onClick={handleOpen}
              >
                {translate(label)}
              </CustomButton>
            )}
            {isActive && (
              <CustomTooltip
                arrow
                placement="right"
                title={isDisabledRemove && hasNegative && isOnlyPositive ? handleTooltip() : ""}
              >
                <ButtonGroup className={styles.buttonGroup} color="primary" disabled={disabled} variant="outlined">
                  <CustomButton
                    ref={labelBtnRef}
                    className={styles.buttonGroup__open}
                    color="primary"
                    size="md"
                    startIcon={iconName}
                    variant="outlined"
                    onClick={handleOpen}
                  >
                    {handleActiveLabelTitle()}
                  </CustomButton>
                  <CustomButton
                    className={styles.buttonGroup__remove}
                    color="primary"
                    disabled={isDisabledRemove && hasNegative && isOnlyPositive}
                    size="md"
                    startIcon={icon.faTimes}
                    tooltipPlacement="bottom"
                    variant="outlined"
                    onClick={() => onRemove(filterKey)}
                  />
                </ButtonGroup>
              </CustomTooltip>
            )}
            {open && (
              <ClickAwayListener mouseEvent="onClick" onClickAway={handleClose}>
                <Popper
                  anchorEl={anchorEl}
                  className={`${styles.popup} ${resizable ? styles["popup--resizable"] : ""}`}
                  disablePortal={disablePortal}
                  open={open}
                  placement={filterPopUpPlacement}
                >
                  <div className={styles.popup__header}>
                    <div className={styles.header__title}>
                      <span>
                        {iconName && <IconComponent color="var(--color-light-grey-1)" icon={iconName} />}
                        {translate(label)}
                        {!!hasIsSeparator && (
                          <FilterSelector
                            itemRenderer={(entry) =>
                              translate(
                                `common:component.filters.is-button.${entry.toLowerCase().replace("_", "-")}`
                              ).toLowerCase()
                            }
                            items={Object.values(IS_SELECTOR)}
                            value={is}
                            onChange={handleSetIsSeparator}
                          />
                        )}
                      </span>
                      {withDescription && description && (
                        <span className={styles.header__title_description}>{description}</span>
                      )}
                    </div>
                  </div>
                  <div className={styles["popup__content--header"]}>
                    {hasSearch && (
                      <>
                        <FilterSearch
                          fullWidth={!hasAdvancedSearch}
                          minSearch={minSearch}
                          search={search}
                          onSearch={handleSearch}
                        />
                        {hasAdvancedSearch && (
                          <FilterSelector
                            itemRenderer={(entry) =>
                              translate(
                                `common:component.filters.is-button.${entry[0].toLowerCase().replace("_", "-")}`
                              ).toLowerCase()
                            }
                            items={Object.values(IS_SELECTOR)}
                            value={is}
                            onChange={handleSetIsSeparator}
                          />
                        )}
                      </>
                    )}

                    {hasAndOrOperator && (
                      <div className={styles.selection__row}>
                        <FilterSelector
                          itemRenderer={(itemValue) => translate(`common:enum.separator.${itemValue?.toLowerCase()}`)}
                          items={Object.values(OPERATORS)}
                          value={andOr}
                          onChange={handleAndOrOperator}
                        />
                        <CustomButton color="secondary" size="sm" variant="outlined" onClick={onClearSelection}>
                          {translate("common:btn.clear-select")}
                        </CustomButton>
                      </div>
                    )}
                    {hasClearSelection && (
                      <div className={styles.clearSelection__row}>
                        <CustomButton color="secondary" size="sm" variant="outlined" onClick={onClearSelection}>
                          {translate("common:btn.clear-select")}
                        </CustomButton>
                      </div>
                    )}
                  </div>
                  <div className={styles.popup__content}>{children}</div>
                  <div className={styles.popup__footer}>
                    <CustomButton
                      fullWidth
                      disabled={applyDisabled || isPreviewLoading || (disableNoResult && previewCount === 0)}
                      variant="contained"
                      onClick={handleApply}
                    >
                      {(isPreviewLoading && <CircularLoader color="inherit" size={25} />) ||
                        (appliedValue !== value && (
                          <>
                            {(typeof customLabelApply === "function" &&
                              value !== null &&
                              customLabelApply(previewCount)) ||
                              `${
                                applyDisabled || previewCount === null || value === null ? "" : previewCount
                              } ${translate("common:btn.apply")}`}
                          </>
                        )) ||
                        translate("common:btn.close")}
                    </CustomButton>
                  </div>
                </Popper>
              </ClickAwayListener>
            )}
          </div>
        </>
      )) || (
        <div className={styles.notAppliable} data-disabled={disabled}>
          {children}
        </div>
      ))) ||
    null
  );
}
