import Keycloak from "keycloak-js";

const kc = new Keycloak("/keycloak.json");

const doLogin = kc.login;
const doLogout = kc.logout;
const getToken = () => `Bearer ${kc.token}`;
const clearToken = () => kc.clearToken();
const isLoggedIn = () => !!kc.token;
const updateToken = (successCallback) => kc.updateToken(295).then(successCallback).catch(doLogout);
const getUsername = () => kc.tokenParsed?.preferred_username;
const hasRole = (roles) => roles.some((role) => kc.hasRealmRole(role));
const kcAll = () => kc;
const userLanguage = () => {
  switch (kc.tokenParsed?.locale) {
    case "fr":
      return "fr";
    case "es":
      return "esp";
    default:
      return "en";
  }
};

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  kc.init({
    checkLoginIframe: false,
    pkceMethod: "S256",
    onLoad: "check-sso",
  })
    .then((authenticated) => {
      if (authenticated) {
        if (onAuthenticatedCallback && typeof onAuthenticatedCallback === "function") {
          onAuthenticatedCallback();
        }
      }
    })
    .catch((err) => console.error("This is error: ", err));
};

const KeycloakService = {
  clearToken,
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  kcAll,
  userLanguage,
};

export default KeycloakService;
