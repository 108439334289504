import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const CustomSelect = ({
  disabled = false,
  label = "",
  items = [],
  onChange,
  fullWidth = false,
  selectedValue = null,
  defaultValue = null,
  field = "name",
  menuItem = null,
  className = "",
}) => {
  return (
    <FormControl className={className} fullWidth={fullWidth}>
      <InputLabel id="asd">{label}</InputLabel>
      <Select defaultValue={defaultValue} disabled={disabled} label={label} value={selectedValue} onChange={onChange}>
        {Array.isArray(items) &&
          items.length > 0 &&
          items.map((item) => (
            <MenuItem key={item.id || item} value={item}>
              {(menuItem && menuItem(item)) || item[field]}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
