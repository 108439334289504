import React, { useEffect, useState } from "react";
import { CustomButton } from "../../../buttons";
import styles from "./ProjectFilters.module.css";
import { translate } from "../../../../providers";
import { useApi } from "../../../../hooks";
import { MyService } from "../../../../../api";
import { InfiniteList2 } from "../../../infinite-list";
import { ProjectRow } from "./components";

const ProjectFilters = ({ onChange, search, value = [] }) => {
  const { call: getProjects } = useApi(MyService.getProjects);
  const [sort, setSort] = useState("created");
  const [infiniteListRequest, setInfiniteListRequest] = useState(null);
  useEffect(() => {
    setInfiniteListRequest(
      () =>
        ({ page, limit }) =>
          getProjects({ page, limit, sort }, { name: search ? search : undefined, isOnlyDeliverables: true })
    );
  }, [sort, search]);
  const handleChangeSort = (value) => {
    setSort(value);
  };
  const handleSelect = (projectId, isSelected) => {
    const newValue = value || [];
    if (isSelected) {
      onChange(newValue.filter((v) => v !== projectId));
    } else {
      onChange([...newValue, projectId]);
    }
  };
  const rowRenderer = (row) => {
    return (
      <ProjectRow key={row.projectId} isSelected={!!value?.includes(row.projectId)} row={row} onSelect={handleSelect} />
    );
  };
  return (
    <div>
      <div className={styles.sortContainer}>
        <CustomButton
          className={styles.leftButton}
          color={sort === "created" ? "primary" : "secondary"}
          size="sm"
          variant="outlined"
          onClick={() => handleChangeSort("created")}
        >
          {translate("common:component.filters.project.sort.by-date")}
        </CustomButton>
        <CustomButton
          className={styles.rightButton}
          color={sort === "name" ? "primary" : "secondary"}
          size="sm"
          variant="outlined"
          onClick={() => handleChangeSort("name")}
        >
          {translate("common:component.filters.project.sort.by-name")}
        </CustomButton>
      </div>
      <div>
        <InfiniteList2
          request={infiniteListRequest}
          rowRenderer={rowRenderer}
          searchPlaceholder={translate("common:project-selection-side-pane.search-a-project")}
        />
      </div>
    </div>
  );
};

export default ProjectFilters;
