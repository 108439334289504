import React, { useEffect, useState } from "react";
import {
  CustomIconButton,
  CustomTooltip,
  DocPreview,
  IconComponent,
  InfiniteList2,
  createNotification,
  generateFilters,
  icon,
} from "../../../../../../../common/components";
import { useApi } from "../../../../../../../common/hooks";
import { ContentService, DocumentService, SearchCardService, SimilarContentService } from "../../../../../../../api";
import { useSelector } from "react-redux";
import { translate } from "../../../../../../../common/providers";
import { EmptyState, IdentifiedContentBox } from "../../../..";
import { getPage, resetContent } from "../../../../../slice/multi-project-search-slice";
import styles from "./IdentifiedContents.module.css";
import { SEARCH_TABS } from "../../../../../constants/tabs";
import { isNonEmptyArray, WordUtils } from "../../../../../../../common/utils";

const IdentifiedContents = ({ delivFiltersLoaded, projectFiltersLoaded }) => {
  const { call: deleteSavedContent } = useApi(ContentService.deleteSavedContent);
  const { call: getMostRelevantChaptersContents } = useApi(SimilarContentService.getMostRelevantChaptersContents);
  const { call: getMostRelevantContentInProject } = useApi(SearchCardService.search);
  const { call: saveContent } = useApi(ContentService.saveContent);
  const { call: onDownloadDocument } = useApi(DocumentService.downloadDocument);
  const filters = useSelector(({ multiProjectSearch }) => multiProjectSearch.filters);
  const projectFilters = useSelector(({ multiProjectSearch }) => multiProjectSearch.projectFilters);
  const selectedSearchTab = useSelector(({ multiProjectSearch }) => multiProjectSearch.selectedSearchTab);
  const projectId = useSelector(({ context }) => context.project?.id);
  const projectName = useSelector(({ context }) => context.project?.name);
  const [chaptersRequest, setChaptersRequest] = useState(null);
  const [projectRequest, setProjectRequest] = useState(null);
  const [resultCount, setResultCount] = useState(0);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (delivFiltersLoaded) {
      setChaptersRequest(
        () =>
          ({ page, limit }) =>
            getMostRelevantChaptersContents(
              { page, limit },

              {
                filters: Object.keys(filters).some((item) => item !== "Type" && item !== "separator")
                  ? { ...generateFilters(filters), input: filters.content, content: undefined }
                  : undefined,
              }
            )
      );
    }
  }, [filters, delivFiltersLoaded]);
  useEffect(() => {
    if (projectFiltersLoaded) {
      setProjectRequest(
        () =>
          ({ page, limit }) =>
            getMostRelevantContentInProject(
              { projectId, page, limit },
              Object.keys(projectFilters).some((item) => item !== "Type" && item !== "separator")
                ? generateFilters(projectFilters)
                : undefined
            )
      );
    }
  }, [projectFilters, projectId, projectFiltersLoaded]);

  const handleCloseDisplay = () => setPreview(null);
  const handleDisplay = ({ projectId, documentId, informationId, previewResponse, identifiedContent }) => {
    setPreview({
      coordinates: previewResponse.rectangles,
      projectId: projectId,
      documentId,
      page: previewResponse.pageStart,
      numOfPages: previewResponse.numberOfPages,
      identifiedContent,
      informationId,
    });
  };
  const handleSaveContentFromList = ({ projectId, documentId, informationId, previewResponse }, callBack) => {
    const { numberOfPages, pageStart } = previewResponse;

    if (selectedSearchTab === SEARCH_TABS.DELIVERABLE) {
      saveContent({ informationId, documentId, numberOfPages, pageStart }, { projectId: undefined })
        .then((data) => {
          createNotification({ type: "info", message: translate("common:notification.selection-was-saved") });
          callBack(data);
        })
        .catch(console.error);
    } else {
      saveContent({ informationId, documentId, numberOfPages, pageStart }, { projectId })
        .then((data) => {
          createNotification({ type: "info", message: translate("common:notification.selection-was-saved") });
          callBack(data);
        })
        .catch(console.error);
    }
  };
  const handleSaveContent = ({ informationId, onParamCallBack, recievedRectangles }) => {
    const { documentId, projectId } = preview?.identifiedContent;
    const { numOfPages, page } = preview;
    let numberOfPages = numOfPages;
    let pageStart = page;
    if (isNonEmptyArray(recievedRectangles)) {
      numberOfPages = recievedRectangles.length;
      pageStart = recievedRectangles[0].pageNumber;
    }
    if (selectedSearchTab === SEARCH_TABS.DELIVERABLE) {
      saveContent(
        {
          informationId: isNonEmptyArray(recievedRectangles) ? undefined : informationId,
          contentPositionDTOs: isNonEmptyArray(recievedRectangles) ? recievedRectangles : undefined,
          documentId,
          numberOfPages,
          pageStart,
        },
        { projectId: undefined }
      )
        .then((data) => {
          if (!recievedRectangles) {
            onParamCallBack(data);
            preview?.identifiedContent?.onCallBack(data);
          }
          createNotification({ type: "info", message: translate("common:notification.selection-was-saved") });
        })
        .catch(console.error);
    } else {
      saveContent(
        {
          informationId: isNonEmptyArray(recievedRectangles) ? undefined : informationId,
          contentPositionDTOs: isNonEmptyArray(recievedRectangles) ? recievedRectangles : undefined,
          documentId,
          numberOfPages,
          pageStart,
        },
        { projectId: projectId }
      )
        .then((data) => {
          if (!recievedRectangles) {
            onParamCallBack(data);
            preview?.identifiedContent?.onCallBack(data);
          }
          createNotification({ type: "info", message: translate("common:notification.selection-was-saved") });
        })
        .catch(console.error);
    }
  };
  const handleDeleteContent = ({ savedId, onParamCallBack }) => {
    deleteSavedContent({ savedDataId: savedId })
      .then(() => {
        onParamCallBack(undefined);
        preview?.identifiedContent?.onCallBack(undefined);
      })
      .catch(console.error);
  };
  const handleManageContent = ({ recievedRectangles, informationId, savedId, onParamCallBack }) => {
    if (savedId) {
      handleDeleteContent({ savedId, onParamCallBack });
    } else {
      handleSaveContent({ informationId, recievedRectangles, onParamCallBack });
    }
  };

  const handleResponse = (data) => {
    setResultCount(data.totalElements);
  };

  const rowRenderer = (identifiedContent) => {
    return (
      <IdentifiedContentBox
        key={identifiedContent.informationId}
        identifiedContent={identifiedContent}
        onDisplay={handleDisplay}
        onSaveContent={handleSaveContentFromList}
      />
    );
  };
  const emptyState = () => {
    return <EmptyState />;
  };
  const handleOpenNewWord = () => {
    onDownloadDocument({ docId: preview?.identifiedContent?.documentId })
      .then(({ data, filename }) => WordUtils.openDocumentInNewFile({ data, filename }))
      .catch((err) => console.error(err));
  };
  const innerActions = ({
    disableActions,
    renderCondition,
    onManageContent,
    onInsert,
    onResetRecievedRectangles,
    savedId,
  }) => (
    <div className={styles.innerActionsContainer}>
      <div className={styles.innerActionsNames}>
        <CustomTooltip showWhenOverflow title={projectName}>
          <span className={styles.projectName}>{projectName}</span>
        </CustomTooltip>
        <CustomTooltip showWhenOverflow title={preview?.identifiedContent?.documentName}>
          <span className={styles.documentName} role="presentation" onClick={handleOpenNewWord}>
            {preview?.identifiedContent?.documentName}
          </span>
        </CustomTooltip>
      </div>
      <div className={styles.innerActions__buttonContainer}>
        <CustomIconButton
          disabled={disableActions}
          icon={icon.faArrowLeft}
          size="sm"
          tooltip={
            renderCondition
              ? translate("common:doc-preview.insert-selection")
              : translate("common:doc-preview.insert-chapter")
          }
          variant="outlined"
          onClick={onInsert}
        />
        <CustomIconButton
          disabled={disableActions}
          icon={savedId ? icon.faBookmark : icon.faBookmarkRegular}
          size="sm"
          tooltip={translate(savedId ? "common:btn.unsave" : "common:btn.save")}
          variant="outlined"
          onClick={onManageContent}
        />
        {renderCondition && (
          <CustomIconButton
            icon={icon.faBan}
            size="sm"
            tooltip={translate("common:doc-preview.cancel-selection")}
            variant="outlined"
            onClick={onResetRecievedRectangles}
          />
        )}
      </div>
    </div>
  );

  return (
    <>
      {resultCount > 0 && (
        <div className={styles.resultCount}>
          <div>
            {translate(resultCount === 1 ? "common:empty-state.x-result" : "common:empty-state.x-results", {
              count: resultCount,
            })}
          </div>
          <div>
            <IconComponent
              className={styles.sortIcon}
              color="var(--color-dark-grey-1)"
              icon={icon.faArrowDownWideShort}
            />
            {translate(selectedSearchTab === SEARCH_TABS.PROJECT ? "common:sort.score" : "common:sort.project-date")}
          </div>
        </div>
      )}
      <InfiniteList2
        callOnResponse={handleResponse}
        className={styles.list__container}
        noRowsRenderer={emptyState}
        request={selectedSearchTab === SEARCH_TABS.PROJECT ? projectRequest : chaptersRequest}
        resetData={selectedSearchTab}
        rowRenderer={rowRenderer}
      />
      <DocPreview
        coordinates={preview?.coordinates}
        docId={preview?.documentId}
        infoId={preview?.informationId}
        innerActions={innerActions}
        numOfPages={preview?.numOfPages}
        open={!!preview}
        page={preview?.page}
        projectId={preview?.projectId}
        savedId={preview?.identifiedContent?.savedId}
        slice="multiProjectSearch"
        onClose={handleCloseDisplay}
        onGetPage={getPage}
        onManageContent={handleManageContent}
        onResetContent={resetContent}
      />
    </>
  );
};

export default IdentifiedContents;
