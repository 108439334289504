import { DialogActions } from "@mui/material";
import React from "react";
import styles from "./GenericDialogFooter.module.css";

export default function GenericDialogFooter({ children, secondaryElements = null }) {
  return (
    <DialogActions className={styles.dialogActions}>
      {secondaryElements && <span className={styles.dialogActions__secondary}>{secondaryElements}</span>}
      {children}
    </DialogActions>
  );
}
