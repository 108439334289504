import introJs from "intro.js";
import React, { useEffect } from "react";
import { translate } from "../../../../common/providers";
import { useDispatch } from "react-redux";
import { setTourEnabled } from "../../slice/multi-project-search-slice";
import { I18nTranslate, icon, IconComponent } from "../../../../common/components";
import ReactDOMServer from "react-dom/server";
import styles from "./MultiProjectSearchTour.module.css";
import StepIntro from "./components/step-intro/StepIntro";

const MultiProjectSearchTour = () => {
  const dispatch = useDispatch();
  const tour = introJs();
  const TitleStep1 = () => {
    return <span>{translate("multi-projects-search.tour.step1-title")}</span>;
  };
  const IntroStep1 = () => {
    return (
      <div>
        <div className={styles.margin}>{translate("multi-projects-search.tour.step1-subtitle")}</div>
        <div className={styles.textNumberContainer}>
          <IconComponent className={styles.icon} color="var(--color-light-grey-1)" icon={icon.faMagnifyingGlass} />
          <I18nTranslate translationKey="multi-projects-search.tour.step1-element1" />
        </div>
        <div className={styles.textNumberContainer}>
          <IconComponent className={styles.icon} color="var(--color-light-grey-1)" icon={icon.faBoxArchive} />
          <I18nTranslate translationKey="multi-projects-search.tour.step1-element2" />
        </div>
        <div className={styles.textNumberContainer}>
          <IconComponent className={styles.icon} color="var(--color-light-grey-1)" icon={icon.faObjectGroupRegular} />
          <I18nTranslate translationKey="multi-projects-search.tour.step1-element3" />
        </div>
        <div className={styles.textNumberContainer}>
          <IconComponent className={styles.icon} color="var(--color-light-grey-1)" icon={icon.faPenFancy} />
          <I18nTranslate translationKey="multi-projects-search.tour.step1-element4" />
        </div>
      </div>
    );
  };
  const TitleStep2 = () => {
    return (
      <div className={styles.headerContainer}>
        <IconComponent color="var(--color-blue)" icon={icon.faMagnifyingGlass} size="md" />
        <span className={styles.greyed}>{translate("multi-projects-search.tour.steps-title.step")} 1/3 </span>
        <span className={styles.greyed}>|</span>
        <span>{translate("multi-projects-search.tab-header.search")}</span>
      </div>
    );
  };
  const IntroStep2 = () => {
    return (
      <StepIntro
        descriptions={[
          {
            icon: icon.faMagnifyingGlass,
            text: "multi-projects-search.tour.step2-sub-title1",
          },
          {
            icon: icon.faBookmark,
            text: "multi-projects-search.tour.step2-sub-title2",
          },
        ]}
        howItWorks={[
          { text: "multi-projects-search.tour.step2-element1" },
          {
            text: "multi-projects-search.tour.step2-element2",
          },
          {
            text: "multi-projects-search.tour.step2-element3",
          },
          {
            text: "multi-projects-search.tour.step2-element4",
          },
        ]}
      />
    );
  };
  const TitleStep3 = () => {
    return (
      <div className={styles.headerContainer}>
        <IconComponent color="var(--color-blue)" icon={icon.faBookmark} size="md" />
        <span className={styles.greyed}>{translate("multi-projects-search.tour.steps-title.step")} 2/3 </span>
        <span className={styles.greyed}>|</span>
        <span>{translate("multi-projects-search.tab-header.generation")}</span>
      </div>
    );
  };
  const IntroStep3 = () => {
    return (
      <StepIntro
        descriptions={[
          {
            icon: icon.faAlignLeft,
            text: "multi-projects-search.tour.step3-sub-title",
          },
        ]}
        howItWorks={[
          {
            text: "multi-projects-search.tour.step3-element1",
          },
          {
            text: "multi-projects-search.tour.step3-element2",
          },
          {
            text: "multi-projects-search.tour.step3-element3",
          },
        ]}
      />
    );
  };
  const TitleStep4 = () => {
    return (
      <div className={styles.headerContainer}>
        <IconComponent color="var(--color-blue)" icon={icon.faPenToSquare} size="md" />
        <span className={styles.greyed}>{translate("multi-projects-search.tour.steps-title.step")} 3/3 </span>
        <span className={styles.greyed}>|</span>
        <span>{translate("multi-projects-search.tab-header.result-and-edition")}</span>
      </div>
    );
  };
  const IntroStep4 = () => {
    return (
      <StepIntro
        descriptions={[
          {
            icon: icon.faMagicWandSparkles,
            text: "multi-projects-search.tour.step4-sub-title1",
          },
          {
            icon: icon.faMagicWandSparkles,
            text: "multi-projects-search.tour.step4-sub-title2",
          },
          {
            icon: icon.faMagicWandSparkles,
            text: "multi-projects-search.tour.step4-sub-title3",
          },
        ]}
        howItWorks={[
          {
            text: "multi-projects-search.tour.step4-element1",
          },
          {
            text: "multi-projects-search.tour.step4-element2",
          },
          {
            text: "multi-projects-search.tour.step4-element3",
          },
        ]}
      />
    );
  };
  const TitleStep5 = () => {
    return (
      <div className={styles.headerContainer}>
        <IconComponent color="var(--color-blue)" icon={icon.faPenToSquare} size="md" />
        <span>{translate("multi-projects-search.tour.step5-title")}</span>
      </div>
    );
  };
  const IntroStep5 = () => {
    return (
      <div>
        <div className={styles.margin}>
          <I18nTranslate translationKey="multi-projects-search.tour.step5-element" />
        </div>
        <b>{translate("multi-projects-search.tour.step5-enjoy-using")}</b>
      </div>
    );
  };
  useEffect(() => {
    tour.setOptions({
      nextLabel: translate("common:btn.next"),
      prevLabel: translate("common:btn.back"),
      doneLabel: translate("common:btn.begin"),
      steps: [
        {
          title: ReactDOMServer.renderToStaticMarkup(TitleStep1()),
          intro: ReactDOMServer.renderToStaticMarkup(IntroStep1()),
        },
        {
          title: ReactDOMServer.renderToStaticMarkup(TitleStep2()),
          intro: ReactDOMServer.renderToStaticMarkup(IntroStep2()),
        },
        {
          title: ReactDOMServer.renderToStaticMarkup(TitleStep3()),
          intro: ReactDOMServer.renderToStaticMarkup(IntroStep3()),
        },
        {
          title: ReactDOMServer.renderToStaticMarkup(TitleStep4()),
          intro: ReactDOMServer.renderToStaticMarkup(IntroStep4()),
        },
        {
          title: ReactDOMServer.renderToStaticMarkup(TitleStep5()),
          intro: ReactDOMServer.renderToStaticMarkup(IntroStep5()),
        },
      ],
      tooltipClass: "IntroJsClass",
    });
    tour.onExit(() => {
      dispatch(setTourEnabled(false));
    });
    tour.start();
  }, [tour]);
  return <></>;
};

export default MultiProjectSearchTour;
