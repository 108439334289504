import React, { useEffect, useMemo } from "react";
import { exportView } from "../../navigation";
import { SearchContents, Generate, Edition } from "./components";
import styles from "./MultiProjectSearch.module.css";
import { CircularLoader, HorizontalDivider, ViewTabs, icon } from "../../common/components";
import { Flags, translate } from "../../common/providers";
import { SEARCH_TABS, TABS } from "./constants/tabs";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSearchTab, setSelectedTab } from "./slice/multi-project-search-slice";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { MultiProjectSearchTour } from "./tour";

const MultiProjectSearch = () => {
  const selectedTab = useSelector(({ multiProjectSearch }) => multiProjectSearch.selectedTab);
  const tourEnabled = useSelector(({ multiProjectSearch }) => multiProjectSearch.tourEnabled);
  const loadingAnswer = useSelector(({ multiProjectSearch }) => multiProjectSearch.loadingAnswer);
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (location?.search?.includes("?deliverable")) {
      dispatch(setSelectedTab(TABS.SEARCH));
      dispatch(setSelectedSearchTab(SEARCH_TABS.DELIVERABLE));
    }
    if (location?.search?.includes("?project")) {
      dispatch(setSelectedTab(TABS.SEARCH));
      dispatch(setSelectedSearchTab(SEARCH_TABS.PROJECT));
    }
  }, [location]);

  const handleChangeTab = (_, tab) => {
    dispatch(setSelectedTab(tab));
  };
  const tabHeaders = useMemo(
    () => [
      {
        iconName: icon.faMagnifyingGlass,
        id: TABS.SEARCH,
        label: translate("multi-projects-search.tab-header.search"),
        tab: "PositionsTab",
      },
      {
        iconName: icon.faMagicWandSparkles,
        id: TABS.GENERATE,
        label: translate("multi-projects-search.tab-header.generation"),
        tab: "GenerateTab",
      },
      {
        iconName: icon.faPenToSquare,
        id: TABS.EDITION,
        label: translate("multi-projects-search.tab-header.result-and-edition"),
        tab: "EditionTab",
      },
    ],
    []
  );
  const tour = useMemo(() => <MultiProjectSearchTour />, [tourEnabled]);
  const handleTabViewLoader = () => {
    if (selectedTab === TABS.SEARCH) {
      return <SearchContents />;
    }
    if (selectedTab === TABS.GENERATE) {
      return <Generate />;
    }
    if (selectedTab === TABS.EDITION) {
      return <Edition />;
    }
    return <SearchContents />;
  };

  return (
    <div className={styles.container}>
      <ViewTabs
        className={styles.tabs}
        selectedTab={selectedTab}
        tabIdentifier="id"
        tabs={tabHeaders}
        onChange={handleChangeTab}
      />
      <HorizontalDivider className={styles.divider} />
      {handleTabViewLoader()}
      {tourEnabled && tour}
      {loadingAnswer && (
        <div className={styles.disableOverlay}>
          <CircularLoader />
        </div>
      )}
    </div>
  );
};

export { default as multiProjectSearchSlice } from "./slice/multi-project-search-slice";

export default exportView({
  path: "/multi-project-search",
  localesPath: "/multi-project-search/locales",
  flag: Flags.WRITE_PLUGIN,
  component: MultiProjectSearch,
});
