import { NotificationManager } from "react-notifications";
import { ApiService } from "../../../api";

const createNotification = ({
  callback = null,
  message = "",
  priority = true,
  timeout = 4000,
  title = "",
  type = "info",
}) => {
  if (ApiService.isRequestCancellation(message)) {
    return null;
  }
  switch (type) {
    case "success":
      return NotificationManager.success(message, title, timeout, callback, priority);
    case "warning":
      return NotificationManager.warning(message, title, timeout, callback, priority);
    case "error":
      return NotificationManager.error(message, title, timeout, callback, priority);
    case "info":
    default:
      return NotificationManager.info(message, title, timeout, callback, priority);
  }
};

export default createNotification;
