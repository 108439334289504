import React, { useMemo } from "react";
import { CustomButton, icon } from "../../../../../../../common/components";
import styles from "./QuickAction.module.css";
import { ButtonGroup } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedQuickAction } from "../../../../../slice/multi-project-search-slice";

const QuickAction = ({ title }) => {
  const selectedQuickActions = useSelector(({ multiProjectSearch }) => multiProjectSearch.selectedQuickActions);
  const dispatch = useDispatch();
  const isSelected = useMemo(() => selectedQuickActions?.includes(title), [selectedQuickActions, title]);

  const handleAdd = () => {
    dispatch(setSelectedQuickAction([...selectedQuickActions, title]));
  };
  const handleRemove = () => {
    dispatch(
      setSelectedQuickAction(selectedQuickActions.filter((selectedQuickAction) => selectedQuickAction !== title))
    );
  };
  return (
    <>
      {(isSelected && (
        <ButtonGroup color="primary" variant="outlined">
          <CustomButton
            className={styles.buttonGroup__open}
            color="primary"
            size="md"
            variant="outlined"
            onClick={handleRemove}
          >
            {title}
          </CustomButton>
          <CustomButton
            className={styles.buttonGroup__remove}
            color="primary"
            size="md"
            startIcon={icon.faTimes}
            variant="outlined"
            onClick={handleRemove}
          />
        </ButtonGroup>
      )) || (
        <CustomButton className={styles.button} color="secondary" variant="outlined" onClick={handleAdd}>
          {title}
        </CustomButton>
      )}
    </>
  );
};

export default QuickAction;
