import client from "../client/client";

class FeatureFlagService {
  isFeatureEnabled({ flag }, cancelToken) {
    return client.get(`/feature-flags/${flag}`, { config: { cancelToken } });
  }

  getAll(cancelToken) {
    return client.get("/feature-flags", { config: { cancelToken } });
  }
}

export default new FeatureFlagService();
