/* eslint-disable office-addins/no-context-sync-in-loop */
/* eslint-disable office-addins/no-navigational-load */
import {
  delivIdRegex,
  linkIdRegex,
  linkReqIdRegex,
  mustHave,
  paragraphOutIdRegex,
  textFromFirstDigit,
} from "../constants";
import { translate } from "../providers";
import { isNonEmptyArray } from "./array-utils";
import { arrayBufferToBase64, downloadFile, isHeader } from "./function-utils";

/* global Word, Office */

class WordUtils {
  async insertTextToWord(text) {
    Word.run(async (context) => {
      context.document.getSelection().insertText(text);
    });
  }

  async insertSelectionToPlugin(onCallback) {
    await Office.context.document.getSelectedDataAsync(Office.CoercionType.Text, (result) => {
      if (result.status == Office.AsyncResultStatus.Failed) {
        console.error("Action failed. Error: " + result.error.message);
      } else {
        onCallback(result);
      }
    });
  }

  async openDocumentInNewFile({ data, filename }) {
    if (filename.includes(".docx")) {
      Word.run(function (context) {
        var newDoc = context.application.createDocument(arrayBufferToBase64(data));
        context.load(newDoc);
        return context
          .sync()
          .then(() => {
            newDoc.open();
            context.sync();
          })
          .catch(console.error);
      }).catch(console.error);
    }
    if (filename.includes(".pdf")) {
      Office.onReady((info) => {
        if (info.host === Office.HostType.Word) {
          if (Office.context.platform === Office.PlatformType.PC) {
            downloadFile({ data, filename, filetype: "zip" });
          } else if (Office.context.platform === Office.PlatformType.Mac) {
            downloadFile({ data, filename, filetype: "zip" });
          } else if (Office.context.platform === Office.PlatformType.OfficeOnline) {
            const blob = new Blob([data], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            window.open(url, "_blank");
            URL.revokeObjectURL(url);
          }
        }
      });
    }
  }

  async getTitle({ onCallback }) {
    return Word.run(async (context) => {
      const selection = context.document.getSelection().paragraphs;
      selection.load("items");
      await context.sync();
      let prevParagraph = selection.items[0].getPreviousOrNullObject();
      prevParagraph.load("style,text, isNullObject, listItemOrNullObject/listString");
      await context.sync();
      while (!isHeader(prevParagraph?.style) && !prevParagraph.isNullObject) {
        prevParagraph = prevParagraph.getPreviousOrNullObject();
        prevParagraph.load("style,text, isNullObject, listItemOrNullObject/listString");
        await context.sync();
      }
      if (!prevParagraph.isNullObject) {
        onCallback({
          title: `${prevParagraph.listItemOrNullObject.listString || ""}${prevParagraph.text}`,
        });
      } else {
        onCallback({ title: undefined });
      }
    }).catch(console.error);
  }

  async getInformationForAddParagraph(onCallback) {
    return Word.run(async (context) => {
      var selection = context.document.getSelection();
      const htmlValue = selection.getHtml();
      selection.load("text,contentControls/items/tag");
      await context.sync();
      this.getTitle({
        onCallback: onCallback({
          content: selection.text,
          // eslint-disable-next-line office-addins/load-object-before-read
          html: htmlValue?.value,
          tag: selection.contentControls?.items[0]?.tag,
        }),
      });
      return context.sync();
    }).catch((err) => {
      this.getTitle({
        onCallback: onCallback({
          content: "",
          html: "",
          tag: undefined,
        }),
      });
      console.error(err);
    });
  }

  async insertContentControl({ id, informationId, paragraphOutId, deliverableId, clientRef }) {
    Word.run((context) => {
      var selection = context.document.getSelection();
      selection.load("text");
      return context.sync().then(() => {
        var contentControl = selection.insertContentControl();
        contentControl.title = `Req: ${informationId}`;
        contentControl.tag = `Req: ${informationId} | paragraphOutId: ${paragraphOutId} | linkId:${id} | delivId: ${deliverableId}`;
        contentControl.appearance = "BoundingBox";
        contentControl.color = "#3880E8";
        contentControl.placeholderText = `${translate("common:insert.content-control.placeholder", {
          reqId: informationId,
        })} ${clientRef ? "[" + clientRef + "]" : ""}`;
        return context.sync();
      });
    }).catch(console.error);
  }

  async scrollToContentControl({ id, informationId, paragraphOutId, documentOutId }) {
    Word.run((context) => {
      var contentControlTag = `Req: ${informationId} | paragraphOutId: ${paragraphOutId} | linkId:${id} | delivId: ${documentOutId}`;
      var contentControls = context.document.contentControls;
      contentControls.load("items/title,items/tag");
      return context.sync().then(() => {
        var targetControl = null;
        for (var i = 0; i < contentControls.items.length; i++) {
          if (contentControls.items[i].tag === contentControlTag) {
            targetControl = contentControls.items[i];
            break;
          }
        }
        if (targetControl) {
          targetControl.load("text");
          context.sync();
          targetControl.select();
          return context.sync();
        } else {
          console.error("Content control not found.");
        }
      });
    }).catch(console.error);
  }
  async removeContentControl({ id, informationId, paragraphOutId, documentOutId }) {
    Word.run((context) => {
      var contentControlTag = `Req: ${informationId} | paragraphOutId: ${paragraphOutId} | linkId:${id} | delivId: ${documentOutId}`;
      var contentControls = context.document.contentControls;
      contentControls.load("items/title,items/tag");
      return context.sync().then(() => {
        var targetControl = null;
        for (var i = 0; i < contentControls.items.length; i++) {
          if (contentControls.items[i].tag === contentControlTag) {
            targetControl = contentControls.items[i];
            break;
          }
        }
        if (targetControl) {
          targetControl.delete(true);
          return context.sync();
        } else {
          console.error("Content control was not found.");
        }
      });
    }).catch(console.error);
  }
  async removeDuplicateContentAt(deliverableId, index, newCurrentIndex) {
    Word.run(async (context) => {
      const document = context.document;
      document.load("contentControls/items/tag");
      await context.sync();
      let total = 0;
      if (document.contentControls?.items?.length > 0) {
        document.contentControls.items.forEach((item) => {
          if (
            mustHave.every((must) => item.tag?.includes(must)) &&
            item.tag?.match(linkIdRegex)?.[1] === deliverableId
          ) {
            total = total + 1;
            if (index === total) {
              item.delete(true);
              return context.sync();
            }
          }
        });
      }
      this.highlightDuplicateContentAt(deliverableId, newCurrentIndex);
      return context.sync();
    });
  }
  async removeDuplicatesNotAtContentAt(deliverableId, index) {
    Word.run(async (context) => {
      const document = context.document;
      document.load("contentControls/items/tag");
      await context.sync();
      let total = 0;
      if (document.contentControls?.items?.length > 0) {
        document.contentControls.items.forEach((item) => {
          if (
            mustHave.every((must) => item.tag?.includes(must)) &&
            item.tag?.match(linkIdRegex)?.[1] === deliverableId
          ) {
            total = total + 1;
            if (index !== total) {
              item.delete(true);
              return context.sync();
            }
          }
        });
      }
      this.highlightDuplicateContentAt(deliverableId, index);
      return context.sync();
    });
  }
  async changeSelection(onSetSelectedLinkId, onSetSelectedReqId, deliverableId) {
    try {
      await Word.run(async (context) => {
        const selection = context.document.getSelection();
        selection.load("contentControls");
        await context.sync();
        const contentControls = selection.contentControls;
        contentControls.load("tag");
        await context.sync();
        if (contentControls.items.length > 0) {
          const contentControl = contentControls.items[0];
          if (parseInt(contentControl.tag?.match(delivIdRegex)?.[1]) === deliverableId) {
            onSetSelectedLinkId(parseInt(contentControl.tag?.match(linkIdRegex)?.[1]) || undefined);
            onSetSelectedReqId(parseInt(contentControl.tag?.match(linkReqIdRegex)?.[1]) || undefined);
          }
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }
  async changeTitleForParagraphs({ context, selection, onCallback }) {
    const newTitle = selection.paragraphs.items[0].text
      ? `${selection.paragraphs.items[0].listItemOrNullObject.listString || ""}${selection.paragraphs.items[0].text}`
      : "";
    let paragraphIds = [];
    let nextParagraph = selection.paragraphs.items[0].getNextOrNullObject();
    nextParagraph.load("*, contentControls,isNullObject");
    await context.sync();
    while (!nextParagraph.isNullObject && !isHeader(nextParagraph?.style)) {
      if (isNonEmptyArray(nextParagraph.contentControls?.items)) {
        nextParagraph.contentControls?.items.forEach((item) => {
          paragraphIds = [...paragraphIds, parseInt(item.tag.match(paragraphOutIdRegex)?.[1])];
        });
      }
      nextParagraph = nextParagraph.getNextOrNullObject();
      nextParagraph.load("*, contentControls,isNullObject");
      // eslint-disable-next-line office-addins/no-context-sync-in-loop
      await context.sync();
    }
    if (newTitle) {
      onCallback({ paragraphIds })({ title: newTitle });
    } else {
      this.getTitle({ onCallback: onCallback({ paragraphIds }) });
    }
  }
  async changeParagraph({ context, selection, onCallback }) {
    const htmlValue = selection?.parentContentControlOrNullObject?.getHtml();
    await context.sync();
    this.getTitle({
      onCallback: onCallback({
        content: selection?.parentContentControlOrNullObject?.text,
        contentHTML: htmlValue?.value,
        paragraphOutId: parseInt(selection?.parentContentControlOrNullObject?.tag.match(paragraphOutIdRegex)?.[1]),
      }),
    });
  }
  async detectChanges(onUpdateTitleForContents, onUpdateParagraph) {
    Word.run(async (context) => {
      var selection = context.document.getSelection();
      selection.load(
        "paragraphs/items,paragraphs/items/listItemOrNullObject/listString,paragraphs/items/text,paragraphs/items/style,parentContentControlOrNullObject/tag,parentContentControlOrNullObject/text"
      );
      await context.sync();
      if (isHeader(selection.paragraphs.items[0]?.style)) {
        this.changeTitleForParagraphs({ context, selection, onCallback: onUpdateTitleForContents });
      }
      if (selection?.parentContentControlOrNullObject?.tag) {
        this.changeParagraph({ context, selection, onCallback: onUpdateParagraph });
      }
    }).catch(function (error) {
      console.error(error);
    });
  }

  async findContentControls(deliverableId, onCallBack) {
    Word.run(async (context) => {
      const document = context.document;
      document.load("contentControls/items/title,contentControls/items/tag,contentControls/items/placeholderText");
      await context.sync();
      let total = 0;
      let matchDelivId = [];
      let missMatchDelivId = [];
      let duplicates = [];
      if (document.contentControls?.items?.length > 0) {
        document.contentControls.items.forEach((item) => {
          if (mustHave.every((must) => item.tag?.includes(must))) {
            const currentLinkId = item.tag.match(linkIdRegex)?.[1];
            if (duplicates.some((duplicate) => duplicate.linkId === currentLinkId)) {
              duplicates.forEach((duplicate) => {
                if (duplicate.linkId === currentLinkId) {
                  duplicate.count += 1;
                }
              });
            } else {
              duplicates = [
                ...duplicates,
                {
                  req: item.placeholderText.match(textFromFirstDigit)[0],
                  linkId: currentLinkId,
                  count: 1,
                },
              ];
            }
            if (parseInt(item.tag?.match(delivIdRegex)?.[1]) === deliverableId) {
              matchDelivId = [...matchDelivId, parseInt(item.tag.match(linkIdRegex)?.[1])];
            } else {
              missMatchDelivId = [...missMatchDelivId, parseInt(item.tag.match(linkIdRegex)?.[1])];
            }
            total = total + 1;
          }
        });
      }
      if (typeof onCallBack === "function") {
        onCallBack({
          total,
          matchDelivId,
          missMatchDelivId,
          duplicates: duplicates.filter((duplicate) => duplicate.count > 1),
        });
      }
      return context.sync();
    });
  }

  async removeFromDocNotCorrectDeliv(deliverableId, onCallBack) {
    Word.run(async (context) => {
      const document = context.document;
      document.load("contentControls/items/tag");
      await context.sync();
      const total = document.contentControls?.items?.length || 0;
      let matchDelivId = [];
      if (total > 0) {
        document.contentControls.items.forEach((item) => {
          if (
            mustHave.every((must) => item.tag?.includes(must)) &&
            parseInt(item.tag?.match(delivIdRegex)?.[1]) !== deliverableId
          ) {
            item.delete(true);
          }
        });
      }
      if (typeof onCallBack === "function") {
        onCallBack({ total, matchDelivId });
      }
      return context.sync();
    });
  }

  async highlightGlobalContentAt(index) {
    Word.run(async (context) => {
      const document = context.document;
      document.load("contentControls/items/tag");
      await context.sync();
      let total = 0;
      if (document.contentControls?.items?.length > 0) {
        document.contentControls.items.forEach((item) => {
          if (mustHave.every((must) => item.tag?.includes(must))) {
            total = total + 1;
            if (index === total) {
              item.select();
              return context.sync();
            }
          }
        });
      }
      return context.sync();
    });
  }
  async highlightMissMatchedContentAt(index, deliverableId) {
    Word.run(async (context) => {
      const document = context.document;
      document.load("contentControls/items/tag");
      await context.sync();
      let total = 0;
      if (document.contentControls?.items?.length > 0) {
        document.contentControls.items.forEach((item) => {
          if (parseInt(item.tag?.match(delivIdRegex)?.[1]) !== deliverableId) {
            total = total + 1;
            if (index === total) {
              item.select();
              return context.sync();
            }
          }
        });
      }
      return context.sync();
    });
  }
  async highlightDuplicateContentAt(deliverableId, index) {
    Word.run(async (context) => {
      const document = context.document;
      document.load("contentControls/items/tag");
      await context.sync();
      let total = 0;
      if (document.contentControls?.items?.length > 0) {
        document.contentControls.items.forEach((item) => {
          if (
            mustHave.every((must) => item.tag?.includes(must)) &&
            item.tag?.match(linkIdRegex)?.[1] === deliverableId
          ) {
            total = total + 1;
            if (index === total) {
              item.select();
              return context.sync();
            }
          }
        });
      }
      return context.sync();
    });
  }
  async updateContentControlsAfterMigration(updatedLinks, currentDelivId) {
    Word.run(async (context) => {
      const document = context.document;
      document.load("contentControls/items/tag");
      await context.sync();
      if (document.contentControls?.items?.length > 0) {
        document.contentControls.items.forEach((item) => {
          updatedLinks.forEach((updatedLink) => {
            if (parseInt(item.tag?.match(linkIdRegex)?.[1]) === updatedLink.oldId) {
              const reqId = parseInt(item.tag?.match(linkReqIdRegex)?.[1]);
              item.tag = `Req: ${reqId} | paragraphOutId: ${updatedLink.paragraphId} | linkId:${updatedLink.newId} | delivId: ${currentDelivId}`;
            }
          });
        });
      }
      return context.sync();
    });
  }
}
export default new WordUtils();
