let flags = null;

export const SEGMENTATION_FLAGS = {
  AUTOMATION: "AUTOMATION",
  CATEGORY: "CATEGORY",
  DELIVERABLES: "DELIVERABLES",
  DOCUMENT_CENTER: "DOCUMENT_CENTER",
  NORMS: "NORMS",
  PROJECT_DASHBOARD: "PROJECT_DASHBOARD",
  PROJECT_REQUIREMENTS: "PROJECT_REQUIREMENTS",
  QUESTION_ANSWER: "QUESTION_ANSWER",
  REDRESSEMENT: "REDRESSEMENT",
  REVIEW_BY_CATEGORY: "REVIEW_BY_CATEGORY",
  REVIEW_PROGRESS_DASHBOARD: "REVIEW_PROGRESS_DASHBOARD",
  RIGHTS_ON_DOCUMENTS: "RIGHTS_ON_DOCUMENTS",
  SMART_REVIEW: "SMART_REVIEW",
  SMART_SEARCH: "SMART_SEARCH",
  TASK: "TASK",
  TEAM: "TEAM",
  VERIFICATION_OF_DETECTIONS: "VERIFICATION_OF_DETECTIONS",
  FAVORITE_CATEGORIES: "FAVORITE_CATEGORIES",
  PARTNERS: "PARTNERS",
  EMBEDDING: "EMBEDDING",
  CHAT_PROJECT: "CHAT_PROJECT",
};
export const setSegFlags = (flgs) => {
  flags = flgs;
};
export const getSegFlags = () => flags;

export function isSegFeatureEnabled(flag) {
  if (!flag || !flags) {
    return false;
  }
  if (Array.isArray(flag) && flag.length > 0) {
    return flag.every((f) => !!isSegFeatureEnabled(f));
  }
  return flags.some((f) => f.featureKey === flag);
}
