import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import styles from "./LabeledCheckbox.module.css";

export default function LabeledCheckbox({
  autoTranslate = "yes",
  checkboxClassName = "",
  checked,
  className = "",
  disabled = false,
  label,
  labelClassName = "",
  labelPlacement = "start",
  onChange,
  onClick,
  size = "medium",
  style = null,
  value,
}) {
  return (
    <FormControlLabel
      classes={{
        label: `${(size === "small" && styles["labeledCheckbox__label--small"]) || ""} ${labelClassName}`,
      }}
      className={`${styles.labeledCheckbox} ${className}`}
      control={
        <Checkbox
          checked={checked}
          className={checkboxClassName}
          color="primary"
          disabled={disabled}
          size={size}
          value={value}
          onChange={onChange}
          onClick={onClick}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
      style={style}
      translate={autoTranslate}
    />
  );
}
