import React, { useEffect, useRef, useState } from "react";
import { SidePanel, SidePanelContent } from "../../side-panels";
import { translate } from "../../../providers";
import { icon } from "../../icon-component";
import { CustomButton, CustomIconButton } from "../../buttons";
import styles from "../Preview.module.css";
import { useApi, useCurrentHeight } from "../../../hooks";
import { ApiService, ContentService, DocumentService } from "../../../../api";
import { WordUtils, downloadFile, isNonEmptyArray } from "../../../utils";
import CustomDialog from "../../custom-dialog/CustomDialog";
import { useDispatch, useSelector } from "react-redux";
import PdfViewer from "../../pdf-viewer/PdfViewer";
import { ClickAwayListener, OutlinedInput } from "@mui/material";
import SelectionLayer from "./components/SelectionLayer";
import { handleCalculateRequestCoordinates } from "./utils/utils";
import localStyles from "./DocPreview.module.css";

const ZOOM_STEP = 0.1;
const ZOOM_MIN = 0.5;
const ZOOM_MAX = 2;
const getRoundedHeight = (dimension, start, end) => Math.round((end - start) * dimension) + 12;
const getRoundedWidth = (dimension, start, end) => Math.round((end - start) * dimension) + 12;
const getRoundedAxisCoordinate = (axis, coordinate) => Math.round(axis * coordinate) - 6;

const DocPreview = ({
  blinking = false,
  coordinates,
  docId,
  infoId,
  innerActions,
  numOfPages,
  onActive: onActiveFromParent = null,
  onClose,
  onGetPage,
  onResetContent,
  onManageContent,
  open = false,
  page,
  savedId,
  slice,
}) => {
  const { call: onDownloadDocument } = useApi(DocumentService.downloadDocument);
  const { call: onGetChapterString } = useApi(ContentService.getChapterString);
  const { call: onGetDocumentPhysicalDetails } = useApi(DocumentService.getDocumentPhysicalDetails);
  const { call: onGetContentsInSelection } = useApi(ContentService.getContentsInSelection);
  const { call: onGetSelectedContentFromContentIds } = useApi(ContentService.getSelectedContentFromContentIds);

  const dispatch = useDispatch();

  const [textToInsert, setTextToInsert] = useState("");
  const [numberOfPages, setNumberOfPages] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [pageDimensions, setPageDimensions] = useState();
  const [manual, setManual] = useState(false);
  const [manualZoom, setManualZoom] = useState(1);
  const [recievedRectangles, setRecievedRectangles] = useState(null);
  const [localSavedId, setLocalSavedId] = useState(undefined);
  const content = useSelector((state) => state[slice].content);
  const isLoading = useSelector((state) => state[slice].content?.find((c) => c.page === page)?.isLoading);
  const pdfScroll = useRef(null);
  const refPrimary = useRef();
  const refSecondary = useRef();
  const selectionRectangleRef = useRef(null);
  const cancelTokenSourceRef = useRef();

  useEffect(() => {
    cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
    return () => {
      ApiService.cancelTokens(cancelTokenSourceRef.current);
    };
  }, []);
  useEffect(() => {
    setStart(0);
    setEnd(0);
  }, []);
  useEffect(() => {
    if (typeof onResetContent === "function") {
      dispatch(onResetContent());
    }
    setRecievedRectangles(null);
  }, [dispatch, onResetContent, docId]);
  useEffect(() => {
    if (docId) {
      onGetDocumentPhysicalDetails({ docId })
        .then((data) => {
          setTotalPages(data.numberOfPages);
          let tempHeight = 0;
          setPageDimensions(
            data.pageDimensions.map((pDimension, index) => {
              const tempPDimension = { ...pDimension, page: index + 1, top: tempHeight };
              tempHeight += pDimension.height * zoom;
              return tempPDimension;
            })
          );
        })
        .catch(console.error);
    }
  }, [docId, zoom]);

  useEffect(() => {
    setTimeout(() => {
      if (open && refPrimary?.current) {
        refPrimary.current.scrollIntoView({ behavior: "smooth", block: "center" });
      } else if (open && refSecondary?.current) {
        refSecondary.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 500);
  }, [open, isLoading, refPrimary, refSecondary, zoom]);
  useEffect(() => {
    if (page && isNonEmptyArray(pageDimensions)) {
      pdfScroll.current.scrollTop = pageDimensions[page - 1]?.top;
    }
  }, [page, pageDimensions]);
  const handleGetSelectedRectangles = () => {
    const pagePosition = handleCalculateRequestCoordinates({ start, end, pageDimensions, zoom });
    onGetContentsInSelection({ docId }, { pagePosition })
      .then((data) => setRecievedRectangles(data))
      .catch(console.error);
  };
  useEffect(() => {
    setLocalSavedId(savedId);
  }, [savedId, open]);
  useEffect(() => {
    const handleMouseDown = (e) => {
      if (e.button === 0) {
        const parentRect = document.getElementById("pdfViewerList_id").getBoundingClientRect();
        setStart({
          left: e.clientX - parentRect.left,
          top: e.clientY - parentRect.top,
        });
      }
    };
    const handleMouseMove = (e) => {
      if (start) {
        const parentRect = document.getElementById("pdfViewerList_id").getBoundingClientRect();
        setEnd({
          left: e.clientX - parentRect.left,
          top: e.clientY - parentRect.top,
        });
      }
    };
    const handleMouseUp = (e) => {
      if (e.button === 0) {
        handleGetSelectedRectangles();
        setStart(null);
        setEnd(null);
      }
    };
    const pdfViewerList = document.getElementById("pdfViewerList_id");
    pdfViewerList.addEventListener("mousedown", handleMouseDown);
    pdfViewerList.addEventListener("mousemove", handleMouseMove);
    pdfViewerList.addEventListener("mouseup", handleMouseUp);
    return () => {
      pdfViewerList.removeEventListener("mousedown", handleMouseDown);
      pdfViewerList.removeEventListener("mousemove", handleMouseMove);
      pdfViewerList.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dispatch, start, end]);

  const requestContentString = () => {
    if (infoId && docId) {
      onGetChapterString({ informationId: infoId, docId: docId })
        .then((data) => {
          if (data.numberOfPages < 3) {
            WordUtils.insertTextToWord(data.allContent.replaceAll("\\", "\n"));
          } else {
            setTextToInsert(data.allContent.replaceAll("\\", "\n"));
            setNumberOfPages(data.numberOfPages);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const handleInsertSelection = () => {
    if (!isNonEmptyArray(recievedRectangles)) {
      return;
    }
    let contentIds = [];
    recievedRectangles.forEach((recievedRectangle) => {
      if (isNonEmptyArray(recievedRectangle?.rectangles)) {
        recievedRectangle.rectangles.forEach((rec) => {
          if (!contentIds.some((cId) => rec.contentId === cId)) contentIds = [...contentIds, rec.contentId];
        });
      }
    });
    if (isNonEmptyArray(contentIds)) {
      onGetSelectedContentFromContentIds({ docId }, { contentIds })
        .then((data) => {
          if (recievedRectangles.length < 3) {
            WordUtils.insertTextToWord(data.value.replaceAll("\\", "\n"));
          } else {
            setTextToInsert(data.value.replaceAll("\\", "\n"));
            setNumberOfPages(recievedRectangles.length);
          }
        })
        .catch(console.error);
    }
  };
  const handleInsert = () => {
    if (recievedRectangles) {
      handleInsertSelection();
    } else {
      requestContentString();
    }
  };
  const handleInsertText = () => {
    WordUtils.insertTextToWord(textToInsert);
    setTextToInsert("");
    setNumberOfPages(0);
  };
  const handleCloseInsertDialog = () => {
    setTextToInsert("");
    setNumberOfPages(0);
  };
  const handleDownload = () => {
    onDownloadDocument({ docId })
      .then(({ data, filename }) => downloadFile({ data, filename, filetype: "zip" }))
      .catch((err) => console.error(err));
  };
  const handleZoomOut = () => {
    if (zoom > ZOOM_MIN) {
      setZoom((prev) => prev - ZOOM_STEP);
    }
  };
  const handleZoomIn = () => {
    if (zoom < ZOOM_MAX) {
      setZoom((prev) => prev + ZOOM_STEP);
    }
  };
  const handleChangeZoom = (e) => {
    const { value } = e.target;
    setManualZoom(value);
    if (value / 100 >= ZOOM_MIN && zoom / 100 <= ZOOM_MAX) {
      setZoom(Math.floor(value) / 100);
    }
  };
  const handleOpenManual = () => {
    setManual((prev) => !prev);
  };
  const handleRenderGlobalLayers = () => (
    <SelectionLayer end={end} selectionRectangleRef={selectionRectangleRef} start={start} />
  );

  const getRender = (props, rec, index) => (
    <>
      <div
        key={`${infoId}  ${index}`}
        ref={rec.primary ? refPrimary : refSecondary}
        className={styles.overlay}
        data-blinking={blinking}
        data-color={rec.primary ? "primary" : "secondary"}
        style={{
          left: getRoundedAxisCoordinate(props.width, rec.x1),
          top: getRoundedAxisCoordinate(props.height, rec.y1),
          width: getRoundedWidth(props.width, rec.x1, rec.x2),
          height: getRoundedHeight(props.height, rec.y1, rec.y2),
        }}
      />
    </>
  );
  const getAdjustedCoordinates = () => {
    if (numOfPages === 1) {
      return [{ ...coordinates[0], x1: 0.07, x2: 0.94, y2: coordinates[1].y2 }];
    }
    if (numOfPages === 2) {
      return coordinates.map((coord, index) => ({
        x1: 0.07,
        x2: 0.94,
        y1: index === 0 ? coord.y1 : 0.02,
        y2: index === 0 ? 0.98 : coord.y2,
        pageNumber: page + index,
      }));
    }
    return Array.apply(null, Array(numOfPages)).map((_, index) => ({
      x1: 0.07,
      x2: 0.94,
      y1: index === 0 ? coordinates[0].y1 : 0.02,
      y2: index === numOfPages - 1 ? coordinates[1].y2 : 0.98,
      contentId: page + index,
      pageNumber: page + index,
    }));
  };
  const handleRenderLayers = (props) => {
    if ((isNonEmptyArray(coordinates) || isNonEmptyArray(recievedRectangles)) && zoom > 0) {
      let adjustedCoordinates = [];
      if (recievedRectangles) {
        recievedRectangles.forEach((recievedRectangle) => {
          if (isNonEmptyArray(recievedRectangle?.rectangles)) {
            recievedRectangle.rectangles.forEach((rec) => {
              adjustedCoordinates = [
                ...adjustedCoordinates,
                { x1: rec.x1, x2: rec.x2, y1: rec.y1, y2: rec.y2, pageNumber: recievedRectangle.pageNumber },
              ];
            });
          }
        });
      } else {
        adjustedCoordinates = coordinates.length === 1 ? coordinates : getAdjustedCoordinates();
      }
      if (adjustedCoordinates.some((coord) => coord?.pageNumber)) {
        return adjustedCoordinates.map(
          (rec, index) => rec.pageNumber === props.metadata.truePage && getRender(props, rec, index)
        );
      }
      if (props.metadata.truePage === page) {
        return adjustedCoordinates.map((rec, index) => getRender(props, rec, index));
      }
    }
    return null;
  };
  const handleGetPage = (page) => {
    if (cancelTokenSourceRef?.current) {
      dispatch(onGetPage({ docId, page, token: cancelTokenSourceRef.current.token }));
    }
  };
  const handleResetRecievedRectangles = () => {
    setRecievedRectangles(null);
    setTimeout(() => {
      if (open && refPrimary?.current) {
        refPrimary.current.scrollIntoView({ behavior: "smooth", block: "center" });
      } else if (open && refSecondary?.current) {
        refSecondary.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 500);
  };
  const handleManageContent = () => {
    onManageContent({
      recievedRectangles: recievedRectangles ? recievedRectangles : undefined,
      informationId: infoId,
      savedId: recievedRectangles ? undefined : localSavedId,
      onParamCallBack: (newSavedId) => setLocalSavedId(newSavedId),
    });
  };

  return (
    <SidePanel open={open} size={65} onActive={onActiveFromParent} onClose={onClose}>
      <SidePanelContent
        headerClassName={localStyles.sidePanelContent__header}
        secondaryActions={
          <span className={styles.secondary__actions}>
            <CustomButton color="secondary" startIcon={icon.faDownload} variant="outlined" onClick={handleDownload}>
              {translate("common:doc-preview.download-document")}
            </CustomButton>
          </span>
        }
        title={translate("common:component.preview.title")}
        onClose={onClose}
      >
        <>
          <div className={styles.actionContainer}>
            {innerActions({
              renderCondition: recievedRectangles,
              disableActions: recievedRectangles?.length === 0,
              onInsert: handleInsert,
              onResetRecievedRectangles: handleResetRecievedRectangles,
              onManageContent: handleManageContent,
              savedId: recievedRectangles ? undefined : localSavedId,
            })}
          </div>
          <PdfViewer
            content={content}
            height={useCurrentHeight({ difference: -113 })}
            numberOfPages={totalPages}
            pageDimensions={pageDimensions}
            scrollRef={pdfScroll}
            zoom={zoom * 100}
            onGetPage={handleGetPage}
            onRenderGlobalOverlay={handleRenderGlobalLayers}
            onRenderLayers={handleRenderLayers}
            onSetPage={() => {}}
          />
          {isNonEmptyArray(content) && (
            <div className={localStyles.zoomButtons}>
              {(!manual && (
                <>
                  <CustomIconButton icon={icon.faMinus} onClick={handleZoomOut} />
                  <span role="presentation" onClick={handleOpenManual}>{`${Math.trunc(zoom * 100)}%`}</span>
                  <CustomIconButton icon={icon.faPlus} onClick={handleZoomIn} />
                </>
              )) || (
                <ClickAwayListener onClickAway={handleOpenManual}>
                  <OutlinedInput
                    classes={{
                      root: styles.input,
                    }}
                    type="number"
                    value={manualZoom}
                    onChange={handleChangeZoom}
                  />
                </ClickAwayListener>
              )}
            </div>
          )}
          <CustomDialog
            iconColor="var(--color-red)"
            iconModel={icon.faExclamationTriangle}
            open={textToInsert && numberOfPages}
            submitLabel={translate("common:btn.validate")}
            subTitle={translate("multi-project-search.identified-content-box.sub-title", {
              numberOfPages,
            })}
            title={translate("common:btn.insert-chapter")}
            onClose={handleCloseInsertDialog}
            onSubmit={handleInsertText}
          />
        </>
      </SidePanelContent>
    </SidePanel>
  );
};

export default DocPreview;
