export const convertFormatDateToDisplayDate = (date) => {
  /* for followings formats :
  yyyy-MM-dd'T'HH:mm:ss.SSS
  yyyy-MM-dd'T'HH:mm:ss
  yyyy-MM-dd'T'HH:mm:ss.SSS'Z'
  yyyy-MM-dd'T'HH:mm:ss.SSSXXX
  yyyy-MM-dd'T'HH:mm:ssXXX
  */
  if (!date || typeof date !== "string") {
    return new Date("");
  }
  return new Date(date.slice(0, 10));
};
