import { Button } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { IconComponent } from "../../icon-component";
import styles from "./CustomButton.module.css";
import { getColorOnCondition } from "../utils";
import { getFilteredDataAttributes } from "../../../utils";

const iconSizes = {
  sm: "xs",
  md: "sm",
  lg: "lg",
  xl: "xl",
};

const CustomButton = React.forwardRef(
  (
    {
      autoTranslate = "yes",
      children,
      className = "",
      color = "primary",
      component = "button",
      disableRipple = false,
      disabled = false,
      endIcon = null,
      fullWidth = false,
      onClick,
      onMouseOver = null,
      onMouseLeave = null,
      size = "md",
      startIcon = null,
      style = null,
      type = "button",
      variant = "text",
      ...dataAttributes
    },
    ref
  ) => {
    const iconColorOnCondition = (icColor) => getColorOnCondition({ icColor, variant, color, disabled });
    const btnIcon = ({ icon, classN, icC }) => (
      <IconComponent className={classN || ""} color={iconColorOnCondition(icC)} icon={icon} size={iconSizes[size]} />
    );

    const filteredDataAttributes = getFilteredDataAttributes(dataAttributes);

    return (
      <Button
        ref={ref}
        className={`${styles.btn} ${className}`}
        component={component}
        data-color={color}
        data-size={size}
        data-variant={variant}
        disabled={disabled}
        disableRipple={disableRipple}
        fullWidth={fullWidth}
        style={style}
        translate={autoTranslate}
        type={type}
        onClick={onClick}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        {...filteredDataAttributes}
      >
        {startIcon && btnIcon({ icon: startIcon })}
        {children}
        {endIcon && btnIcon({ icon: endIcon })}
      </Button>
    );
  }
);
CustomButton.propTypes = {
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  color: PropTypes.oneOf(["primary", "secondary"]),
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
};
CustomButton.defaultProps = {
  size: "md",
  color: "primary",
  variant: "text",
};
export default CustomButton;
