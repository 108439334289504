import React from "react";
import { translateDate } from "../../../../../../providers";
import { Checkbox } from "@mui/material";
import styles from "./ProjectRow.module.css";

const ProjectRow = ({ isSelected, onSelect, row }) => {
  return (
    <div className={styles.projectRow}>
      <Checkbox checked={isSelected} onClick={() => onSelect(row.projectId, isSelected)} />
      <div>
        <div className={styles.projectName}>{row.projectName}</div>
        <div className={styles.projectHelperInfo}>
          <div>{translateDate(row.creationDate)}</div>|<div>{row.projectType}</div>
        </div>
      </div>
    </div>
  );
};

export default ProjectRow;
