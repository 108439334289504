import { Accordion, AccordionDetails, AccordionSummary, Checkbox } from "@mui/material";
import React, { useMemo } from "react";
import { icon, IconComponent } from "../../..";
import styles from "./ExpandableCategoryRow.module.css";

let allowExpand = true;

const getChildrensCounts = (category, checkedCategories, counters = { selected: 0, total: 0 }) => {
  let tempCounters = { ...counters };
  if (!Array.isArray(category.subThematic) || category.subThematic.length === 0) {
    return counters;
  }
  category.subThematic.forEach((cat) => {
    tempCounters.total++;
    if (checkedCategories?.includes(cat.id)) {
      tempCounters.selected += 1;
    }
    tempCounters = getChildrensCounts(cat, checkedCategories, tempCounters);
  });
  return tempCounters;
};

const ExpandableCategoryRow = ({
  category,
  indentLevel = 0,
  onCheck,
  onIsChecked,
  onToggleExpand,
  checkedCategories = [],
}) => {
  const isChecked = useMemo(() => onIsChecked(category.id), [onIsChecked, category.id]);
  const hasSubCategories = useMemo(() => category.subThematic?.length > 0 || category.childrenCount > 0, [category]);
  const categoryCounters = useMemo(
    () => getChildrensCounts(category, checkedCategories),
    [category, checkedCategories]
  );
  const handleChange = () => {
    setTimeout(() => {
      if (allowExpand) {
        onToggleExpand(category);
      } else {
        allowExpand = true;
      }
    }, 0);
  };
  const handleCheck = () => {
    allowExpand = false;
    onCheck(category, isChecked);
  };
  return (
    <Accordion
      classes={{
        root: styles.accordion__root,
      }}
      expanded={!!category.expanded || category.filtered !== undefined}
      style={{
        marginLeft: indentLevel > 0 ? 32 : 0,
      }}
      onChange={handleChange}
    >
      <AccordionSummary
        classes={{
          root: styles.accordionSummary__root,
          content: styles.accordionSummary__content,
        }}
        style={{
          cursor: hasSubCategories ? "pointer" : "default",
        }}
      >
        {hasSubCategories && (
          <IconComponent
            className={styles.expandIcon}
            color="var(--shades-grey-600)"
            icon={((category.expanded || category.filtered !== undefined) && icon.faChevronDown) || icon.faChevronRight}
            size="lg"
          />
        )}
        <Checkbox checked={isChecked} className={styles.checkBox} size="small" onChange={handleCheck} />
        {((category.childrenCount > 0 || category.subThematic?.length > 0) && (
          <span
            className={`${styles["categoryName--bold"]} ${
              (category.filtered === false || category.prevFilteredState === false) && styles["categoryName--greyed"]
            }`}
          >
            {category.name} ({categoryCounters.selected}/{category.childrenCount || category.subThematic?.length})
          </span>
        )) || <span>{category.name}</span>}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: styles.accordionDetails__root,
        }}
      >
        {Array.isArray(category.subThematic) &&
          category.subThematic.length > 0 &&
          category.subThematic.map((subCat) => (
            <ExpandableCategoryRow
              key={subCat.id}
              category={subCat}
              checkedCategories={checkedCategories}
              indentLevel={indentLevel + 1}
              onCheck={onCheck}
              onIsChecked={onIsChecked}
              onToggleExpand={onToggleExpand}
            />
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default ExpandableCategoryRow;
