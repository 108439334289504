import client from "../client/client";

class ProjectTeamService {
  joinProject({ projectId }, cancelToken) {
    return client.get(`/projects/${projectId}/join`, {
      params: { projectId },
      config: { cancelToken },
    });
  }
}

export default new ProjectTeamService();
