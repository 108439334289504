import React from "react";
import { I18nTranslate, IconComponent } from "../../../../../../common/components";
import styles from "../../MultiProjectSearchTour.module.css";
import { isNonEmptyArray } from "../../../../../../common/utils";
import { translate } from "../../../../../../common/providers";

const StepIntro = ({ descriptions, howItWorks }) => {
  return (
    <div>
      <div className={styles.margin}>
        <div className={`${styles.greyed} ${styles.uppercased}`}>
          {translate("multi-projects-search.tour.steps-intro.description")}
        </div>
        {isNonEmptyArray(descriptions) &&
          descriptions.map((description) => (
            <div key={description.text} className={styles.textNumberContainer}>
              <IconComponent className={styles.icon} color="var(--color-light-grey-1)" icon={description.icon} />
              <I18nTranslate translationKey={description.text} />
            </div>
          ))}
      </div>
      <div className={`${styles.greyed} ${styles.uppercased}`}>
        {translate("multi-projects-search.tour.steps-intro.how-it-works")}
      </div>
      {isNonEmptyArray(howItWorks) &&
        howItWorks.map((howItWork, index) => (
          <div key={howItWork.text} className={styles.textNumberContainer}>
            <span className={styles.number}>{index + 1}</span>
            <I18nTranslate translationKey={howItWork.text} />
          </div>
        ))}
    </div>
  );
};

export default StepIntro;
