import React, { useEffect, useState } from "react";
import { NavUtils, exportView } from "../../navigation";
import { LinkReqToDelivService, UserService } from "../../api";
import { useApi } from "../../common/hooks";
import { CustomButton, InfiniteList2 } from "../../common/components";
import styles from "./CompanySelection.module.css";
import { useDispatch, useSelector } from "react-redux";
import { KeycloakService } from "../../sevices";
import { useHistory } from "react-router-dom";
import {
  setCompanies,
  setCompany,
  setDeliverable,
  setDomain,
  setProject,
  setProjectReqToDeliv,
  setToken,
} from "../../common/slice";
import CompanyRow from "./common/CompanyRow";
import { isNonEmptyArray, parseJwt } from "../../common/utils";
import { translate } from "../../common/providers";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const CompanySelection = () => {
  const { call: getAllUserCompanies } = useApi(LinkReqToDelivService.getAllUserCompanies, 1000);
  const { call: loginWithCompany } = useApi(UserService.loginWithCompany, 1000);
  const company = useSelector(({ context }) => context.company);
  const companies = useSelector(({ context }) => context.companies);
  const domain = useSelector(({ context }) => context.domain);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [request, setRequest] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (company) {
      setSelectedCompany(company);
    } else if (isNonEmptyArray(companies)) {
      setSelectedCompany(companies[0]);
    }
  }, [company, companies]);
  useEffect(() => {
    if (domain) {
      setRequest(
        () =>
          ({ page, limit }) =>
            getAllUserCompanies({ page, limit }, undefined)
      );
    }
  }, [domain]);
  const handleResponse = (data) => {
    if (!Array.isArray(data.contents) || data.contents.length === 0) {
      KeycloakService.doLogout().then(() => {
        KeycloakService.clearToken();
      });
    } else {
      dispatch(setCompanies(data.data));
    }
  };
  const handleError = (err) => {
    if (err !== "Pending requests were cancelled") {
      dispatch(setDomain(""));
      KeycloakService.doLogout().then(() => {
        KeycloakService.clearToken();
      });
    }
  };
  const handleChange = (companySelection) => {
    setSelectedCompany(companySelection);
    dispatch(setProject({}));
    dispatch(setProjectReqToDeliv({}));
    dispatch(setDeliverable({}));
  };
  useEffect(() => {
    if (isNonEmptyArray(companies)) {
      if (
        location.search !== "?manualModification=true" &&
        company?.id &&
        companies.find((comp) => comp.id === company.id) &&
        companies.length !== 1
      ) {
        loginWithCompany({ companyId: company.id })
          .then(() => {
            dispatch(setToken(KeycloakService.getToken()));
            history.push(NavUtils.gotoMainMenu());
          })
          .catch((err) => console.error(err));
      } else if (companies.length === 1) {
        loginWithCompany({ companyId: companies[0].id })
          .then(() => {
            dispatch(setCompany(companies[0]));
            dispatch(setToken(KeycloakService.getToken()));
            history.push(NavUtils.gotoMainMenu());
          })
          .catch((err) => console.error(err));
      }
    }
  }, [companies, company]);
  const handleLoginWithCompany = () => {
    if (!selectedCompany) {
      return;
    }
    loginWithCompany({ companyId: selectedCompany.id })
      .then(() => {
        dispatch(setCompany(selectedCompany));
        dispatch(setToken(KeycloakService.getToken()));
        history.push(NavUtils.gotoMainMenu());
      })
      .catch((err) => console.error(err));
  };
  const handleGoBack = () => {
    if (parseJwt(KeycloakService.getToken()).groups?.length === 1) {
      KeycloakService.doLogout();
    } else {
      history.push(NavUtils.gotoHome());
    }
  };
  const rowRenderer = (row) => {
    return <CompanyRow key={row.id} company={row} selectedCompany={selectedCompany} onClick={handleChange} />;
  };
  return (
    <div className={styles.container}>
      <div className={styles.companySelectionContainer}>
        <div className={styles.helperText}>{translate("company-selection.select-bu")}</div>
        <div className={styles.companyList}>
          <InfiniteList2
            callOnError={handleError}
            callOnResponse={handleResponse}
            className={styles.infiniteListContainer}
            request={request}
            rowRenderer={rowRenderer}
          />
        </div>
      </div>
      <div className={styles.actionsContainer}>
        <CustomButton variant="outlined" onClick={handleGoBack}>
          {translate("common:btn.back")}
        </CustomButton>
        <CustomButton disabled={!selectedCompany} variant="contained" onClick={handleLoginWithCompany}>
          {translate("common:btn.next")}
        </CustomButton>
      </div>
    </div>
  );
};

export default exportView({
  path: "/company-selection",
  component: CompanySelection,
  localesPath: "/company-selection/locales",
});
