import client from "../client/client";

class PublicService {
  refreshToken(cancelToken) {
    return client.post("/public/refresh", {
      config: {
        cancelToken,
      },
    });
  }
}
export default new PublicService();
