import { useRef, useEffect, useState, useCallback } from "react";
import { ApiService } from "../../api";

/**
 * /!\ useApi will trigger a re-render when isLoading changes which means: every time.
 * Even if you don't use isLoading in your component make sure to use useCallback
 * in the case you are passing a method ref to a useEffect.
 *
 * See example in DocumentFilter::handleSearch
 */
export default function useApi(serviceMethodRef) {
  const cancelTokenRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    cancelTokenRef.current = ApiService.getCancelTokenSource();
    return () => {
      ApiService.cancelTokens(cancelTokenRef.current);
    };
  }, []);

  const call = useCallback(
    (...params) => {
      if (params.length > serviceMethodRef.length - 1) {
        throw Error("ERROR: call#useApi - Number of params exceeds expected params");
      }
      setIsLoading(true);
      return serviceMethodRef(...params, cancelTokenRef.current.token).finally(() => setIsLoading(false));
    },
    [serviceMethodRef]
  );

  const cancel = useCallback(() => {
    setIsLoading(false);
    ApiService.cancelTokens(cancelTokenRef.current);
    cancelTokenRef.current = ApiService.getCancelTokenSource();
  }, []);

  return { call, cancel, isLoading };
}
