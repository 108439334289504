import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MyService, FeatureFlagService, ProjectInfoService } from "../../api";
import { languages } from "../constants";
import { ContextProvider, setUserRoles, setFlags, setSegFlags } from "../providers";
import { KeycloakService } from "../../sevices";

function getUserLanguage(lang) {
  if (lang && languages.some((l) => l.value === lang)) {
    document.documentElement.lang = lang;
    return languages.find((l) => l.value === lang);
  }
  document.documentElement.lang = "en";
  return { value: "en", name: "English" };
}
const initialState = {
  isLogged: false,
  shouldReconnectWebsocket: false,
  flags: null,
  segFlags: null,
  loadingSegFlags: false,
  roles: null,
  companyRoles: null, // Company roles in project
  company: null,
  companies: null,
  project: ContextProvider.getProject(),
  projectReqToDeliv: ContextProvider.getProjectReqToDeliv(),
  projectReqToDelivToUpdate: false,
  deliverable: ContextProvider.getDeliverable(),
  deliverableToUpdate: false,
  projects: null,
  openProjectSelectionSidePanel: false,
  domain: ContextProvider.getDomain(),
  user: null,
  resetRoutes: true,
  language: getUserLanguage(ContextProvider.getLang()),
  loadingFlags: false,
  loadingUser: false,
  crossFilters: null,
  firstTimeLogin: true,
};
export const getFlags = createAsyncThunk("context/getFlags", (token) => FeatureFlagService.getAll(token), {
  condition: () => KeycloakService.isLoggedIn(),
});
export const getUser = createAsyncThunk("context/getUser", (token) => MyService.getDetails(token), {
  condition: () => KeycloakService.isLoggedIn(),
});
export const getSegFlags = createAsyncThunk(
  "context/getSegFlags",
  ({ projectId, token }) => ProjectInfoService.getActiveFeatures({ projectId }, token),
  { condition: () => KeycloakService.isLoggedIn() }
);
export const getPreferedLanguage = createAsyncThunk(
  "context/getPreferedLanguage",
  (token) => MyService.getPreferedLanguage(token),
  { condition: () => KeycloakService.isLoggedIn() }
);
const contextSlice = createSlice({
  name: "context",
  initialState,
  reducers: {
    setLanguage: (state, { payload }) => {
      const { value, cancelToken } = payload;
      ContextProvider.setLang(value);
      MyService.changeLanguage({ value }, cancelToken.token).catch((err) => console.error(err));
      return { ...state, language: getUserLanguage(value) };
    },
    setDeliverable: (state, { payload }) => {
      ContextProvider.setDeliverable(payload);
      return { ...state, deliverable: payload };
    },
    setDeliverableToUpdate: (state, { payload }) => {
      return { ...state, deliverableToUpdate: payload };
    },
    setProject: (state, { payload }) => {
      ContextProvider.setProject(payload);
      return { ...state, project: payload };
    },
    setProjectReqToDeliv: (state, { payload }) => {
      ContextProvider.setProjectReqToDeliv(payload);
      return { ...state, projectReqToDeliv: payload };
    },
    setProjectReqToDelivToUpdate: (state, { payload }) => {
      return { ...state, projectReqToDelivToUpdate: payload };
    },
    setDomain: (state, { payload }) => {
      ContextProvider.setDomain(payload);
      return { ...state, domain: payload, companies: [] };
    },
    setToken: (state, { payload }) => {
      const logged = ContextProvider.login(payload);
      return { ...state, isLogged: logged, shouldReconnectWebsocket: logged };
    },
    setCompany: (state, { payload }) => ({ ...state, company: payload, setProjects: null }),
    setCompanies: (state, { payload }) => ({ ...state, companies: payload }),
    setProjects: (state, { payload }) => ({ ...state, projects: payload }),
    setResetRoutes: (state, { payload }) => ({ ...state, resetRoutes: payload }),
    setShouldReconnectWebsocket: (state, { payload }) => ({ ...state, shouldReconnectWebsocket: payload }),
    setCrossFilters: (state, { payload }) => ({ ...state, crossFilters: payload }),
    setFirstTimeLogin: (state, { payload }) => ({ ...state, firstTimeLogin: payload }),
    setOpenProjectSelectionSidePanel: (state, { payload }) => ({ ...state, openProjectSelectionSidePanel: payload }),
  },
  extraReducers: (builder) => {
    builder.addCase(getFlags.pending, (state) => ({
      ...state,
      loadingFlags: true,
    }));
    builder.addCase(getFlags.rejected, (state) => ({
      ...state,
      loadingFlags: false,
    }));
    builder.addCase(getFlags.fulfilled, (state, { payload }) => {
      if (payload) {
        setFlags(payload);
        return { ...state, flags: payload, loadingFlags: false };
      }
      return state;
    });
    builder.addCase(getSegFlags.pending, (state) => ({
      ...state,
      loadingSegFlags: true,
    }));
    builder.addCase(getSegFlags.rejected, (state) => ({
      ...state,
      loadingSegFlags: false,
    }));
    builder.addCase(getSegFlags.fulfilled, (state, { payload }) => {
      const newState = { ...state };
      if (payload) {
        setSegFlags(payload);
        newState.segFlags = payload;
        newState.loadingSegFlags = false;
      }
      return newState;
    });
    builder.addCase(getUser.pending, (state) => ({
      ...state,
      loadingUser: true,
    }));
    builder.addCase(getUser.rejected, (state) => ({
      ...state,
      loadingUser: false,
    }));
    builder.addCase(getPreferedLanguage.fulfilled, (state, { payload }) => {
      const { value } = payload;
      const preferedLang = state.firstTimeLogin ? KeycloakService.userLanguage() : value;
      ContextProvider.setLang(preferedLang);
      return { ...state, language: getUserLanguage(preferedLang) };
    });
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      if (payload) {
        const { companyId, companyName, displayName, email, firstName, lastName, role, roles, userId: id } = payload;
        const initials = `${firstName[0]}${lastName[0]}`;
        const user = {
          displayName,
          email,
          firstName,
          id,
          initials,
          lastName,
          role,
        };
        let company = null;
        if (companyId) {
          company = {
            id: companyId,
            name: companyName,
            roles,
          };
        }
        const storedRoles = { ...state.roles, PLATFORM: role, COMPANY: roles };
        setUserRoles(storedRoles);
        return {
          ...state,
          user,
          company,
          roles: storedRoles,
          loadingUser: false,
        };
      }
      return state;
    });
    builder.addMatcher(
      ({ type }) => typeof type === "string" && type.endsWith("rejected"),
      (_, { error }) => {
        console.error(error.message);
      }
    );
  },
});
export const {
  setLanguage,
  setFirstTimeLogin,
  setOpenProjectSelectionSidePanel,
  setToken,
  setDomain,
  setCompany,
  setDeliverable,
  setDeliverableToUpdate,
  setCompanies,
  setProjects,
  setShouldReconnectWebsocket,
  setProject,
  setProjectReqToDeliv,
  setProjectReqToDelivToUpdate,
  setResetRoutes,
  setCrossFilters,
} = contextSlice.actions;
export default contextSlice.reducer;
