import React from "react";
import { AppBar, TabScrollButton, Tabs } from "@mui/material";
import { withStyles } from "@mui/styles";
import { ViewTab } from "..";
import styles from "./ViewTabs.module.css";

export default function ViewTabs({
  ariaLabel = "view-tabs",
  className,
  level = "default",
  onChange,
  selectedTab,
  tabClassName = "",
  tabIdentifier = "",
  tabs,
  variant = "standard",
  noPadding = false,
  noShadow = false,
}) {
  const ScrollButtonHidden = withStyles(() => ({
    root: {
      "&.Mui-disabled": {
        width: 0,
      },
    },
  }))(TabScrollButton);
  return (
    <AppBar
      className={`${styles.container} ${styles.appBar} ${className || ""}`}
      data-level={level}
      data-shadow={!noShadow}
      position="static"
    >
      <Tabs
        aria-label={ariaLabel}
        classes={{ root: styles.viewTabs, indicator: styles.viewTabs__indicator }}
        className={styles.tabs}
        data-level={level}
        data-nopadding={noPadding}
        indicatorColor="primary"
        ScrollButtonComponent={ScrollButtonHidden}
        TabIndicatorProps={{ children: <span /> }}
        textColor="primary"
        value={selectedTab}
        variant={variant}
        onChange={onChange}
      >
        {Array.isArray(tabs) &&
          tabs.map((tab, index) => {
            const key = index;
            return (
              <ViewTab
                key={tabIdentifier ? tab[tabIdentifier] : key}
                className={`${styles.tabs__tab} ${tabClassName}`}
                data-level={level}
                disabled={tab.disabled}
                iconName={tab.iconName}
                label={tab.label}
                selected={index === selectedTab}
                value={tabIdentifier ? tab[tabIdentifier] : key}
              />
            );
          })}
      </Tabs>
    </AppBar>
  );
}
