import { TableCell } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { icon, IconComponent } from "../../icon-component";
import styles from "./CustomTableCell.module.css";

export default function CustomTableCell({
  active = false,
  align = "inherit",
  autoTranslate = "yes",
  children,
  className = "",
  colSpan = 1,
  direction = "asc",
  onSort = null,
  sortKey = "",
  verticalAlign = "center",
}) {
  if (sortKey) {
    return (
      <TableCell
        align={align}
        className={className}
        colSpan={colSpan}
        style={{ verticalAlign: verticalAlign }}
        translate={autoTranslate}
      >
        <div className={styles.sortLabel} role="presentation" onClick={onSort}>
          {children}
          {!active && <IconComponent className={`${styles.icon} ${styles["icon--inactive"]}`} icon={icon.faSort} />}
          {active &&
            ((direction === "asc" && <IconComponent className={styles.icon} icon={icon.faSortUp} />) ||
              (direction === "desc" && <IconComponent className={styles.icon} icon={icon.faSortDown} />))}
        </div>
      </TableCell>
    );
  }
  return (
    <TableCell
      align={align}
      className={className}
      colSpan={colSpan}
      style={{ verticalAlign: verticalAlign }}
      translate={autoTranslate}
    >
      {children}
    </TableCell>
  );
}
CustomTableCell.propTypes = {
  direction: PropTypes.oneOf(["asc", "desc"]),
};
CustomTableCell.defaultProps = {
  direction: "asc",
};
