import client from "../client/client";

class DocumentService {
  downloadDocument({ docId }, cancelToken) {
    return client.download(`/documents/${docId}/download`, {
      config: { cancelToken },
    });
  }
  getDocumentPreviewDimensions({ docId, page }, { adjPages }, cancelToken) {
    return client.get(`/documents/${docId}/pages/${page}/preview`, {
      params: { adjPages },
      config: { cancelToken },
    });
  }
  getPage({ docId }, { page = 1 }, cancelToken) {
    return client.get(`/documents/${docId}/image`, {
      config: { responseType: "blob", cancelToken },
      params: { page, timestamp: Date.now() },
    });
  }
  getDocumentPhysicalDetails({ docId }, cancelToken) {
    return client.get(`/documents/${docId}/details`, { config: { cancelToken } });
  }
  getProjectDeliverablesExist(cancelToken) {
    return client.get("/projects/deliverables/exist", { config: { cancelToken } });
  }
}

export default new DocumentService();
