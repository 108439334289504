import { Radio } from "@mui/material";
import React from "react";
import styles from "./CompanyRow.module.css";
const CompanyRow = ({ company, onClick, selectedCompany }) => {
  const handleClick = () => {
    onClick(company);
  };
  return (
    <div className={styles.row} role="presentation" onClick={handleClick}>
      <Radio checked={selectedCompany.id === company.id} className={styles.radio} />
      <span className={styles.CompanyName}>{company.companyName}</span>
    </div>
  );
};

export default CompanyRow;
