import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { IconComponent, icon, CustomIconButton } from "../..";

const SearchInput = React.forwardRef(
  (
    {
      autoFocus = false,
      className = "",
      disabled = false,
      error = false,
      fullWidth = false,
      helperText = "",
      isLoading = false,
      label,
      onBlur,
      onChange,
      onClearSearch = null,
      onFocus,
      onKeyPress,
      onKeyDown,
      placeholder = "",
      required = false,
      size = "small",
      value,
    },
    ref
  ) => (
    <TextField
      autoFocus={autoFocus}
      className={className}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      helperText={helperText}
      InputLabelProps={{
        style: {
          fontSize: "var(--font-size-sm)",
          lineHeight: "1rem",
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {isLoading && <CircularProgress size={25} thickness={3} />}
            {!isLoading && (!onClearSearch || !value || value.length === 0) && (
              <IconComponent color="var(--color-light-grey-2)" icon={icon.faSearch} />
            )}
            {!isLoading && onClearSearch && value && value.length > 0 && (
              <CustomIconButton icon={icon.faTimes} onClick={onClearSearch} />
            )}
          </InputAdornment>
        ),
        style: {
          fontSize: "var(--font-size-sm)",
          height: 32,
        },
      }}
      inputRef={ref}
      label={label}
      placeholder={placeholder}
      required={required}
      size={size}
      value={value}
      variant="outlined"
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onKeyPress={onKeyPress}
    />
  )
);
export default SearchInput;
