import { Flags } from "../../../../common/providers";
import { SEARCH_TABS, TABS } from "../../../../views/multi-project-search/constants/tabs";

export default [
  { name: "common:user-bar.secondary-menu.project", flag: [Flags.WRITE_PLUGIN] },
  {
    name: "common:user-bar.secondary-menu.multi-project-search",
    to: "/multi-project-search?project",
    reduxState: "multiProjectSearch",
    tabKeys: ["selectedTab", "selectedSearchTab"],
    tabs: [TABS.SEARCH, SEARCH_TABS.PROJECT],
    flag: [Flags.WRITE_PLUGIN],
  },
  { name: "common:user-bar.secondary-menu.help-writing", flag: [Flags.WRITE_PLUGIN] },
  {
    name: "common:user-bar.secondary-menu.multi-project-deliverables",
    to: "/multi-project-search?deliverable",
    reduxState: "multiProjectSearch",
    tabKeys: ["selectedTab", "selectedSearchTab"],
    tabs: [TABS.SEARCH, SEARCH_TABS.DELIVERABLE],
    flag: [Flags.WRITE_PLUGIN],
  },
  { name: "common:user-bar.secondary-menu.req-coverage", flag: [Flags.COVERAGE_PLUGIN] },
  {
    name: "common:user-bar.secondary-menu.link-requirement-to-deliverable",
    to: "/link-requirement-to-deliverable",
    flag: [Flags.COVERAGE_PLUGIN],
  },
];
