import React from "react";
import { CustomButton } from "../..";
import styles from "./CustomButtonLink.module.css";
import CustomLink from "../custom-link/CustomLink";
import { getFilteredDataAttributes } from "../../../utils";

const CustomButtonLink = React.forwardRef(
  (
    {
      autoTranslate = "yes",
      buttonClassName = "",
      children,
      className = "",
      color = "primary",
      "data-testid": dataTestId = null,
      disableRipple = false,
      disabled = false,
      endIcon = null,
      external = false,
      fullWidth = false,
      onClick = null,
      onMouseLeave = null,
      onMouseOver = null,
      openInNewTab = false,
      size = "md",
      startIcon = null,
      state = null,
      to,
      underlined = false,
      variant = "text",
      ...dataAttributes
    },
    ref
  ) => {
    const filteredDataAttributes = getFilteredDataAttributes(dataAttributes);
    return (
      <CustomLink
        className={`${styles.customButtonLink} ${fullWidth && styles["customButtonLink--fullWidth"]} ${className} `}
        disabled={disabled}
        external={external}
        openInNewTab={openInNewTab}
        state={state}
        to={to}
      >
        <CustomButton
          ref={ref}
          autoTranslate={autoTranslate}
          className={buttonClassName}
          color={color}
          data-testid={dataTestId}
          disabled={disabled}
          disableRipple={disableRipple}
          endIcon={endIcon}
          fullWidth={fullWidth}
          size={size}
          startIcon={startIcon}
          underlined={underlined}
          variant={variant}
          onClick={onClick}
          onMouseLeave={onMouseLeave}
          onMouseOver={onMouseOver}
          {...filteredDataAttributes}
        >
          {children}
        </CustomButton>
      </CustomLink>
    );
  }
);
export default CustomButtonLink;
