import { Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { translate } from "../../../../../../../common/providers";
import { CustomButton } from "../../../../../../../common/components";
import styles from "./GenerationActions.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setEditContentIndex, setLoadingAnswer, setSelectedTab } from "../../../../../slice/multi-project-search-slice";
import { useApi } from "../../../../../../../common/hooks";
import { ContentService } from "../../../../../../../api";
import { TABS } from "../../../../../constants/tabs";

const GenerationActions = ({
  savedDeliverableContentCount,
  savedProjectContentCount,
  integrationCheck,
  onIntegrationCheck,
}) => {
  const { call: getGenerate } = useApi(ContentService.getGenerate);
  const { call: getAskedQuestionCount } = useApi(ContentService.getAskedQuestionCount);
  const projectId = useSelector(({ context }) => context.project?.id);
  const [chatsCount, setChatsCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    getAskedQuestionCount()
      .then((data) => setChatsCount(data))
      .catch(console.error);
  }, [getAskedQuestionCount]);
  const handleGeneration = () => {
    if (savedDeliverableContentCount <= 0) {
      return;
    }
    dispatch(setLoadingAnswer(true));
    getGenerate(
      projectId
        ? {
            projectId,
            isIntegration: integrationCheck,
          }
        : { projectId: undefined, isIntegration: undefined }
    )
      .then(() => {
        dispatch(setEditContentIndex(chatsCount + 1));
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setLoadingAnswer(false));
        dispatch(setSelectedTab(TABS.EDITION));
      });
  };
  return (
    <>
      <div className={styles.generationActions}>
        <div className={styles.checkboxContainer}>
          <Checkbox
            checked={integrationCheck}
            className={styles.checkbox}
            disabled={savedProjectContentCount <= 0 || savedDeliverableContentCount <= 0}
            size="small"
            onClick={onIntegrationCheck}
          />
          <div
            className={styles.checkboxText}
            data-disabled={savedProjectContentCount <= 0 || savedDeliverableContentCount <= 0}
          >
            {translate("multi-projects-search.generate.integrate-to-project-context")}
          </div>
        </div>
        <CustomButton disabled={savedDeliverableContentCount <= 0} variant="contained" onClick={handleGeneration}>
          {translate("multi-projects-search.generate.btn.generate-chapter")}
        </CustomButton>
      </div>
      <div className={styles.helperInfo}>{translate("multi-projects-search.ai-helper-text")}</div>
    </>
  );
};

export default GenerationActions;
