import client from "../client/client";

class ParagraphOutService {
  getParagraphsForDeliverables({ projectId, deliverableId, informationId }, cancelToken) {
    return client.get(
      `/spp/projects/${projectId}/document-out/${deliverableId}/information/${informationId}/paragraph-out/links`,
      {
        config: { cancelToken },
      }
    );
  }
  createParagraphForDeliverables(
    { projectId, deliverableId, informationId },
    { content, contentHTML, order_in_doc, page, title, paragraphId },
    cancelToken
  ) {
    return client.post(
      `/spp/projects/${projectId}/document-out/${deliverableId}/information/${informationId}/paragraph-out/links`,
      {
        payload: { content, contentHTML, order_in_doc, title, page, paragraphId },
        config: { cancelToken },
      }
    );
  }
  updateTitleForParagraphs({ projectId, deliverableId }, { title, ids }, cancelToken) {
    return client.put(`/spp/projects/${projectId}/document-out/${deliverableId}/paragraph-out`, {
      payload: { title, ids },
      config: { cancelToken },
    });
  }
  updateParagraph(
    { projectId, deliverableId, paragraphOutId },
    { page, title, content, contentHTML, order_in_doc },
    cancelToken
  ) {
    return client.put(`/spp/projects/${projectId}/document-out/${deliverableId}/paragraph-out/${paragraphOutId}`, {
      payload: {
        page,
        title,
        content,
        contentHTML,
        order_in_doc,
        paragraphOutId,
      },
      config: { cancelToken },
    });
  }
  deleteParagraphsLinkForDeliverables(
    { projectId, deliverableId, informationId, paragraphOutId, linkId },
    cancelToken
  ) {
    return client.delete(
      `/spp/projects/${projectId}/document-out/${deliverableId}/information/${informationId}/paragraph-out/${paragraphOutId}/links/${linkId}`,
      {
        config: { cancelToken },
      }
    );
  }
  deleteLinksNotPresentInDoc({ projectId, deliverableId }, { values }, cancelToken) {
    return client.patch(`/spp/projects/${projectId}/document-out/${deliverableId}/links`, {
      payload: { values },
      config: { cancelToken },
    });
  }
  getCountLinksNotPresentInDoc({ projectId, deliverableId }, { values }, cancelToken) {
    return client.post(`/spp/projects/${projectId}/document-out/${deliverableId}/links`, {
      payload: { values },
      config: { cancelToken },
    });
  }
  transferLinksAndParagraphsToNewDeliv({ projectId, deliverableId }, { linksIds }, cancelToken) {
    return client.post(`/spp/projects/${projectId}/document-out/${deliverableId}/links/transfer`, {
      payload: { linksIds },
      config: { cancelToken },
    });
  }
}
export default new ParagraphOutService();
