import React from "react";
import styles from "./EmptyState.module.css";
import { CustomButtonLink, icon } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import { useSelector } from "react-redux";
import { SEARCH_TABS } from "../../constants/tabs";

const EmptyState = ({ deliverablesDoNotExist = false }) => {
  const companyId = useSelector(({ context }) => context.company?.id);
  const domain = useSelector(({ context }) => context.domain);
  const selectedSearchTab = useSelector(({ multiProjectSearch }) => multiProjectSearch.selectedSearchTab);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {translate(
          deliverablesDoNotExist && selectedSearchTab === SEARCH_TABS.DELIVERABLE
            ? "multi-projects-search.identified-contents.saved.empty-state.deliverable.header"
            : "common:empty-state.no-results"
        )}
      </div>
      {selectedSearchTab === SEARCH_TABS.DELIVERABLE && (
        <>
          <div className={styles.body}>
            {translate(
              deliverablesDoNotExist
                ? "multi-projects-search.identified-contents.saved.empty-state.deliverable.body"
                : "multi-projects-search.identified-contents.saved.empty-state.body"
            )}
            <div className={styles.linkContainer}>
              {deliverablesDoNotExist && (
                <CustomButtonLink external openInNewTab size="sm" to="https://academy.aitenders.com/">
                  {translate("common:btn.access-tutorial")}
                </CustomButtonLink>
              )}
            </div>
          </div>

          {deliverablesDoNotExist && (
            <div>
              <CustomButtonLink
                external
                openInNewTab
                startIcon={icon.faArrowUpRightFromSquare}
                to={`https://${domain}?companyId=${companyId}`}
                variant="contained"
              >
                {translate("multi-projects-search.identified-contents.saved.empty-state.deliverable.access-document")}
              </CustomButtonLink>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EmptyState;
