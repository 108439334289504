import React from "react";
import { CircularProgress } from "@mui/material";
import styles from "./Loader.module.css";

export default function Loader({ className = "" }) {
  return (
    <div className={`${styles.loader} ${className}`}>
      <CircularProgress color="primary" />
    </div>
  );
}
