import { ButtonGroup } from "@mui/material";
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import styles from "./CustomButtonGroup.module.css";

const CustomButtonGroup = forwardRef(
  ({ children, color = "primary", fullWidth = false, size = "md", variant = "text" }, ref) => (
    <ButtonGroup
      ref={ref}
      className={styles.btnGroup}
      color={color}
      data-color={color}
      data-size={size}
      data-variant={variant}
      fullWidth={fullWidth}
      variant="text"
    >
      {children}
    </ButtonGroup>
  )
);
CustomButtonGroup.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  color: PropTypes.oneOf(["primary", "secondary"]),
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
};
CustomButtonGroup.defaultProps = {
  size: "md",
  color: "primary",
  variant: "text",
};
export default CustomButtonGroup;
