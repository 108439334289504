import React, { forwardRef, useEffect, useRef, useState } from "react";
import { CustomIconButton, CustomButton, DropDown } from "../..";

const CustomMenuButton = forwardRef(
  (
    {
      btnClassName = "",
      children,
      className = "",
      color = "secondary",
      disabled,
      disablePortal = false,
      endIcon = null,
      forceClose = false,
      fullWidth = false,
      icon = null,
      iconClassName = "",
      iconColor = "inherit",
      label = "",
      position = "bottom-start",
      size = "md",
      startIcon = null,
      text = "",
      variant = "outlined",
    },
    ref
  ) => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
      if (open && forceClose) {
        setOpen(false);
      }
    }, [open, forceClose]);

    const handleOpen = () => {
      setOpen(!open);
    };
    const handleClose = () => {
      setOpen(false);
    };

    useEffect(() => {
      if (disabled) {
        handleClose();
      }
    }, [disabled]);

    useEffect(() => {
      if (!ref) {
        return null;
      }
      ref.current = { onClose: handleClose };
      return () => {
        if (!ref) {
          return;
        }
        ref.current = null;
      };
    }, [ref]);

    return (
      <>
        {text ? (
          <CustomButton
            ref={anchorRef}
            className={className}
            color={color}
            disabled={disabled}
            endIcon={endIcon}
            fullWidth={fullWidth}
            size={size}
            startIcon={startIcon}
            variant={variant}
            onClick={handleOpen}
          >
            {text}
          </CustomButton>
        ) : (
          <CustomIconButton
            ref={anchorRef}
            btnClassName={btnClassName}
            className={className}
            color={color}
            disabled={disabled}
            icon={icon}
            iconClassName={iconClassName}
            iconColor={iconColor}
            label={label}
            size={size}
            variant={variant}
            onClick={handleOpen}
          />
        )}
        <DropDown ref={anchorRef} disablePortal={disablePortal} open={open} position={position} onClose={handleClose}>
          {children}
        </DropDown>
      </>
    );
  }
);

export default CustomMenuButton;
