import React, { useState, useEffect, useRef } from "react";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { useSelector } from "react-redux";
import { ApiService, PublicService } from "./api";
import { CircularLoader } from "./common/components";
import { useApi, useEffectOnce } from "./common/hooks";
import { Permissions, loadTranslation } from "./common/providers";
import AppRoutes from "./navigation/routes/AppRoutes";
import { KeycloakService } from "./sevices";

const environment = "dev";

const Loader = () => (
  <div style={{ margin: "auto" }}>
    <CircularLoader color="primary" />
  </div>
);

const hasAdminClaim = () => KeycloakService.hasRole([Permissions.REALM.ADMIN.claim]);

const Refresh = ({ children }) => {
  const { isLoading: isRefreshing } = useApi(PublicService.refreshToken);
  const isLogged = KeycloakService.isLoggedIn();
  const user = useSelector(({ context }) => context.user);
  const { value } = useSelector(({ context }) => context.language);
  const locale = KeycloakService.userLanguage();
  const firstTimeLogin = useSelector(({ context }) => context.firstTimeLogin);
  const cancelTokenSourceRef = useRef(null);
  const language = firstTimeLogin ? locale : value;

  useEffect(() => {
    cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
    return () => {
      ApiService.cancelTokens(cancelTokenSourceRef.current);
    };
  }, []);
  useEffectOnce(
    /* If user has ADMIN Claim, then he's automatically Logged out */
    () => {
      if (hasAdminClaim()) {
        KeycloakService.doLogout();
      }
    },
    [],
    () => hasAdminClaim()
  );

  useEffect(() => {
    if (
      environment &&
      user &&
      user?.id &&
      user?.email &&
      user?.displayName &&
      window.location.hostname !== "localhost"
    ) {
      let userguidingUserId = `${environment}#${user.id}#${user.email}`;
      if (environment === "dev") {
        userguidingUserId = "dev";
      }
      if (window.userGuiding) {
        window.userGuiding.identify(userguidingUserId, {
          name: user.displayName,
          email: user.email,
          instance: environment,
          is_active: true,
          language: language.value,
        });
      }
    }
  }, [user, language]);

  return !isLogged && isRefreshing ? <Loader /> : children;
};

const I18n = ({ children }) => {
  const { value } = useSelector(({ context }) => context.language);
  const firstTimeLogin = useSelector(({ context }) => context.firstTimeLogin);
  const [render, setRender] = useState(false);
  const mountedRef = useRef(false);
  const locale = KeycloakService.userLanguage();
  const language = firstTimeLogin ? locale : value;
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    loadTranslation(language)
      .then(() => (mountedRef.current ? setRender(true) : null))
      .catch((err) => console.error(err));
    return () => (mountedRef.current ? setRender(false) : null);
  }, [language]);
  return render ? children : <Loader />;
};

const Root = ({ isOfficeInitialized, title }) => {
  if (!isOfficeInitialized) {
    return <div>Loading {title}</div>;
  }

  return (
    <div className="ms-welcome">
      <Refresh>
        <I18n>
          <AppRoutes />
          <NotificationContainer />
        </I18n>
      </Refresh>
    </div>
  );
};

export default Root;
