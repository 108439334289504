import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOpenProjectSelectionSidePanel, setProject, setProjects } from "../../../slice";
import { SidePanelContent, SidePanel } from "../";
import { translate, translateDate } from "../../../providers";
import { MyService } from "../../../../api";
import { useApi } from "../../../hooks";
import { InfiniteList2 } from "../../infinite-list";
import styles from "./ProjectSelectionSidePanel.module.css";
import { Radio } from "@mui/material";
import { CustomButton } from "../../buttons";
import { IconComponent, icon } from "../../icon-component";
import CustomTooltip from "../../tooltip/CustomTooltip";
import { KeycloakService } from "../../../../sevices";

const ProjectSelectionSidePanel = () => {
  const isLogged = KeycloakService.isLoggedIn();
  const { call: getProjects } = useApi(MyService.getProjects);
  const openProjectSelectionSidePanel = useSelector(({ context }) => context?.openProjectSelectionSidePanel);
  const company = useSelector(({ context }) => context?.company);
  const project = useSelector(({ context }) => context.project);
  const domain = useSelector(({ context }) => context.domain);
  const [infiniteListRequest, setInfiniteListRequest] = useState(null);
  const [tempProject, setTempProject] = useState(null);
  const [sort, setSort] = useState("");
  const [direction, setDirection] = useState("DESC");
  const dispatch = useDispatch();
  useEffect(() => {
    if (isLogged && domain && company?.id && openProjectSelectionSidePanel) {
      setInfiniteListRequest(
        () =>
          ({ page, limit, search }) =>
            getProjects({ page, limit, sort, direction }, { name: search ? search : undefined })
      );
    }
  }, [sort, direction, isLogged, company, domain, openProjectSelectionSidePanel]);
  useEffect(() => {
    if (project) {
      setTempProject(project);
    }
  }, [project]);
  const handleClose = () => {
    dispatch(setOpenProjectSelectionSidePanel(false));
  };
  const handleSelectProject = (row) => {
    setTempProject({ id: row.projectId, name: row.projectName });
  };
  const handleValidate = () => {
    dispatch(setProject(tempProject));
    handleClose();
  };
  const handleResponse = (data) => {
    if (data.pageNumber === 0) {
      const firstPproject = data.contents[0];
      setTempProject({ id: firstPproject.projectId, name: firstPproject.projectName });
    }
    dispatch(setProjects(data.contents));
  };
  const handleSort = (newSort) => {
    if (sort === newSort) {
      setDirection((prev) => (prev === "ASC" ? "DESC" : "ASC"));
    } else {
      setSort(newSort);
      setDirection("ASC");
    }
  };
  const handleSortIconDisplay = (cellSort) => {
    if (cellSort === sort) {
      if (direction === "ASC") {
        return icon.faSortUp;
      }
      return icon.faSortDown;
    }
    return icon.faSort;
  };
  const headerRow = () => {
    return (
      <div className={`${styles.row} ${styles.row__header}`}>
        <div className={`${styles.cell__radio} ${styles.cell__header}`} />
        <div
          className={`${styles.cell__date} ${styles.cell__header}`}
          role="presentation"
          onClick={() => handleSort("created")}
        >
          {translate("common:project-selection-side-pane.date")}
          <IconComponent
            className={styles.sort__icon}
            color={sort === "created" ? "var(--color-dark-grey-2)" : "var(--color-light-grey-3)"}
            icon={handleSortIconDisplay("created")}
          />
        </div>
        <div
          className={`${styles.cell__name} ${styles.cell__header}`}
          role="presentation"
          onClick={() => handleSort("name")}
        >
          {translate("common:project-selection-side-pane.name")}
          <IconComponent
            className={styles.sort__icon}
            color={sort === "name" ? "var(--color-dark-grey-2)" : "var(--color-light-grey-3)"}
            icon={handleSortIconDisplay("name")}
          />
        </div>
      </div>
    );
  };
  const rowRenderer = (row) => {
    return (
      <div key={row.projectId} className={styles.row} role="presentation" onClick={() => handleSelectProject(row)}>
        <div className={styles.cell__radio}>
          <Radio checked={tempProject?.id === row.projectId} className={styles.radio} size="small" />
        </div>
        <div className={styles.cell__date}> {translateDate(row.creationDate)}</div>
        <CustomTooltip showWhenOverflow title={row.projectName}>
          <div className={styles.cell__name}> {row.projectName}</div>
        </CustomTooltip>
      </div>
    );
  };
  return (
    <SidePanel open={openProjectSelectionSidePanel} onClose={handleClose}>
      <SidePanelContent title={translate("common:project-selection-side-pane.title")} onClose={handleClose}>
        <div className={styles.container}>
          <div className={styles.list__container}>
            {isLogged && company && (
              <InfiniteList2
                hasSearch
                callOnResponse={handleResponse}
                headerRow={headerRow}
                request={infiniteListRequest}
                rowRenderer={rowRenderer}
                searchPlaceholder={translate("common:project-selection-side-pane.search-a-project")}
              />
            )}
          </div>
          <div className={styles.btn__container}>
            <CustomButton variant="contained" onClick={handleValidate}>
              {translate("common:btn.validate")}
            </CustomButton>
          </div>
        </div>
      </SidePanelContent>
    </SidePanel>
  );
};

export default ProjectSelectionSidePanel;
