import React from "react";
import styles from "./MenuRow.module.css";
import { CustomButtonLink } from "../../../../../common/components";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { translate } from "../../../../../common/providers";

const MenuRow = ({ menu, onClose }) => {
  const location = useLocation();

  const isDisabled = () => {
    const selectors = menu?.tabKeys?.map((tabKey) => useSelector((state) => state?.[menu.reduxState]?.[tabKey])) || [];

    if (menu?.to?.includes(location?.pathname)) {
      let isDisabled = true;

      selectors.forEach((selector, index) => {
        if (selector !== menu?.tabs[index]) {
          isDisabled = false;
        }
      });

      return isDisabled;
    }

    return false;
  };
  return (
    <div>
      {(menu.to && (
        <CustomButtonLink
          className={isDisabled() ? styles.disabled : ""}
          color={isDisabled() ? "primary" : "secondary"}
          to={menu.to}
          onClick={onClose}
        >
          {translate(menu.name)}
        </CustomButtonLink>
      )) || <span className={styles.title}>{translate(menu.name)}</span>}
    </div>
  );
};

export default MenuRow;
