import React from "react";
import { TextField, MenuItem } from "@mui/material";
import { translate } from "../../../providers";
import { CustomTooltip } from "../../";
import styles from "./Selector.module.css";
import { IconComponent } from "../../icon-component";

export default function Selector({
  autoTranslate = "yes",
  className = "",
  disabled = false,
  error = false,
  extraField = "",
  field = null,
  fallbackField = field,
  fullWidth = false,
  icon = "",
  inputClassName = "",
  itemRenderer = (itemLabel) => itemLabel,
  items,
  label,
  labelClassName = "",
  onChange,
  required = false,
  size = "small",
  textFieldClassName = "",
  value,
  valueRenderer = null,
  withNone = false,
}) {
  const handleChange = (e) => {
    e.stopPropagation();
    if ((!e.target?.value && !withNone) || e.target.value.disabled) {
      return;
    }
    onChange(e);
  };
  return (
    <TextField
      select
      className={className}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      iconcomponent={icon && <IconComponent icon={icon} />}
      InputLabelProps={{ className: labelClassName }}
      InputProps={{
        className: textFieldClassName,
        classes: { input: inputClassName },
      }}
      label={label}
      required={required}
      SelectProps={{
        renderValue: (option) =>
          (valueRenderer && valueRenderer(option)) || (field && option[field]) || option[fallbackField] || option,
        onClose: () =>
          setTimeout(() => {
            document.activeElement.blur();
          }, 0),
      }}
      size={size}
      translate={autoTranslate}
      value={value}
      variant="outlined"
      onChange={handleChange}
    >
      {withNone ? (
        <MenuItem value="">
          <div className={styles.textfield__item}>{translate("common:empty-state.none")}</div>
        </MenuItem>
      ) : null}
      {Array.isArray(items) &&
        items.map((item) => {
          const key = item[field] || item[fallbackField] || item;
          const keyItem = item?.id;
          const extra = extraField && item[extraField] ? ` (${item[extraField]})` : "";
          return (
            <MenuItem key={keyItem || key} disabled={item.disabled} value={item}>
              <CustomTooltip title={item.tooltip || ""}>
                <div className={styles.textfield__item} data-disabled={item.disabled} translate={autoTranslate}>
                  {itemRenderer(`${key}${extra}`)}
                </div>
              </CustomTooltip>
            </MenuItem>
          );
        })}
    </TextField>
  );
}
