import client from "../client/client";

class CategoryTemplateService {
  getTreeForCompany({ search = "" }, cancelToken) {
    return client.get("/thematic-templates", {
      params: { search },
      config: { cancelToken },
    });
  }

  getTreeParentsForCompany(cancelToken) {
    return client.get("/thematic-templates/parents", {
      config: { cancelToken },
    });
  }

  getTreeChildrenForCompany({ parentId }, cancelToken) {
    return client.get(`/thematic-templates/${parentId}/children`, {
      config: { cancelToken },
    });
  }
}
export default new CategoryTemplateService();
