import client from "../client/client";

class ApiService {
  getCancelTokenSource() {
    return client.getCancelTokenSource();
  }

  cancelTokens(cancelTokenSource) {
    return client.cancelTokens(cancelTokenSource);
  }

  isRequestCancellation(error) {
    return client.isRequestCancellation(error);
  }
}
export default new ApiService();
