import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { translate } from "../../../../../providers";
import { debounce } from "../../../../../utils";
import { AutocompleteTextField, SearchInput } from "../../../..";
import { ApiService, PdfViewService } from "../../../../../../api";
import styles from "./FilterSearch.module.css";

const debouncedFunction = debounce((func) => func(), 500);

export default function FilterSearch({
  noSearch = false,
  autocomplete = false,
  className = "",
  debounceSearch = true,
  deliverableAutoComplete = false,
  fullWidth,
  label = "",
  minSearch = 0,
  placeholder = null,
  search,
  onSearch,
  onKeyDown,
}) {
  const projectId = useSelector(({ context }) => context?.project?.id);
  const [searchValue, setSearchValue] = useState(search);
  const inputRefFTS = useRef();
  useEffect(() => {
    setSearchValue(search);
  }, [search]);
  const handleResetSearchText = () => {
    setSearchValue("");
    onSearch();
  };
  const handleChangeSearchText = (event, newValue, newPlainTextValue, mentions) => {
    if (mentions?.length > 0) {
      setSearchValue(newPlainTextValue);
      onSearch(newPlainTextValue);
    } else {
      const { value } = event.target;
      setSearchValue(value);
      if (debounceSearch) {
        debouncedFunction(() => {
          onSearch(value);
        });
      } else {
        onSearch(value);
      }
    }
  };
  const cancelTokenSourceRef = useRef();
  useEffect(() => {
    cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
    return () => {
      ApiService.cancelTokens(cancelTokenSourceRef.current);
    };
  }, []);
  const getFTSSuggestions = (query, callback) => {
    debouncedFunction(() => {
      if (noSearch) {
        return [];
      }
      if (query?.length < 2) {
        return [];
      }
      if (deliverableAutoComplete) {
        PdfViewService.delivAutoComplete(query, cancelTokenSourceRef.current.token).then((d) => {
          callback(d.map((word) => ({ id: word, display: word })));
        });
      } else {
        PdfViewService.autoComplete({ projectId }, query, cancelTokenSourceRef.current.token).then((d) => {
          callback(d.map((word) => ({ id: word, display: word })));
        });
      }
      return [];
    }, 500);
  };
  const handleKeyDownCapture = (e) => {
    if (e.repeat) {
      return;
    }
    if (e.key === "Enter") {
      e.preventDefault();
      onKeyDown(e);
    }
  };
  return (
    <div>
      {(autocomplete && (
        <AutocompleteTextField
          ref={inputRefFTS}
          className={className}
          classNames={styles}
          data={getFTSSuggestions}
          fullWidth={fullWidth}
          label={label}
          placeholder={placeholder === null ? translate("common:btn.search") : placeholder}
          trigger=":"
          value={searchValue}
          onChange={handleChangeSearchText}
          onClearSearch={handleResetSearchText}
          onKeyDown={onKeyDown}
          onKeyDownCapture={handleKeyDownCapture}
        />
      )) || (
        <SearchInput
          className={className}
          fullWidth={fullWidth}
          label={label}
          placeholder={placeholder === null ? translate("common:btn.search") : placeholder}
          value={searchValue}
          onChange={handleChangeSearchText}
          onClearSearch={handleResetSearchText}
          onKeyDown={onKeyDown}
        />
      )}
      {!!minSearch && (
        <div className={styles.helperText}>
          {translate("common:component.filters.keyword-filter.min-input", {
            min: minSearch,
          })}
        </div>
      )}
    </div>
  );
}
