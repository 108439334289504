import React, { useCallback, useEffect, useRef } from "react";
import { CircularLoader } from "..";
import styles from "./ScrollContainer.module.css";

export default function ScrollContainer({
  anticipate = false,
  children,
  className = "",
  isLoading = false,
  onHitBottom,
  onHitTop,
}) {
  const containerRef = useRef(null);
  const handleScroll = useCallback(() => {
    const { scrollHeight, clientHeight, scrollTop } = containerRef.current;
    if (
      Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 3 + (anticipate ? 2 * clientHeight : 0) &&
      typeof onHitBottom === "function"
    ) {
      onHitBottom();
    } else if (scrollTop === 0 && typeof onHitTop === "function") {
      onHitTop();
    }
  }, [onHitBottom, onHitTop, anticipate]);
  useEffect(() => {
    const { scrollHeight, clientHeight, scrollTop } = containerRef.current;
    if (
      scrollHeight === clientHeight ||
      Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1 + (anticipate ? 2 * clientHeight : 0)
    ) {
      onHitBottom();
    }
  }, [children, onHitBottom, anticipate]);
  return (
    <div
      ref={containerRef}
      className={`${styles.container} ${className}`}
      data-loading={isLoading}
      onScroll={handleScroll}
    >
      {children}
      {isLoading && (
        <div className={styles.loaderContainer}>
          <CircularLoader size={40} />
        </div>
      )}
    </div>
  );
}
