import client from "../../client/client";

class MyService {
  getDetails(cancelToken) {
    return client.get("/users/me", { config: { cancelToken } });
  }
  getProjects(
    { page = 0, limit = 50, sort = "created", direction = "ASC" },
    {
      name,
      isUserActivated = true,
      isAccessibleByLoggedInCompany = true,
      isArchiveEnabled = true,
      isOnlyDeliverables = false,
    },
    cancelToken
  ) {
    return client.post("/users/me/projects", {
      params: { page, limit, sort, direction },
      payload: { name, isUserActivated, isAccessibleByLoggedInCompany, isArchiveEnabled, isOnlyDeliverables },
      config: { cancelToken },
    });
  }
  getProjectTypes({ onlyMine }, cancelToken) {
    return client.get("/projects/types", {
      params: { onlyMine },
      config: { cancelToken },
    });
  }
  changeLanguage({ value }, cancelToken) {
    return client.post("/users/me/language", { payload: { value }, config: { cancelToken } });
  }
  getPreferedLanguage(cancelToken) {
    return client.get("/users/me/language", { config: { cancelToken } });
  }
  getProjectDetails({ projectId }, cancelToken) {
    return client.get(`/users/me/projects/${projectId}`, { config: { cancelToken } });
  }
}

export default new MyService();
