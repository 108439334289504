import client from "../../client/client";

class ContentService {
  getContentCoordinatesFromInformation({ docId, informationId }, cancelToken) {
    return client.get(`/contents/documents/${docId}/information/${informationId}`, { config: { cancelToken } });
  }
  getChapterString({ docId, informationId }, cancelToken) {
    return client.get(`/contents/documents/${docId}/chapter/information/${informationId}/contents`, {
      config: { cancelToken },
    });
  }
  getPreviewInformationChapter({ docId, informationId }, cancelToken) {
    return client.get(`/contents/documents/${docId}/position/information/${informationId}`, {
      config: { cancelToken },
    });
  }
  getContentsInSelection({ docId }, { pagePosition }, cancelToken) {
    return client.post(`/contents/documents/${docId}/contents-position`, {
      payload: { pagePosition },
      config: { cancelToken },
    });
  }
  getSelectedContentFromContentIds({ docId }, { contentIds }, cancelToken) {
    return client.post(`/contents/documents/${docId}/selected`, {
      payload: { values: contentIds },
      config: { cancelToken },
    });
  }
  getGenerate({ projectId, isIntegration }, cancelToken) {
    return client.get("/write-plugin/ai/generate", {
      params: {
        projectId,
        isIntegration,
      },
      config: { cancelToken },
    });
  }
  getGenerationState(cancelToken) {
    return client.get("/write-plugin/save/ai/generate", {
      config: { cancelToken },
    });
  }
  saveGenerationState({ integratingProjectContent, userPrompts }, cancelToken) {
    return client.post("/write-plugin/save/ai/generate", {
      payload: { integratingProjectContent, userPrompts },
      config: { cancelToken },
    });
  }
  askQuestion({ question, content, predefinedPrompts, tabId }, cancelToken) {
    return client.post("/write-plugin/ai/chat", {
      payload: { question, content, predefinedPrompts, tabId },
      config: { cancelToken },
    });
  }
  getAskedQuestion({ tabId }, cancelToken) {
    return client.get(`/write-plugin/ai/chat/saved/tabs/${tabId}`, {
      config: { cancelToken },
    });
  }
  editAskedQuestionContent({ value }, { tabId }, cancelToken) {
    return client.patch(`/write-plugin/ai/chat/saved/tabs/${tabId}`, {
      payload: { value },
      config: { cancelToken },
    });
  }
  getAskedQuestionCount(cancelToken) {
    return client.get("/write-plugin/ai/chat/saved/tabs/count", {
      config: { cancelToken },
    });
  }
  saveContent(
    { documentId, informationId, numberOfPages, pageStart, contentPositionDTOs },
    { projectId },
    cancelToken
  ) {
    return client.post("/write-plugin/save/data", {
      payload: { documentId, informationId, numberOfPages, pageStart, contentPositionDTOs },
      params: { projectId },
      config: { cancelToken },
    });
  }
  getSavedContent({ projectId }, cancelToken) {
    return client.get("/write-plugin/save/data", {
      params: { projectId },
      config: { cancelToken },
    });
  }
  deleteSavedContent({ savedDataId }, cancelToken) {
    return client.delete(`/write-plugin/save/data/${savedDataId}`, {
      config: { cancelToken },
    });
  }
  deleteAllSavedContent({ projectId }, cancelToken) {
    return client.delete("/write-plugin/save/data", {
      params: { projectId },
      config: { cancelToken },
    });
  }
  deleteResultsTabs(cancelToken) {
    return client.delete("/write-plugin/save/data/result-tab", {
      config: { cancelToken },
    });
  }
}
export default new ContentService();
