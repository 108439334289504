import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomIconButton, icon } from "..";
import styles from "./CustomAccordion.module.css";
import { getFilteredDataAttributes } from "../../utils";

export default function CustomAccordion({
  className = "",
  closeExpansion = false,
  children,
  defaultOpen = false,
  header = "",
  onExpand,
  onlyArrowExpand = false,
  onSetCloseExpansion,
  rightIndented = false,
  summaryClassName = "",
  ...dataAttributes
}) {
  const filteredDataAttributes = getFilteredDataAttributes(dataAttributes);
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (closeExpansion) {
      setExpanded(false);
      if (typeof onSetCloseExpansion === "function") {
        onSetCloseExpansion(false);
      }
    }
  }, [closeExpansion, onSetCloseExpansion]);
  useEffect(() => {
    if (defaultOpen && !!children) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [defaultOpen]);
  const handleChange = (event, newExpanded) => {
    if (typeof onExpand === "function") {
      onExpand(expanded);
    }
    if (!!children && !onlyArrowExpand) {
      setExpanded(newExpanded);
    }
  };
  const handleClickArrow = () => {
    if (onlyArrowExpand && !!children) {
      setExpanded(!expanded);
    }
  };
  return (
    <Accordion
      classes={{
        root: styles.accordion__root,
      }}
      className={`${styles.accordionContent} ${className}`}
      expanded={expanded}
      onChange={handleChange}
      {...filteredDataAttributes}
    >
      <AccordionSummary
        classes={{
          content: `${styles["accordion-summary__content"]} ${summaryClassName}`,
        }}
        className={styles.accordion__header}
      >
        <div className={styles.arrowContainer}>
          {!!children && (
            <CustomIconButton
              icon={expanded ? icon.faChevronDown : icon.faChevronRight}
              size="sm"
              onClick={handleClickArrow}
            />
          )}
        </div>
        {typeof header === "string" ? <div className={styles.header}>{header}</div> : header}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: styles.accordionDetails__root,
        }}
        data-rightpadding={rightIndented}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
