import React from "react";
import { CustomIconButton, icon } from "../../../../../../../common/components";
import styles from "./OptionsMenu.module.css";
import { WordUtils } from "../../../../../../../common/utils";
import { useDispatch } from "react-redux";
import { translate } from "../../../../../../../common/providers";
import { setEditContentIndex } from "../../../../../slice/multi-project-search-slice";
import { useApi } from "../../../../../../../common/hooks";
import { ContentService } from "../../../../../../../api";

const OptionsMenu = ({ chatsCount, insertableContent, onIncrementChatCount, onResetTabs }) => {
  const { call: deleteResultsTabs } = useApi(ContentService.deleteResultsTabs);
  const dispatch = useDispatch();

  const handleInsertToDoc = () => {
    WordUtils.insertTextToWord(insertableContent);
  };
  const handleInsertSelection = () => {
    dispatch(setEditContentIndex(chatsCount === 0 ? 2 : chatsCount + 1));
    onIncrementChatCount();
  };
  const handleReset = () => {
    deleteResultsTabs()
      .then(() => onResetTabs())
      .catch(console.error);
  };
  return (
    <>
      <CustomIconButton
        btnClassName={styles["btn--transparent"]}
        color="secondary"
        icon={icon.faArrowLeft}
        size="sm"
        tooltip={translate("multi-projects-search.edition.options-menu.insert-to-word")}
        variant="outlined"
        onClick={handleInsertToDoc}
      />
      <CustomIconButton
        btnClassName={styles["btn--transparent"]}
        color="secondary"
        disabled={!insertableContent || !/\S/.test(insertableContent)}
        icon={icon.faPlus}
        size="sm"
        tooltip={translate("multi-projects-search.edition.options-menu.add-page")}
        variant="outlined"
        onClick={handleInsertSelection}
      />
      <CustomIconButton
        btnClassName={styles["btn--transparent"]}
        color="secondary"
        icon={icon.faTrash}
        size="sm"
        tooltip={translate("common:btn.reset")}
        variant="outlined"
        onClick={handleReset}
      />
    </>
  );
};

export default OptionsMenu;
