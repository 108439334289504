import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function CustomTooltip({
  autoTranslate = "yes",
  arrow = false,
  children,
  className = "",
  enterDelay = 100,
  placement = "bottom",
  title = "",
  showWhenOverflow = false,
}) {
  const ref = React.useRef(null);
  const [newTitle, setNewTitle] = useState(title);
  useEffect(() => {
    setNewTitle(title);
    if (showWhenOverflow) {
      const handleTitle = () => {
        setNewTitle(ref.current?.offsetWidth < ref.current?.scrollWidth ? title || "" : "");
      };
      handleTitle();
      window.addEventListener("resize", handleTitle);
      return () => {
        window.removeEventListener("resize", handleTitle);
      };
    }
    return null;
  }, [showWhenOverflow, title, ref.current?.offsetWidth]);
  return (
    <Tooltip
      ref={ref}
      arrow={arrow}
      classes={{
        tooltip: `${"font-sm-regular"} ${className}`,
      }}
      enterDelay={enterDelay}
      placement={placement}
      title={newTitle}
      translate={autoTranslate}
    >
      {children}
    </Tooltip>
  );
}
