import { Radio } from "@mui/material";
import React from "react";
import styles from "./InstanceRow.module.css";
const InstanceRow = ({ instance, onClick, selectedInstance }) => {
  const handleClick = () => {
    onClick(instance);
  };
  return (
    <div className={styles.row} role="presentation" onClick={handleClick}>
      <Radio checked={selectedInstance === instance} className={styles.radio} />
      <span className={styles.instanceName}>{instance}</span>
    </div>
  );
};

export default InstanceRow;
