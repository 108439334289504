import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./DocumentFilter.module.css";
import { ToggleButtons } from "../../../toggles";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../providers";
import { DocTree } from "./components";
import { useApi } from "../../../../hooks";
import { ProjectDocumentsService, SmartViewService } from "../../../../../api";
import { isNonEmptyArray, updateTreeBranch } from "../../../../utils";
import { CircularLoader } from "../../../loaders";

// Adapts data to understable format when search is presented for display by folder
const handleFormatRecursiveDirData = (folders) => {
  if (!isNonEmptyArray(folders)) {
    return null;
  }
  let formatedArray = [];
  folders.forEach((folder) => {
    let formatedElement = {
      id: folder.id,
      label: folder.label,
      expanded: true,
      type: "FOLDER",
      documents: folder.documents?.map((document) => ({ ...document, type: "FILE" })),
    };
    if (isNonEmptyArray(folder?.children)) {
      formatedElement = {
        ...formatedElement,
        expanded: true,
        documents: [...(folder?.documents || []), ...handleFormatRecursiveDirData(folder.children)],
      };
    }
    formatedArray = [...formatedArray, formatedElement];
  });
  return formatedArray;
};
// Formats data when search is used By dir first level
const handleFormatDirData = (unformatedData) => {
  let documentList = [];
  let folderList = [];
  if (isNonEmptyArray(unformatedData.documentsInRoot)) {
    documentList = unformatedData.documentsInRoot.map((folder) => ({ ...folder, type: "FILE" }));
  }
  if (isNonEmptyArray(unformatedData.folders)) {
    folderList = handleFormatRecursiveDirData(unformatedData.folders);
  }
  return [...folderList, ...documentList];
};
// Formats data when search is used by precedence
const handleFormatPrecData = (unformatedData) =>
  unformatedData.map((d) => ({ ...d.precedence, documents: d.documents, expanded: true }));

const DocumentFilter = ({ disabled, onChange, search = "", value = { is: true, matchAll: true, elements: [] } }) => {
  const { call: getDirectory } = useApi(ProjectDocumentsService.getDirectory);
  const { call: getAllPrecedences } = useApi(ProjectDocumentsService.getAllPrecedences);
  const { call: getPrecedence } = useApi(ProjectDocumentsService.getPrecedence);
  const { call: getAllRoles } = useApi(ProjectDocumentsService.getAllRoles);
  const { call: getRole } = useApi(ProjectDocumentsService.getRole);
  const { call: getSelectedDocumentCountByRole } = useApi(SmartViewService.getSelectedDocumentCountByRole);
  const { call: searchInDirectoryByDocumentName } = useApi(SmartViewService.searchInDirectoryByDocumentName);
  const { call: searchInRoleByDocumentName } = useApi(SmartViewService.searchInRoleByDocumentName);
  const { call: searchInPrecedenceByDocumentName } = useApi(SmartViewService.searchInPrecedenceByDocumentName);
  const { call: getSelectedDocumentCountByPrecedence } = useApi(SmartViewService.getSelectedDocumentCountByPrecedence);
  const projectId = useSelector(({ context }) => context.project?.id);
  const [mode, setMode] = useState("folder");
  const [docs, setDocs] = useState([]);
  const [parentCounters, setParentCounters] = useState([]);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const modes = useMemo(() => {
    let newModes = [
      {
        value: "folder",
        icon: "faFolder",
        tooltip: translate("common:filters.document-list.folder-list"),
      },
    ];
    if (isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER) && isSegFeatureEnabled(SegFlags.PRECEDENCE)) {
      newModes = [
        ...newModes,
        {
          value: "precedence",
          icon: "faStar",
          tooltip: translate("common:filters.document-list.precedence-list"),
        },
      ];
    }
    if (isSegFeatureEnabled(SegFlags.RIGHTS_ON_DOCUMENTS) && isSegFeatureEnabled(SegFlags.TEAM)) {
      newModes = [
        ...newModes,
        {
          value: "role",
          icon: "faUserTie",
          tooltip: translate("common:filters.document-list.role-list"),
        },
      ];
    }

    return newModes;
  }, []);

  // Updates counter for directory with search
  const handleUpdateCounterOnGetWithSearch = (folders, result = [], prevFolderId = 0) => {
    if (isNonEmptyArray(value.elements)) {
      if (!isNonEmptyArray(folders)) {
        return result;
      }
      let temp = result;
      folders.forEach((folder) => {
        temp = [
          ...temp,
          {
            parentId: folder.id,
            counter: folder.documents?.filter((doc) => value.elements.some((v) => v === doc.id))?.length || 0,
          },
        ];
        temp = handleUpdateCounterOnGetWithSearch(folder.children, temp, folder.id);
        temp = temp.map((t) => {
          if (t.parentId === prevFolderId) {
            return {
              ...t,
              counter: t.counter + (temp.find((f) => f.parentId === folder.id)?.counter || 0),
            };
          }
          return t;
        });
      });
      return temp;
    }
    return [];
  };
  // get count of each folder when there is no search
  const handleUpdateCounterOnGet = (folder) => ({
    parentId: folder.id,
    counter: folder.childrenIds?.filter((childrenId) => value.elements.includes(childrenId))?.length || 0,
  });
  // iterate through each folder to get count
  const handleExtractFolders = (elements) => {
    let newParentCounters = [];
    if (isNonEmptyArray(value.elements)) {
      newParentCounters = [...parentCounters];
      elements.forEach((element) => {
        const parentCountIndex = newParentCounters.findIndex((p) => p.parentId === element.id);
        if (isNonEmptyArray(element.childrenIds)) {
          if (parentCountIndex >= 0) {
            newParentCounters[parentCountIndex] = handleUpdateCounterOnGet(element);
          } else {
            newParentCounters = [...newParentCounters, handleUpdateCounterOnGet(element)];
          }
        }
      });
    }
    setParentCounters(newParentCounters);
  };
  useEffect(() => {
    handleExtractFolders(docs);
  }, [docs, value.elements?.length]);
  const getTree = useCallback(() => {
    if (mode === "folder") {
      getDirectory({ projectId, directoryId: "root" })
        .then((data) => {
          setDocs(data);
        })
        .catch(console.error)
        .finally(() => setLoadingDocuments(false));

      return;
    }
    if (mode === "precedence") {
      getAllPrecedences({ projectId })
        .then((data) => setDocs(data))
        .catch(console.error)
        .finally(() => setLoadingDocuments(false));
      return;
    }
    if (mode === "role") {
      getAllRoles({ projectId })
        .then((data) => setDocs(data))
        .catch(console.error)
        .finally(() => setLoadingDocuments(false));
    }
  }, [getAllPrecedences, getAllRoles, getDirectory, mode, projectId]);
  const getTreeWithSearch = useCallback(() => {
    if (mode === "folder") {
      searchInDirectoryByDocumentName({ projectId }, { search })
        .then((data) => {
          setParentCounters(handleUpdateCounterOnGetWithSearch(data.folders));
          setDocs(handleFormatDirData(data));
        })
        .catch(console.error)
        .finally(() => setLoadingDocuments(false));

      return;
    }
    if (mode === "precedence") {
      searchInPrecedenceByDocumentName({ projectId }, { search })
        .then((data) => setDocs(handleFormatPrecData(data)))
        .catch(console.error)
        .finally(() => setLoadingDocuments(false));
      return;
    }
    if (mode === "role") {
      searchInRoleByDocumentName({ projectId }, { search })
        .then((data) => setDocs(data.map((d) => ({ ...d, expanded: true }))))
        .catch(console.error)
        .finally(() => setLoadingDocuments(false));
    }
  }, [
    mode,
    projectId,
    search,
    searchInDirectoryByDocumentName,
    searchInPrecedenceByDocumentName,
    searchInRoleByDocumentName,
  ]);

  useEffect(() => {
    setLoadingDocuments(true);
    if (search) {
      getTreeWithSearch();
    } else {
      getTree();
    }
  }, [search, getTree, getTreeWithSearch]);
  useEffect(() => {
    if (!isNonEmptyArray(value.elements)) {
      setParentCounters([]);
      return;
    }
    if (mode === "precedence") {
      getSelectedDocumentCountByPrecedence({ projectId }, { values: value.elements })
        .then(setParentCounters)
        .catch(console.error);
    }
    if (mode === "role") {
      getSelectedDocumentCountByRole({ projectId }, { values: value.elements })
        .then(setParentCounters)
        .catch(console.error);
    }
  }, [projectId, mode, getSelectedDocumentCountByPrecedence, getSelectedDocumentCountByRole]);
  const handleChange = (_, newMode) => {
    if (disabled || !newMode) {
      return;
    }
    setMode(newMode);
  };
  const isFolder = (el) => el.type === "FOLDER" || el.role || el.precedenceId;
  const getIdentifier = () => {
    if (mode === "folder") {
      return "id";
    }
    if (mode === "precedence") {
      return "precedenceId";
    }
    if (mode === "role") {
      return "role";
    }
    return "id";
  };
  const setTreeValue = (directory, elements) => {
    setDocs((prev) =>
      updateTreeBranch({
        tree: prev,
        additionalConditions: isFolder,
        idToReplace: directory[getIdentifier()],
        identifier: getIdentifier(),
        newBranch: {
          ...directory,
          documents: elements,
          expanded: !directory.expanded,
        },
        iterativeBranchName: "documents",
      })
    );
  };
  const handleToggleExpand = (directory, onCallBack) => {
    if (!isNonEmptyArray(directory.documents)) {
      if (mode === "folder") {
        getDirectory({ projectId, directoryId: directory.id })
          .then((data) => {
            setTreeValue(directory, data);
            handleExtractFolders(data);
          })
          .catch((err) => console.error(err))
          .finally(() => onCallBack(false));
        return;
      }
      if (mode === "precedence") {
        getPrecedence({ precedenceId: directory.precedenceId })
          .then((data) => {
            setTreeValue(directory, data);
          })
          .catch((err) => console.error(err))
          .finally(() => onCallBack(false));
        return;
      }
      if (mode === "role") {
        getRole({ projectId, role: directory.role })
          .then((data) => {
            setTreeValue(directory, data);
          })
          .catch((err) => console.error(err))
          .finally(() => onCallBack(false));
      }
    } else {
      setDocs((prev) =>
        updateTreeBranch({
          tree: prev,
          additionalConditions: isFolder,
          idToReplace: directory[getIdentifier()],
          identifier: getIdentifier(),
          newBranch: {
            ...directory,
            expanded: directory.filtered !== undefined ? false : !directory.expanded,
            filtered: directory.prevFilteredState,
            prevFilteredState: directory.filtered,
          },
          iterativeBranchName: "documents",
        })
      );
      onCallBack(false);
    }
  };
  const handleCalculateNewCounter = (isChecked, affectedelement, selectedIds) => {
    if (isChecked) {
      return affectedelement.counter > selectedIds.length ? affectedelement.counter - selectedIds.length : 0;
    }
    return affectedelement.counter + selectedIds.length;
  };
  const getParentCounterIdentifierKey = () => {
    if (mode === "role") {
      return "parentName";
    }
    return "parentId";
  };
  const handleAdjustCounters = ({ parentIds, isChecked, selectedIds }) => {
    parentIds.forEach((parentId) => {
      const parentIdentifierKey = getParentCounterIdentifierKey();
      if (parentCounters.find((parentCounter) => parentCounter[parentIdentifierKey] === parentId)) {
        setParentCounters((prev) =>
          prev.map((p) => {
            if (p[parentIdentifierKey] === parentId) {
              return {
                ...p,
                counter: handleCalculateNewCounter(isChecked, p, selectedIds),
              };
            }
            return p;
          })
        );
      } else {
        setParentCounters((prev) => [
          ...prev,
          {
            [parentIdentifierKey]: parentId,
            counter: selectedIds.length,
          },
        ]);
      }
    });
  };
  const handleCheck = ({ selectedIds, isChecked, parentIds }) => {
    let newElements = value.elements;
    if (isChecked) {
      newElements = newElements.filter((newElement) => !selectedIds.includes(newElement));
    } else {
      newElements = [...newElements, ...selectedIds];
    }
    if (isNonEmptyArray(parentIds)) {
      handleAdjustCounters({ parentIds, isChecked, selectedIds });
    }
    onChange({ ...value, elements: newElements });
  };
  return (
    <div className={styles.main}>
      <ToggleButtons
        exclusive
        labelized
        btnClassName={styles.toggleGroup__btn}
        className={styles.toggleGroup}
        content={modes}
        size="large"
        value={mode}
        onChange={handleChange}
      />
      {(loadingDocuments && (
        <div className={styles.loaderContainer}>
          <CircularLoader size={50} />
        </div>
      )) || (
        <DocTree
          disabled={disabled}
          documents={docs}
          hasSearch={search}
          mode={mode}
          parentCounters={parentCounters}
          selectedIds={value.elements}
          onAdjustCounters={handleAdjustCounters}
          onCheck={handleCheck}
          onToggleExpand={handleToggleExpand}
        />
      )}
    </div>
  );
};
export default DocumentFilter;
