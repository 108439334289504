import React, { useCallback, useEffect, useState } from "react";
import {
  CircularLoader,
  CustomButton,
  CustomDialog,
  CustomIconButton,
  CustomTooltip,
  GenericDialog,
  GenericDialogContent,
  GenericDialogHeader,
  HorizontalDivider,
  I18nTranslate,
  icon,
  IconComponent,
} from "../../../../../common/components";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../../../../../common/providers";
import styles from "./SyncDialog.module.css";
import { isNonEmptyArray, WordUtils } from "../../../../../common/utils";
import { setDeliverableToUpdate } from "../../../../../common/slice";
import { ParagraphOutService } from "../../../../../api";
import { useApi } from "../../../../../common/hooks";
import {
  setUpdateReqCounters,
  setUpdateReqList,
} from "../../../../../views/link-requirement-to-deliverable/slice/link-requirement-to-deliverable-slice";

const SyncDialog = ({ onClose, open }) => {
  const { call: getCountLinksNotPresentInDoc } = useApi(ParagraphOutService.getCountLinksNotPresentInDoc);
  const { call: deleteLinksNotPresentInDoc } = useApi(ParagraphOutService.deleteLinksNotPresentInDoc);
  const { call: transferLinksAndParagraphsToNewDeliv } = useApi(
    ParagraphOutService.transferLinksAndParagraphsToNewDeliv
  );
  const deliverableName = useSelector(({ context }) => context.deliverable?.name);
  const deliverableId = useSelector(({ context }) => context.deliverable?.id);
  const projectId = useSelector(({ context }) => context.projectReqToDeliv?.id);
  const projectName = useSelector(({ context }) => context.projectReqToDeliv?.name);
  const [totalCount, setTotalCount] = useState(0);
  const [doNotReferToDelivCount, setDoNotReferToDelivCount] = useState(0);
  const [doNotReferinAITCount, setDoNotReferinAITCount] = useState(0);
  const [actionInProgress, setActionInprogress] = useState(false);
  const [loadingCounters, setLoadingCounters] = useState(true);
  const [linksMatchedDeliv, setLinksMatchedDeliv] = useState([]);
  const [linksMissMatchedDeliv, setLinksMissMatchedDeliv] = useState([]);
  const [currentGlobalIndex, setCurrentGlobalIndex] = useState(0);
  const [currentMissmatchIndex, setCurrentMissmatchIndex] = useState(0);
  const [openDeleteAITDialog, setOpenDeleteAITDialog] = useState(false);
  const [openDeleteWordDialog, setOpenDeleteWordDialog] = useState(false);
  const [linkDuplicates, setLinkDuplicates] = useState([]);
  const [duplicateIndexes, setDuplicateIndexes] = useState({});
  const [activeDuplicate, setActiveDuplicate] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    setCurrentGlobalIndex(0);
    setCurrentMissmatchIndex(0);
  }, [open]);

  const handleCounters = useCallback(
    ({ total, matchDelivId, missMatchDelivId, duplicates }) => {
      setLinkDuplicates(duplicates);
      setTotalCount(total);
      setDoNotReferToDelivCount(total - matchDelivId.length);
      setLinksMatchedDeliv(matchDelivId);
      setLinksMissMatchedDeliv(missMatchDelivId);
      let tempIndexes = {};
      duplicates.forEach((duplicate) => (tempIndexes = { ...tempIndexes, [duplicate.linkId]: 0 }));
      setDuplicateIndexes(tempIndexes);
      getCountLinksNotPresentInDoc(
        { projectId, deliverableId },
        { values: isNonEmptyArray(matchDelivId) ? matchDelivId : null }
      )
        .then((data) => setDoNotReferinAITCount(data))
        .catch(console.error)
        .finally(() => setLoadingCounters(false));
    },
    [projectId, deliverableId]
  );
  useEffect(() => {
    if (open) {
      setLoadingCounters(true);
      WordUtils.findContentControls(deliverableId, handleCounters);
    }
  }, [open, handleCounters]);
  useEffect(() => {
    if (activeDuplicate && duplicateIndexes[activeDuplicate]) {
      WordUtils.highlightDuplicateContentAt(activeDuplicate, duplicateIndexes[activeDuplicate]);
    }
  }, [activeDuplicate, duplicateIndexes]);

  const handleClose = () => {
    onClose();
    setActionInprogress(false);
  };
  const handleChangeDeliverable = () => {
    dispatch(setDeliverableToUpdate(true));
    handleClose();
  };
  const handleRemoveFromDoc = () => {
    setActionInprogress(true);
    WordUtils.removeFromDocNotCorrectDeliv(deliverableId, handleClose);
    handleOpenDeleteWordDialog();
  };
  const handleDeleteFromAIT = () => {
    setActionInprogress(true);
    handleOpenDeleteAITDialog();
    deleteLinksNotPresentInDoc(
      { projectId, deliverableId },
      { values: isNonEmptyArray(linksMatchedDeliv) ? linksMatchedDeliv : [] }
    )
      .catch(console.error)
      .finally(() => {
        handleClose();
        dispatch(setUpdateReqList(true));
        dispatch(setUpdateReqCounters(true));
      });
  };
  const handleCreateCopyInAIT = () => {
    setActionInprogress(true);
    transferLinksAndParagraphsToNewDeliv({ deliverableId, projectId }, { linksIds: linksMissMatchedDeliv })
      .then((data) => WordUtils.updateContentControlsAfterMigration(data, deliverableId))
      .catch(console.error)
      .finally(() => {
        handleClose();
        dispatch(setUpdateReqList(true));
        dispatch(setUpdateReqCounters(true));
      });
  };
  const handleIncrementGlobalIndex = () => {
    setCurrentGlobalIndex((prev) => prev + 1);
    WordUtils.highlightGlobalContentAt(currentGlobalIndex + 1);
  };
  const handleDecrementGlobalIndex = () => {
    setCurrentGlobalIndex((prev) => prev - 1);
    WordUtils.highlightGlobalContentAt(currentGlobalIndex - 1);
  };
  const handleIncrementMissmatchIndex = () => {
    setCurrentMissmatchIndex((prev) => prev + 1);
    WordUtils.highlightMissMatchedContentAt(currentMissmatchIndex + 1, deliverableId);
  };
  const handleDecrementMissMatchIndex = () => {
    setCurrentMissmatchIndex((prev) => prev - 1);
    WordUtils.highlightMissMatchedContentAt(currentMissmatchIndex - 1, deliverableId);
  };
  const handleOpenDeleteAITDialog = () => {
    setOpenDeleteAITDialog((prev) => !prev);
  };
  const handleOpenDeleteWordDialog = () => {
    setOpenDeleteWordDialog((prev) => !prev);
  };
  const handleIncrementDuplicateCount = (e, duplicateIndex) => {
    e.stopPropagation();
    setActiveDuplicate(duplicateIndex);
    setDuplicateIndexes((prev) => ({ ...prev, [duplicateIndex]: duplicateIndexes[duplicateIndex] + 1 }));
  };
  const handleDecrementDuplicateCount = (e, duplicateIndex) => {
    e.stopPropagation();
    setActiveDuplicate(duplicateIndex);
    setDuplicateIndexes((prev) => ({ ...prev, [duplicateIndex]: duplicateIndexes[duplicateIndex] - 1 }));
  };
  const handlSetActiveDuplicate = (newActiveDuplicate) => {
    setActiveDuplicate(newActiveDuplicate);
  };
  const handleDeleteThisDuplicate = () => {
    if (activeDuplicate && duplicateIndexes[activeDuplicate]) {
      const totalCount = linkDuplicates.find((ld) => ld.linkId === activeDuplicate).count;
      const newCurrentIndex =
        duplicateIndexes[activeDuplicate] >= totalCount ? totalCount - 1 : duplicateIndexes[activeDuplicate];
      WordUtils.removeDuplicateContentAt(activeDuplicate, duplicateIndexes[activeDuplicate], newCurrentIndex);
      duplicateIndexes[activeDuplicate] = newCurrentIndex;
      setLinkDuplicates((prev) =>
        prev
          .map((p) => {
            if (p.linkId === activeDuplicate) {
              return { ...p, count: p.count - 1 };
            }
            return p;
          })
          .filter((p) => p.count !== 1)
      );
    }
  };
  const handleDeleteOtherDuplicates = () => {
    if (activeDuplicate && duplicateIndexes[activeDuplicate]) {
      WordUtils.removeDuplicatesNotAtContentAt(activeDuplicate, duplicateIndexes[activeDuplicate]);
      setLinkDuplicates((prev) => prev.filter((p) => p.linkId !== activeDuplicate));
    }
  };
  return (
    <>
      <GenericDialog open={open} onClose={handleClose}>
        <GenericDialogHeader className={styles.header}>
          <div>{translate("common:sync-dialog.header.synchronization")}</div>
          <CustomIconButton icon={icon.faTimes} onClick={handleClose} />
        </GenericDialogHeader>
        <GenericDialogContent className={styles.content}>
          <div className={styles.container}>
            <HorizontalDivider />
            <div className={styles.content__header}>{translate("common:sync-dialog.content.general")}</div>
            <div>
              <ul>
                <li>
                  {translate("common:sync-dialog.content.project-selected")}&nbsp;
                  <b>{projectName}</b>
                </li>
                <li>
                  {translate("common:sync-dialog.content.deliverable-selected")}&nbsp;
                  <b>{deliverableName}</b>
                </li>
                <li>
                  {translate("common:sync-dialog.content.document-contains")}
                  <CustomIconButton
                    className={styles.navigateButton}
                    disabled={loadingCounters || currentGlobalIndex <= 1}
                    icon={icon.faChevronLeft}
                    size="xs"
                    onClick={handleDecrementGlobalIndex}
                  />
                  <b>
                    {currentGlobalIndex}/{loadingCounters ? <CircularLoader size={14} /> : totalCount}
                  </b>
                  <CustomIconButton
                    className={styles.navigateButton}
                    disabled={loadingCounters || currentGlobalIndex >= totalCount}
                    icon={icon.faChevronRight}
                    size="xs"
                    onClick={handleIncrementGlobalIndex}
                  />
                  {translate("common:sync-dialog.content.cover-links")}
                </li>
              </ul>
            </div>
            <HorizontalDivider />
            <div className={styles.content__header}>
              {translate("common:sync-dialog.content.sync-problem")}
              <CustomTooltip title={<I18nTranslate translationKey="common:sync-dialog.content.sync-problem.tooltip" />}>
                <IconComponent className={styles.icon} color="var(--color-light-grey-1)" icon={icon.faInfoCircle} />
              </CustomTooltip>
            </div>
            {((doNotReferinAITCount > 0 || doNotReferToDelivCount > 0 || linkDuplicates.length > 0) && (
              <>
                {doNotReferToDelivCount > 0 && (
                  <>
                    <div className={styles["text--orange"]}>
                      <span>{translate("common:sync-dialog.content.links-no-lead")}</span>
                      <CustomIconButton
                        className={styles.navigateButton}
                        disabled={loadingCounters || currentMissmatchIndex <= 1}
                        icon={icon.faChevronLeft}
                        iconColor="var(--color-orange)"
                        size="xs"
                        onClick={handleDecrementMissMatchIndex}
                      />
                      <b>
                        {currentMissmatchIndex}/
                        {loadingCounters ? <CircularLoader size={14} /> : doNotReferToDelivCount}
                      </b>
                      <CustomIconButton
                        className={styles.navigateButton}
                        disabled={loadingCounters || currentMissmatchIndex >= doNotReferToDelivCount}
                        icon={icon.faChevronRight}
                        iconColor="var(--color-orange)"
                        size="xs"
                        onClick={handleIncrementMissmatchIndex}
                      />
                    </div>
                    <div className={styles.btnContainer}>
                      <CustomButton
                        className={styles.button}
                        color="secondary"
                        disabled={actionInProgress || loadingCounters}
                        size="md"
                        variant="outlined"
                        onClick={handleCreateCopyInAIT}
                      >
                        {translate("common:sync-dialog.btn.create-links-ait-db")}
                      </CustomButton>
                      <CustomButton
                        className={styles.button}
                        color="secondary"
                        disabled={actionInProgress || loadingCounters}
                        size="md"
                        variant="outlined"
                        onClick={handleOpenDeleteWordDialog}
                      >
                        {actionInProgress && <CircularLoader size={16} />}
                        {translate("common:sync-dialog.btn.delete-links-doc")}
                      </CustomButton>
                      <CustomButton
                        className={styles.button}
                        color="secondary"
                        disabled={actionInProgress || loadingCounters}
                        size="md"
                        variant="outlined"
                        onClick={handleChangeDeliverable}
                      >
                        {actionInProgress && <CircularLoader size={16} />}
                        {translate("common:sync-dialog.btn.change-deliverable-ait")}
                      </CustomButton>
                    </div>
                  </>
                )}
                {doNotReferinAITCount > 0 && (
                  <>
                    <div className={styles["text--orange"]}>
                      <b>
                        {loadingCounters ? <CircularLoader size={14} /> : doNotReferinAITCount}&nbsp;
                        {translate("common:sync-dialog.content.links")}
                      </b>
                      &nbsp;
                      <span>{translate("common:sync-dialog.content.in-ait-db")}</span>
                    </div>
                    <CustomButton
                      className={styles.button}
                      color="secondary"
                      disabled={actionInProgress || loadingCounters}
                      size="md"
                      variant="outlined"
                      onClick={handleOpenDeleteAITDialog}
                    >
                      {translate("common:sync-dialog.btn.delete-links-ait")}
                    </CustomButton>
                  </>
                )}
                {linkDuplicates.length > 0 && (
                  <div>
                    <div className={styles["text--orange"]}>
                      {translate("common:sync-dialog.dialog.duplicates.several-links")}
                    </div>
                    {linkDuplicates.map((linkDuplicate) => (
                      <div
                        key={linkDuplicate.linkId}
                        className={styles.duplicateBox}
                        data-active={activeDuplicate === linkDuplicate.linkId}
                        onClick={() => handlSetActiveDuplicate(linkDuplicate.linkId)}
                      >
                        <div className={styles.duplicate__header}>
                          <div className={styles.duplicate__title}>
                            {translate("common:sync-dialog.dialog.duplicates.link-for-req", {
                              reqId: linkDuplicate.req,
                            })}
                          </div>
                          <div>
                            <CustomIconButton
                              className={styles.navigateButton}
                              disabled={loadingCounters || duplicateIndexes[linkDuplicate.linkId] <= 1}
                              icon={icon.faChevronLeft}
                              size="xs"
                              onClick={(e) => handleDecrementDuplicateCount(e, linkDuplicate.linkId)}
                            />
                            {duplicateIndexes[linkDuplicate.linkId]}/
                            {loadingCounters ? <CircularLoader size={14} /> : linkDuplicate.count}
                            <CustomIconButton
                              className={styles.navigateButton}
                              disabled={
                                loadingCounters || duplicateIndexes[linkDuplicate.linkId] >= linkDuplicate.count
                              }
                              icon={icon.faChevronRight}
                              size="xs"
                              onClick={(e) => handleIncrementDuplicateCount(e, linkDuplicate.linkId)}
                            />
                          </div>
                        </div>
                        <div className={styles.duplacte__btnContainer}>
                          <CustomButton
                            className={styles.duplicate__btn}
                            color="secondary"
                            disabled={
                              duplicateIndexes[linkDuplicate.linkId] === 0 || activeDuplicate !== linkDuplicate.linkId
                            }
                            size="sm"
                            variant="outlined"
                            onClick={handleDeleteThisDuplicate}
                          >
                            {translate("common:sync-dialog.dialog.duplicates.delete-this")}
                          </CustomButton>
                          <CustomButton
                            className={styles.duplicate__btn}
                            color="secondary"
                            disabled={
                              duplicateIndexes[linkDuplicate.linkId] === 0 || activeDuplicate !== linkDuplicate.linkId
                            }
                            size="sm"
                            variant="outlined"
                            onClick={handleDeleteOtherDuplicates}
                          >
                            {translate("common:sync-dialog.dialog.duplicates.delete-other")}
                          </CustomButton>
                          <div className={styles.duplicate__infoBtn}>
                            <CustomButton
                              disabled
                              className={styles.duplicate__btn}
                              color="secondary"
                              size="sm"
                              variant="outlined"
                            >
                              {translate("common:sync-dialog.dialog.duplicates.create-all")}
                            </CustomButton>
                            <CustomTooltip title={translate("common:sync-dialog.dialog.duplicates.upcoming")}>
                              <IconComponent color="var(--color-light-grey-1)" icon={icon.faInfoCircle} />
                            </CustomTooltip>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )) || <div className={styles["text--green"]}>{translate("common:sync-dialog.content.no-problems")}</div>}
          </div>
        </GenericDialogContent>
      </GenericDialog>
      <CustomDialog
        closeLabel={translate("common:btn.cancel")}
        iconColor="var(--color-red)"
        iconModel={icon.faExclamationTriangle}
        open={openDeleteAITDialog}
        submitLabel={translate("common:btn.delete")}
        subTitle={translate("common:sync-dialog.dialog.delete-links-ait")}
        onClose={handleOpenDeleteAITDialog}
        onSubmit={handleDeleteFromAIT}
      />
      <CustomDialog
        closeLabel={translate("common:btn.cancel")}
        iconColor="var(--color-red)"
        iconModel={icon.faExclamationTriangle}
        open={openDeleteWordDialog}
        submitLabel={translate("common:btn.delete")}
        subTitle={translate("common:sync-dialog.dialog.delete-links-doc")}
        onClose={handleOpenDeleteWordDialog}
        onSubmit={handleRemoveFromDoc}
      />
    </>
  );
};

export default SyncDialog;
