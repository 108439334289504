import React, { useState } from "react";
import { CustomButton, CustomIconButton, icon, IconComponent } from "../../common/components";
import { translate } from "../../common/providers";
import { aitendersLogo } from "../../common/resources/images";
import styles from "./Introduction.module.css";
import { KeycloakService } from "../../sevices";
import { useDispatch } from "react-redux";
import { setResetRoutes } from "../../common/slice";

const Introduction = () => {
  const [openHowTo, setOpenHowTo] = useState(false);
  const dispatch = useDispatch();
  const handleOpen = () => {
    setOpenHowTo((prev) => !prev);
  };
  const handleCallback = () => {
    dispatch(setResetRoutes(true));
  };
  const handleLogin = () => {
    const isLogged = KeycloakService.isLoggedIn();
    if (!isLogged) {
      KeycloakService.doLogin();
    }
    handleCallback();
  };
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <img alt="aitenders_logo" src={aitendersLogo} width="200px" />
        <div className={styles.logoText}>Smart Draft</div>
      </div>
      <div>
        {/* <div className={styles.info}>
          <span className={styles.infoIcon}>
            <IconComponent color="var(--color-blue)" icon={icon.faPen} size="xl" />
          </span>
          {translate("common:introduction.info-draft")}
        </div>
        <div className={styles.info}>
          <span className={styles.infoIcon}>
            <IconComponent color="var(--color-blue)" icon={icon.faCircleCheck} size="xl" />
          </span>
          {translate("common:introduction.info-improve")}
        </div>
        <div className={styles.info}>
          <span className={styles.infoIcon}>
            <IconComponent color="var(--color-blue)" icon={icon.faClockRegular} size="xl" />
          </span>
          {translate("common:introduction.info-reduce")}
        </div>
        <div className={styles.info}>
          <span className={styles.infoIcon}>
            <IconComponent color="var(--color-blue)" icon={icon.faMagnifyingGlass} size="xl" />
          </span>
          {translate("common:introduction.info-your-add-in")}
        </div> */}
      </div>
      <div className={styles.footer}>
        <div className={styles.extraInfo}>
          <div className={styles.extraInfoContainer}>
            <div className={styles.howTo} data-open={openHowTo}>
              <div className={styles.howToTitleContainer}>
                <span className={styles.howToTitleContainer__leftSide}>
                  <IconComponent color="var(--color-blue)" icon={icon.faInfoCircle} size="lg" />
                  <span className={styles.howToTitleContainer__text}>{translate("common:introduction.how-to")}</span>
                </span>
                <CustomIconButton color="primary" icon={icon.faXmark} onClick={handleOpen} />
              </div>
              <div className={styles.text}>{translate("common:introduction.to-use")}</div>
              <ul>
                <strong>{translate("common:introduction.to-subscribe")}</strong>
                <li className={styles.text}>{translate("common:introduction.email")}</li>
                <li className={styles.text}>
                  {translate("common:introduction.book")}
                  <a className={styles.url} href="https://www.aitenders.com/" rel="noreferrer" target="_blank">
                    {translate("common:introduction.our website")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <CustomButton className={styles.extraInfoButton} endIcon={icon.faChevronDown} size="md" onClick={handleOpen}>
            {translate("common:btn.learn-how-to-access")}
          </CustomButton>
        </div>
        <CustomButton variant="contained" onClick={handleLogin}>
          {translate("common:btn.continue")}
        </CustomButton>
      </div>
    </div>
  );
};

export default Introduction;
