import React, { useEffect, useState } from "react";
import { SelectionContainer } from "../";
import { Radio } from "@mui/material";
import { useApi } from "../../../../../common/hooks";
import { LinkReqToDelivService } from "../../../../../api";
import { useDispatch, useSelector } from "react-redux";
import styles from "../EnvironmentSelection.module.css";
import { translate } from "../../../../../common/providers";
import { CustomButtonLink, CustomTooltip } from "../../../../../common/components";
import { setDeliverable, setDeliverableToUpdate, setProjectReqToDelivToUpdate } from "../../../../../common/slice";
import { setUpdateReqCounters, setUpdateReqList } from "../../../slice/link-requirement-to-deliverable-slice";

const DeliverableSelection = () => {
  const { call: getDeliverables } = useApi(LinkReqToDelivService.getDeliverables);
  const projectReqToDelivId = useSelector(({ context }) => context.projectReqToDeliv.id);
  const domain = useSelector(({ context }) => context.domain);
  const companyId = useSelector(({ context }) => context.company?.id);
  const deliverable = useSelector(({ context }) => context.deliverable);
  const [request, setRequest] = useState(null);
  const [selectedDeliverable, setSelectedDeliverable] = useState(deliverable);
  const dispatch = useDispatch();
  useEffect(() => {
    setRequest(
      () =>
        ({ page, limit, search }) =>
          getDeliverables({ projectId: projectReqToDelivId }, { page, limit, filter: search })
    );
  }, [projectReqToDelivId]);
  const handleSelectProject = (row) => {
    setSelectedDeliverable(row);
  };
  const rowRenderer = (row) => {
    return (
      <div className={styles.rowContainer} role="presentation" onClick={() => handleSelectProject(row)}>
        <Radio radio checked={row.id === selectedDeliverable?.id} />
        <CustomTooltip showWhenOverflow title={row.name}>
          <div className={styles.name}>{row.name}</div>
        </CustomTooltip>
        <CustomTooltip showWhenOverflow title={row.owner?.displayName}>
          <div className={styles.name}>{row.owner?.displayName}</div>
        </CustomTooltip>
      </div>
    );
  };

  const headerRow = () => (
    <div className={styles.headerRowContainer}>
      <div className={styles.radio} />
      <div className={styles.name}>{translate("common:project-selection-side-pane.name")}</div>
      <div className={styles.name}>{translate("common:project-selection-side-pane.owner")}</div>
    </div>
  );
  const handleValidate = () => {
    dispatch(
      setDeliverable({
        name: selectedDeliverable.name,
        id: selectedDeliverable.id,
        ownerId: selectedDeliverable.owner?.id,
        ownerName: selectedDeliverable.owner?.displayName,
      })
    );
    dispatch(setDeliverableToUpdate(false));
    dispatch(setUpdateReqList(true));
    dispatch(setUpdateReqCounters(true));
  };
  const handleBack = () => {
    dispatch(setProjectReqToDelivToUpdate(true));
  };
  const noRowsRenderer = () => (
    <div className={styles.emptyState}>
      <div className={styles.emptyState__header}>{translate("common:empty-state.no-results")}</div>
      <div className={styles.emptyState__content}>
        <span>{translate("requirements-to-deliverables.deliverable-selection.to-cover")}</span>
        <CustomButtonLink
          external
          openInNewTab
          buttonClassName={styles.emptyState__link}
          to={`https://${domain}/projects/${projectReqToDelivId}/follow-up/deliverables/deliverables-to-be-produced?companyId=${companyId}`}
        >
          {translate("requirements-to-deliverables.deliverable-selection.delivcerables-to-be-produced")}
        </CustomButtonLink>
        <span className={styles.emptyState__lowerCase}>
          {translate("requirements-to-deliverables.deliverable-selection.to-cover.pages")}
        </span>
      </div>
    </div>
  );
  return (
    <SelectionContainer
      disableValidate={!selectedDeliverable}
      headerRow={headerRow}
      noRowsRenderer={noRowsRenderer}
      request={request}
      rowRenderer={rowRenderer}
      title="Sélectionner un livrable"
      onBack={handleBack}
      onValidate={handleValidate}
    />
  );
};

export default DeliverableSelection;
