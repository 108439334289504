import React, { useState } from "react";
import styles from "./AdditionalInfoBox.module.css";
import { CustomIconButton, icon } from "../../../../../../../common/components";

const AdditionalInfoBox = ({ onRemove, value }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <div className={styles.container} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {value}
      {isHovering && (
        <div className={styles.hoverContainer}>
          <CustomIconButton
            color="secondary"
            icon={icon.faTrash}
            size="xs"
            variant="outlined"
            onClick={() => onRemove(value)}
          />
        </div>
      )}
    </div>
  );
};

export default AdditionalInfoBox;
