import React, { useEffect, useState } from "react";
import MultiInput from "../components/multi-input/MultiInput";
import { translate } from "../../../../../providers";
import { useEffectOnce } from "../../../../../hooks";

const InformationIdFilter = ({ onChange, value }) => {
  const [idInputs, setIdInputs] = useState([""]);

  useEffect(() => {
    if (idInputs.some((val) => Number(val) > 0)) {
      onChange({ elements: idInputs.map((val) => Number(val)) });
    }
  }, [idInputs]);
  useEffectOnce(
    () => {
      setIdInputs(value?.elements);
    },
    [value],
    () => Array.isArray(value?.elements) && value.elements.length > 0
  );
  const handleAddNew = () => {
    setIdInputs((prev) => [...prev, ""]);
  };
  const handleChangeInput = (newValue, index) => {
    const tempIdInputs = [...idInputs];
    tempIdInputs[index] = newValue;
    setIdInputs(tempIdInputs);
  };
  const handleRemove = (index) => {
    const tempIdInputs = [...idInputs];
    tempIdInputs.splice(index, 1);
    setIdInputs(tempIdInputs);
  };
  return (
    Array.isArray(idInputs) &&
    idInputs.map((idInput, index) => (
      <MultiInput
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        disableLimitation
        error={Number(idInput) === 0}
        helperText={translate("common:component.filters.input-filter.helper.numeric")}
        index={index}
        input={idInput}
        isLast={idInputs.length - 1 === index}
        type="number"
        onAddNew={handleAddNew}
        onChangeInput={handleChangeInput}
        onRemove={handleRemove}
      />
    ))
  );
};

export default InformationIdFilter;
