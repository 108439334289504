import React, { useState } from "react";
import { isNonEmptyArray } from "../../../../../../../common/utils";
import IdentifiedContentBox from "../../../../identified-content-box/IdentifiedContentBox";
import { ChapterPreview } from "../../../../../../../common/components";
import { ContentService } from "../../../../../../../api";
import { useApi } from "../../../../../../../common/hooks";

const SavedContents = ({ savedContents, onDelete }) => {
  const [chapterPreview, setChapterPreview] = useState(null);
  const { call: onGetPreviewInformationChapter } = useApi(ContentService.getPreviewInformationChapter);
  const handleCloseChapterDisplay = () => setChapterPreview(null);
  const handleDisplaySelection = (data) => {
    if (data.informationId && !data.contentPositionDTOs) {
      onGetPreviewInformationChapter({ docId: data.documentId, informationId: data.informationId })
        .then((res) => setChapterPreview({ ...data, coordinates: res.rectangles }))
        .catch(console.error);
    } else {
      setChapterPreview(data);
    }
  };
  return (
    <div>
      {isNonEmptyArray(savedContents) &&
        savedContents.map((savedContent) => (
          <div key={savedContent.savedDataId}>
            <IdentifiedContentBox
              key={savedContent.savedDataId}
              identifiedContent={savedContent}
              onDelete={onDelete}
              onDisplaySelection={handleDisplaySelection}
            />
          </div>
        ))}
      <ChapterPreview
        coordinates={chapterPreview?.coordinates}
        docId={chapterPreview?.documentId}
        infoId={chapterPreview?.informationId}
        numOfPages={chapterPreview?.numberOfPages}
        open={!!chapterPreview}
        page={chapterPreview?.pageStart}
        projectId={chapterPreview?.projectId}
        recievedRectangles={chapterPreview?.contentPositionDTOs}
        onClose={handleCloseChapterDisplay}
      />
    </div>
  );
};

export default SavedContents;
