import React from "react";
import styles from "./ProjectSelection.module.css";
import { CustomButton } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import { setOpenProjectSelectionSidePanel } from "../../../../common/slice";
import { useDispatch } from "react-redux";

const ProjectSelection = () => {
  const dispatch = useDispatch();
  const handleOpenSidePane = () => {
    dispatch(setOpenProjectSelectionSidePanel(true));
  };
  return (
    <div className={styles.containter}>
      <div>{translate("multi-projects-search.projec-selection.no-project-selected")}</div>
      <CustomButton variant="contained" onClick={handleOpenSidePane}>
        {translate("common:project-selection-side-pane.title")}
      </CustomButton>
    </div>
  );
};

export default ProjectSelection;
