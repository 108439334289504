import client from "../client/client";

class RequirementMatrixService {
  getParametersForInformation(cancelToken) {
    return client.get("/requirement-matrix/parameters", { config: { cancelToken } });
  }
  getRequirementInformation({ reqId }, cancelToken) {
    return client.get(`/requirement-matrix/requirements/${reqId}`, { config: { cancelToken } });
  }
}

export default new RequirementMatrixService();
